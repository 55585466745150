export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never }
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string }
  String: { input: string; output: string }
  Boolean: { input: boolean; output: boolean }
  Int: { input: number; output: number }
  Float: { input: number; output: number }
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: { input: any; output: any }
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: { input: any; output: any }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any }
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: { input: any; output: any }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar.This scalar is serialized to a string in ISO 8601 format and parsed from a string in ISO 8601 format. */
  DateTimeISO: { input: any; output: any }
  Upload: { input: any; output: any }
}

export type Query = {
  __typename: 'Query'
  adminFindAllPaymentsByProfileId: Array<Payment>
  /** find rooms by user */
  adminFindRooms: Array<Room>
  adminGetActiveSubscriptions: Array<Subscriber>
  /** returns all shifts in range including cancelled for all tutors */
  adminGetAllShiftsInRange: Array<Shift>
  adminGetClientTherapistId: TutorStudent
  adminGetConversation: Maybe<Conversation>
  adminGetConversations: Array<Conversation>
  adminGetInvoices: Array<Invoice>
  adminGetPurchasedPackages: Array<Purchase>
  /** returns all shifts in range including cancelled */
  adminGetShiftsInRange: Array<Shift>
  adminGetTopics: Array<Topic>
  adminGetTransactions: Array<Transaction>
  adminGetTutor: Tutor
  adminGetUserStatusHistory: Array<UserStatusHistory>
  adminGetWalletBalances: Array<AdminWalletBalance>
  adminListGroups: Array<Group>
  adminPaymentStats: Array<PaymentStats>
  callTime: Scalars['Int']['output']
  canProlongCall: CantProlongReason
  canRedeem: Scalars['String']['output']
  checkAnyPayment: Payment
  /** For service call only. Did user pay?. */
  checkCreditUsage: CheckCreditUsageResponse
  /** Check payment status and do all necessary updates. */
  checkPayment: CsobPayment
  emailExists: Maybe<EmailExist>
  findConversation: Maybe<Conversation>
  /** find credential by identifier and optional by type */
  findCredential: Maybe<Credential>
  findCredentialByHash: Maybe<Credential>
  findCredentials: Array<Credential>
  findCredentialsIdentifier: Array<Credential>
  findPackageById: Maybe<Package>
  findPackageByName: Maybe<Package>
  findRatingByRoomId: Maybe<Rating>
  findRoom: Maybe<Room>
  findStudents: Array<Student>
  findUserByEmail: Scalars['String']['output']
  findUserByReferralUrl: Maybe<User>
  finishedInvites: Array<Invite>
  generateGoogleAuthUrl: Maybe<Scalars['String']['output']>
  generateGoogleLoginUrl: Maybe<Scalars['String']['output']>
  getActiveSubscriptions: Array<Subscriber>
  getAllCoupons: Array<CouponSummary>
  getAssignedTutorId: Maybe<Scalars['String']['output']>
  /** returns all valid shifts, free slots are filtered on the gateway */
  getAvailableShiftDates: Array<Scalars['DateTime']['output']>
  /** for calendar, returns 100 current/future shifts */
  getAvailableShifts: Array<Shift>
  getAvailableSlots: Maybe<Array<Maybe<Slots>>>
  getAverageRating: Scalars['Float']['output']
  getAwaitingVerificationCredentials: Array<Credential>
  getBannedUserIds: Array<Scalars['String']['output']>
  getBlockedUsers: Array<Block>
  getBookedFutureTutors: Array<Tutor>
  /** find rooms sorted from latest */
  getCallHistory: Array<Room>
  /** find rooms sorted from latest */
  getCallHistoryByParticipantProfileId: Array<Room>
  getCallsInProgress: Array<Room>
  getCallSummary: Maybe<CallSummary>
  getCompositionFile: Maybe<Scalars['String']['output']>
  /** Returns conversation for two userIds */
  getConversation: Maybe<Conversation>
  getConversationById: Conversation
  /** Returns conversation for two userIds */
  getConversationForTwoUserIds: Maybe<Conversation>
  getConversations: Array<Conversation>
  getCouponById: Coupon
  getCoupons: Array<CouponSummary>
  getCredentialsWithoutHash: Array<Credential>
  /** For codegen purposes only */
  getCurrencies: Currency
  getDefaultPricelist: Pricelist
  getDefaultTutorPrice: Array<TutorPrice>
  /**
   * Exchange rates for different.
   * @deprecated Obsolete schema. Use getExchangeRates2: Currency2[].
   */
  getExchangeRates: ExchangeRate
  /** Exchange rates for different currencies. */
  getExchangeRates2: Array<Currency2>
  /** Get expiring transactions for user */
  getExpiringTransactions: Array<Maybe<ExpiringTransaction>>
  getFavoriteTutors: Array<Favorite>
  getFutureTutors: Array<Tutor>
  getGiftVoucher: Scalars['String']['output']
  getGiftVouchers: Array<Voucher>
  getGiftVoucherTypes: Array<GiftVoucherType>
  getGoals: Array<Goal>
  getGroup: Maybe<Group>
  getGroupStudent: GroupStudent
  getGroupStudents: Array<GroupStudent>
  getGroupWallet: Wallet
  getIntercomHash: Scalars['String']['output']
  /** Returns invites that doesnt have negative flag (rejected, expired, etc..) */
  getInvites: Maybe<Invite>
  getInvitesFixed: Array<Invite>
  getInvoices: Array<Invoice>
  getLandingTutors: Maybe<Array<Maybe<Tutor>>>
  getLastPartners: Array<Scalars['String']['output']>
  getLastStudents: Maybe<Array<Maybe<Student>>>
  getLesson: Lesson
  getLessons: Array<Lesson>
  /** Get maximum call price (at date) */
  getMaximumCallPrice: Scalars['Float']['output']
  getMessagesByRoomId: Array<Message>
  /** Returns amount of missing credit if any for given expected call prices (at date) */
  getMissingCreditForCalls: Array<CheckCreditCallsResult>
  getMyGoals: Array<Maybe<UserGoal>>
  getNote: Maybe<Note>
  getNotes: Maybe<Array<Note>>
  /** returns notes from calls with specific user -profileId */
  getNotesByParticipantProfileId: Maybe<Array<Note>>
  getNotUsedPurchases: Array<Purchase>
  getNumberOfCalls: NumberOfCalls
  getOfflineTutors: Array<Tutor>
  getOnlineTutors: Maybe<Array<Maybe<Tutor>>>
  getPackage: Package
  getPackageByLessonId: Package
  getPackageByName: Package
  getPackages: Array<Package>
  getPackagesByIds: Array<Package>
  getPackagesByTutorIds: Array<Package>
  getPackageTutorsWithAvailableShifts: Maybe<Array<Maybe<Tutor>>>
  getProfile: ProfileResult
  getProfileFixed: ProfileResult
  getProfiles: Array<Tutor>
  getProfilesFixed: Array<ProfileResult>
  getPromoInfo: PromoInfo
  getPublicRatings: Array<Rating>
  getPurchasedPackages: Array<Purchase>
  getPurchasedPackagesByProfileId: Array<Purchase>
  getQuizzes: Array<Quiz>
  getRating: Rating
  getRatingByRoomId: Maybe<Rating>
  getRatings: Array<Rating>
  getRatingsByRoomIds: Array<Rating>
  getReceivedRecommendations: Array<Recommendation>
  getRecentInvites: Maybe<Array<Room>>
  /** Returns array of users who were referred by specified user */
  getReferralsByUserId: Array<User>
  getReferralStatistics: ReferralStatistics
  getReportsByReportedUserId: Array<Report>
  getReportsByReportingUserId: Array<Report>
  getRoom: Room
  getScheduledCallById: ScheduledCall
  getScheduledCalls: Array<ScheduledCall>
  getScheduledCallsByIds: Array<Maybe<ScheduledCall>>
  getScheduledCallsByShiftIds: Array<ScheduledCall>
  getSelf: Maybe<User>
  /** returns all shifts in range, including cancelled? = true/false */
  getShiftsInRange: Array<Shift>
  getSource: Maybe<Source>
  getStatus: Status
  getStudent: Student
  getStudentDashboard: Maybe<Student>
  /** Get monthly invoice data for tutors. */
  getStudentInvoiceData: Array<StudentInvoice>
  getSubscriberCount: Scalars['Int']['output']
  getTherapistClients: Array<Student>
  getTherapistsByQuiz: Array<TutorOutput>
  getTopics: Array<Topic>
  getTutor: Maybe<Tutor>
  getTutorByShiftId: Maybe<Tutor>
  getTutorBySlug: Tutor
  getTutorDashboard: Maybe<Tutor>
  getTutorIdsByPackageId: Array<Scalars['String']['output']>
  /** @deprecated Use getTutorsWithStatuses instead */
  getTutorIdsByStatus: Array<UserStatus>
  /** Get invoice data for tutor. */
  getTutorInvoiceData: Array<InvoiceItem>
  getTutorRevenueByMonth: Array<Maybe<TutorRevenueByMonthResponse>>
  getTutors: Array<Tutor>
  getTutorsByAccountingId: Array<Tutor>
  getTutorsById: Array<Tutor>
  getTutorsByQuiz: Array<Tutor>
  getTutorsByQuizId: Array<Tutor>
  /** Get monthly invoice data for tutors. */
  getTutorsMonthlyInvoiceData: Array<TutorInvoice>
  /** Use only for polling of availability, status and busy */
  getTutorsRealState: Array<Tutor>
  /** find rooms sorted from latest */
  getTutorsRoomsByIds: Array<Room>
  getTutorsWithStatuses: Array<Tutor>
  getTutorTransactions: Array<Transaction>
  getUser: Maybe<User>
  getUserStatus: Maybe<UserStatus>
  getUserTransactions: Array<Transaction>
  getVerificationByIdentifier: Credential
  getVoucher: Voucher
  getVouchers: Array<Voucher>
  /** Get balance for all or specific wallet - directed by type argument. */
  getWalletBalance: WalletBalance
  getWallets: Array<Wallet>
  /** For purpose of codegen in other services */
  getWebLanguage: WebLanguage
  googleLogin: Maybe<AuthResponse>
  hasCreditForCalls: Array<CheckCreditResult>
  /** Did user pay?. */
  hasPaid: Scalars['Boolean']['output']
  hasUnreadMessages: UnreadInfo
  hasUnreadMessagesInRange: UnreadInfo
  isPotentialFraud: Scalars['Boolean']['output']
  listTutors: Array<Tutor>
  query: Scalars['String']['output']
  resolveId: ResolvedId
  resolveTutorRevenuesByRoomIds: Array<Transaction>
  resolveUsers: Maybe<Array<User>>
  savedCurrency: Maybe<Scalars['String']['output']>
  serverTime: Maybe<Scalars['Date']['output']>
  serviceCheckAnyPayment: Payment
  serviceGetAssignedTutorId: Maybe<Scalars['String']['output']>
  /** Service ONLY. Find rooms sorted from latest */
  serviceGetCallHistory: Array<Room>
  /** Returns estimated call revenue, commission and vat */
  serviceGetCallPriceEstimation: EstimatedCallPrices
  /** Get expiring transactions for user */
  serviceGetExpiringTransactions: Array<Maybe<ExpiringTransaction>>
  serviceGetGroup: Maybe<Group>
  serviceGetGroupMembersWallets: Array<Wallet>
  serviceGetNotUsedPurchases: Array<Purchase>
  serviceGetPurchasedPackages: Array<Purchase>
  serviceGetPurchaseUsages: PurchaseUsage
  serviceGetScheduledCalls: Array<ScheduledCall>
  serviceGetScheduledCallsByDate: Array<ScheduledCall>
  /** combines with scheduled calls on gateway */
  serviceGetShiftsByDate: Array<Shift>
  /** Get balance for all or specific wallet - directed by type argument. */
  serviceGetWalletBalance: WalletBalance
  serviceGetWallets: Array<Wallet>
  /** For service call only. Did user pay?. */
  serviceHasPaid: Scalars['Boolean']['output']
  /** Service ONLY. Test if student is busy */
  serviceIsStudentBusy: Scalars['Boolean']['output']
  serviceIsUserVerified: Scalars['Boolean']['output']
  statsCountCalls: Array<DailyCalls>
  statsCountSuccessfulInvites: Array<DailyCalls>
  statsCountUnsuccessfulCalls: Array<DailyCalls>
  statsCountUnsuccessfulInvites: Array<DailyCalls>
  statsCountUnverifiedUsers: Array<DailyUsers>
  statsCountUsers: Array<DailyUsers>
  testAssembly: Array<Tutor>
  testError: Scalars['String']['output']
  tutorsBusy: Array<Busy>
  users: Array<User>
  validatePromo: Maybe<Voucher>
}

export type QueryAdminFindAllPaymentsByProfileIdArgs = {
  profileId: Scalars['String']['input']
}

export type QueryAdminFindRoomsArgs = {
  pageInfo?: InputMaybe<PageInfo>
  profileId: Scalars['String']['input']
}

export type QueryAdminGetActiveSubscriptionsArgs = {
  type: UserNotificationType
  userId: Scalars['String']['input']
}

export type QueryAdminGetAllShiftsInRangeArgs = {
  from: Scalars['DateTime']['input']
  to: Scalars['DateTime']['input']
}

export type QueryAdminGetClientTherapistIdArgs = {
  clientId: Scalars['String']['input']
}

export type QueryAdminGetConversationArgs = {
  id: Scalars['String']['input']
  pageInfo?: InputMaybe<PageInfo>
}

export type QueryAdminGetConversationsArgs = {
  pageInfo?: InputMaybe<PageInfo>
  userId: Scalars['String']['input']
}

export type QueryAdminGetInvoicesArgs = {
  userId: Scalars['String']['input']
}

export type QueryAdminGetPurchasedPackagesArgs = {
  studentId: Scalars['String']['input']
}

export type QueryAdminGetShiftsInRangeArgs = {
  from: Scalars['DateTime']['input']
  to: Scalars['DateTime']['input']
  tutorId: Scalars['String']['input']
}

export type QueryAdminGetTransactionsArgs = {
  pageInfo?: InputMaybe<PageInfo>
  profileId: Scalars['String']['input']
}

export type QueryAdminGetTutorArgs = {
  id: Scalars['String']['input']
}

export type QueryAdminGetUserStatusHistoryArgs = {
  pageInfo?: InputMaybe<PageInfo>
  userId: Scalars['String']['input']
}

export type QueryAdminGetWalletBalancesArgs = {
  userIds: Array<Scalars['String']['input']>
}

export type QueryAdminPaymentStatsArgs = {
  dateRangeInput: DateRangeInput
}

export type QueryCallTimeArgs = {
  timePeriod: TimePeriod
}

export type QueryCanProlongCallArgs = {
  roomId: Scalars['String']['input']
}

export type QueryCanRedeemArgs = {
  code: Scalars['String']['input']
}

export type QueryCheckAnyPaymentArgs = {
  paymentId: Scalars['String']['input']
}

export type QueryCheckCreditUsageArgs = {
  input: CheckCreditUsageInput
}

export type QueryCheckPaymentArgs = {
  paymentId: Scalars['String']['input']
}

export type QueryEmailExistsArgs = {
  email: Scalars['String']['input']
}

export type QueryFindConversationArgs = {
  id: Scalars['String']['input']
  pageInfo?: InputMaybe<PageInfo>
}

export type QueryFindCredentialArgs = {
  identifier: Scalars['String']['input']
  type?: InputMaybe<CredentialType>
}

export type QueryFindCredentialByHashArgs = {
  hash: Scalars['String']['input']
  type: CredentialType
}

export type QueryFindCredentialsArgs = {
  userId: Scalars['String']['input']
}

export type QueryFindCredentialsIdentifierArgs = {
  identifier: Scalars['String']['input']
}

export type QueryFindPackageByIdArgs = {
  id: Scalars['String']['input']
}

export type QueryFindPackageByNameArgs = {
  name: Scalars['String']['input']
}

export type QueryFindRatingByRoomIdArgs = {
  roomId: Scalars['String']['input']
}

export type QueryFindRoomArgs = {
  id: Scalars['String']['input']
}

export type QueryFindStudentsArgs = {
  pageInfo?: InputMaybe<PageInfo>
  studentId?: InputMaybe<Scalars['String']['input']>
}

export type QueryFindUserByEmailArgs = {
  email: Scalars['String']['input']
}

export type QueryFindUserByReferralUrlArgs = {
  referralUrl: Scalars['String']['input']
}

export type QueryGenerateGoogleAuthUrlArgs = {
  isLocal?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryGenerateGoogleLoginUrlArgs = {
  coupon?: InputMaybe<Scalars['String']['input']>
}

export type QueryGetActiveSubscriptionsArgs = {
  type: UserNotificationType
}

export type QueryGetAllCouponsArgs = {
  filters?: InputMaybe<CouponFiltersInput>
}

export type QueryGetAvailableShiftDatesArgs = {
  tutorId: Scalars['String']['input']
}

export type QueryGetAvailableShiftsArgs = {
  pageInfo?: InputMaybe<PageInfo>
}

export type QueryGetAvailableSlotsArgs = {
  duration: Scalars['Int']['input']
  tutorId: Scalars['String']['input']
}

export type QueryGetAverageRatingArgs = {
  id: Scalars['String']['input']
}

export type QueryGetBlockedUsersArgs = {
  userId: Scalars['String']['input']
}

export type QueryGetBookedFutureTutorsArgs = {
  pageInfo?: InputMaybe<PageInfo>
}

export type QueryGetCallHistoryArgs = {
  onlyChargedCall?: InputMaybe<Scalars['Boolean']['input']>
  pageInfo?: InputMaybe<PageInfo>
}

export type QueryGetCallHistoryByParticipantProfileIdArgs = {
  onlyChargedCall?: InputMaybe<Scalars['Boolean']['input']>
  pageInfo?: InputMaybe<PageInfo>
  profileId: Scalars['String']['input']
}

export type QueryGetCallSummaryArgs = {
  roomId: Scalars['String']['input']
}

export type QueryGetCompositionFileArgs = {
  roomId: Scalars['String']['input']
}

export type QueryGetConversationArgs = {
  pageInfo?: InputMaybe<PageInfo>
  partnerUserId: Scalars['String']['input']
}

export type QueryGetConversationByIdArgs = {
  id: Scalars['String']['input']
  pageInfo?: InputMaybe<PageInfo>
}

export type QueryGetConversationForTwoUserIdsArgs = {
  pageInfo?: InputMaybe<PageInfo>
  userIdA: Scalars['String']['input']
  userIdB: Scalars['String']['input']
}

export type QueryGetConversationsArgs = {
  pageInfo?: InputMaybe<PageInfo>
}

export type QueryGetCouponByIdArgs = {
  couponId: Scalars['String']['input']
}

export type QueryGetCouponsArgs = {
  filters?: InputMaybe<CouponFiltersInput>
  pageInfo?: InputMaybe<PageInfo>
}

export type QueryGetExpiringTransactionsArgs = {
  until?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryGetFavoriteTutorsArgs = {
  tutorId: Scalars['String']['input']
}

export type QueryGetFutureTutorsArgs = {
  pageInfo?: InputMaybe<PageInfo>
}

export type QueryGetGiftVoucherArgs = {
  voucherId: Scalars['String']['input']
}

export type QueryGetGroupArgs = {
  groupId: Scalars['String']['input']
}

export type QueryGetGroupStudentArgs = {
  groupId: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type QueryGetGroupStudentsArgs = {
  groupId: Scalars['String']['input']
}

export type QueryGetGroupWalletArgs = {
  groupId: Scalars['String']['input']
}

export type QueryGetLandingTutorsArgs = {
  pageInfo?: InputMaybe<PageInfo>
}

export type QueryGetLastPartnersArgs = {
  pageInfo?: InputMaybe<PageInfo>
}

export type QueryGetLessonArgs = {
  id: Scalars['String']['input']
}

export type QueryGetLessonsArgs = {
  ids: Array<Scalars['String']['input']>
}

export type QueryGetMaximumCallPriceArgs = {
  groupId?: InputMaybe<Scalars['String']['input']>
  until?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryGetMessagesByRoomIdArgs = {
  roomId: Scalars['String']['input']
}

export type QueryGetMissingCreditForCallsArgs = {
  expectedPrices: Array<Scalars['Float']['input']>
  groupId?: InputMaybe<Scalars['String']['input']>
  until?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryGetNoteArgs = {
  roomId: Scalars['String']['input']
}

export type QueryGetNotesByParticipantProfileIdArgs = {
  pageInfo?: InputMaybe<PageInfo>
  profileId: Scalars['String']['input']
}

export type QueryGetNumberOfCallsArgs = {
  profileId: Scalars['String']['input']
}

export type QueryGetOfflineTutorsArgs = {
  excludedIds?: InputMaybe<Array<Scalars['String']['input']>>
  pageInfo?: InputMaybe<PageInfo>
}

export type QueryGetOnlineTutorsArgs = {
  pageInfo?: InputMaybe<PageInfo>
}

export type QueryGetPackageArgs = {
  id: Scalars['String']['input']
}

export type QueryGetPackageByLessonIdArgs = {
  lessonId: Scalars['String']['input']
}

export type QueryGetPackageByNameArgs = {
  name: Scalars['String']['input']
}

export type QueryGetPackagesByIdsArgs = {
  ids: Array<Scalars['String']['input']>
}

export type QueryGetPackagesByTutorIdsArgs = {
  ids: Array<Scalars['String']['input']>
}

export type QueryGetPackageTutorsWithAvailableShiftsArgs = {
  lessonId: Scalars['String']['input']
}

export type QueryGetProfileArgs = {
  id: Scalars['String']['input']
}

export type QueryGetProfileFixedArgs = {
  id: Scalars['String']['input']
}

export type QueryGetProfilesArgs = {
  ids: Array<Scalars['String']['input']>
}

export type QueryGetProfilesFixedArgs = {
  ids: Array<Scalars['String']['input']>
}

export type QueryGetPublicRatingsArgs = {
  pageInfo?: InputMaybe<PageInfo>
  profileId: Scalars['String']['input']
}

export type QueryGetPurchasedPackagesArgs = {
  tutorId?: InputMaybe<Scalars['String']['input']>
}

export type QueryGetPurchasedPackagesByProfileIdArgs = {
  profileId: Scalars['String']['input']
}

export type QueryGetQuizzesArgs = {
  profileId?: InputMaybe<Scalars['String']['input']>
}

export type QueryGetRatingArgs = {
  id: Scalars['String']['input']
}

export type QueryGetRatingByRoomIdArgs = {
  roomId: Scalars['String']['input']
}

export type QueryGetRatingsArgs = {
  profileId: Scalars['String']['input']
}

export type QueryGetRatingsByRoomIdsArgs = {
  roomIds: Array<Scalars['String']['input']>
}

export type QueryGetReceivedRecommendationsArgs = {
  recommendedUserId: Scalars['String']['input']
}

export type QueryGetReferralsByUserIdArgs = {
  userId: Scalars['String']['input']
}

export type QueryGetReportsByReportedUserIdArgs = {
  userId: Scalars['String']['input']
}

export type QueryGetReportsByReportingUserIdArgs = {
  userId: Scalars['String']['input']
}

export type QueryGetRoomArgs = {
  id: Scalars['String']['input']
}

export type QueryGetScheduledCallByIdArgs = {
  id: Scalars['String']['input']
}

export type QueryGetScheduledCallsArgs = {
  from?: InputMaybe<Scalars['DateTimeISO']['input']>
  showBooked?: InputMaybe<Scalars['Boolean']['input']>
  showFinished?: InputMaybe<Scalars['Boolean']['input']>
  showMissed?: InputMaybe<Scalars['Boolean']['input']>
  to?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryGetScheduledCallsByIdsArgs = {
  ids: Array<Scalars['String']['input']>
}

export type QueryGetScheduledCallsByShiftIdsArgs = {
  shiftIds: Array<Scalars['String']['input']>
  type?: InputMaybe<ScheduledCallResult>
}

export type QueryGetShiftsInRangeArgs = {
  cancelled?: InputMaybe<Scalars['Boolean']['input']>
  from: Scalars['DateTime']['input']
  to: Scalars['DateTime']['input']
  type?: InputMaybe<ShiftType>
}

export type QueryGetStatusArgs = {
  userId?: InputMaybe<Scalars['String']['input']>
}

export type QueryGetStudentInvoiceDataArgs = {
  from: Scalars['DateTime']['input']
  until: Scalars['DateTime']['input']
}

export type QueryGetSubscriberCountArgs = {
  type: UserNotificationType
}

export type QueryGetTherapistClientsArgs = {
  pageInfo?: InputMaybe<PageInfo>
}

export type QueryGetTherapistsByQuizArgs = {
  language?: Scalars['String']['input']
  quiz: QuizInput
}

export type QueryGetTutorArgs = {
  id: Scalars['String']['input']
}

export type QueryGetTutorByShiftIdArgs = {
  shiftId: Scalars['String']['input']
}

export type QueryGetTutorBySlugArgs = {
  slug: Scalars['String']['input']
}

export type QueryGetTutorIdsByPackageIdArgs = {
  packageId: Scalars['String']['input']
}

export type QueryGetTutorInvoiceDataArgs = {
  from: Scalars['DateTime']['input']
  tutorUserId: Scalars['String']['input']
  until: Scalars['DateTime']['input']
}

export type QueryGetTutorRevenueByMonthArgs = {
  pageInfo?: InputMaybe<PageInfo>
}

export type QueryGetTutorsArgs = {
  pageInfo?: InputMaybe<PageInfo>
}

export type QueryGetTutorsByAccountingIdArgs = {
  ids: Array<Scalars['Int']['input']>
}

export type QueryGetTutorsByIdArgs = {
  ids: Array<Scalars['String']['input']>
}

export type QueryGetTutorsByQuizArgs = {
  quiz: QuizInput
}

export type QueryGetTutorsByQuizIdArgs = {
  quizId?: InputMaybe<Scalars['String']['input']>
}

export type QueryGetTutorsMonthlyInvoiceDataArgs = {
  month: Scalars['Int']['input']
  year: Scalars['Int']['input']
}

export type QueryGetTutorsRoomsByIdsArgs = {
  roomIds: Array<Scalars['String']['input']>
}

export type QueryGetTutorTransactionsArgs = {
  from?: InputMaybe<Scalars['DateTime']['input']>
  pageInfo?: InputMaybe<PageInfo>
  to?: InputMaybe<Scalars['DateTime']['input']>
}

export type QueryGetUserArgs = {
  id: Scalars['String']['input']
}

export type QueryGetUserStatusArgs = {
  userId: Scalars['String']['input']
}

export type QueryGetUserTransactionsArgs = {
  groupId?: InputMaybe<Scalars['String']['input']>
  pageInfo?: InputMaybe<PageInfo>
}

export type QueryGetVerificationByIdentifierArgs = {
  identifier: Scalars['String']['input']
}

export type QueryGetVoucherArgs = {
  voucherId: Scalars['String']['input']
}

export type QueryGetWalletBalanceArgs = {
  groupId?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<WalletType>
}

export type QueryGetWalletsArgs = {
  userId: Scalars['String']['input']
}

export type QueryGoogleLoginArgs = {
  code: Scalars['String']['input']
  isLocal?: InputMaybe<Scalars['Boolean']['input']>
}

export type QueryHasCreditForCallsArgs = {
  date?: InputMaybe<Scalars['DateTime']['input']>
  durations: Array<Scalars['Int']['input']>
  groupId?: InputMaybe<Scalars['String']['input']>
  tutorId: Scalars['String']['input']
  type?: InputMaybe<TutorPriceType>
}

export type QueryHasUnreadMessagesInRangeArgs = {
  intervalFrom: Scalars['Float']['input']
  intervalUntil: Scalars['Float']['input']
  userId: Scalars['String']['input']
}

export type QueryIsPotentialFraudArgs = {
  fraudCookie: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type QueryListTutorsArgs = {
  pageInfo?: InputMaybe<PageInfo>
}

export type QueryResolveIdArgs = {
  id: Scalars['String']['input']
}

export type QueryResolveTutorRevenuesByRoomIdsArgs = {
  roomIds: Array<Scalars['String']['input']>
}

export type QueryResolveUsersArgs = {
  ids: Array<Scalars['String']['input']>
}

export type QueryServiceCheckAnyPaymentArgs = {
  paymentId: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type QueryServiceGetAssignedTutorIdArgs = {
  studentId: Scalars['String']['input']
}

export type QueryServiceGetCallHistoryArgs = {
  pageInfo?: InputMaybe<PageInfo>
  profileId: Scalars['String']['input']
}

export type QueryServiceGetCallPriceEstimationArgs = {
  callPrice: Scalars['Float']['input']
  commission?: InputMaybe<Scalars['Float']['input']>
  tutorUserId: Scalars['String']['input']
}

export type QueryServiceGetExpiringTransactionsArgs = {
  userId: Scalars['String']['input']
}

export type QueryServiceGetGroupArgs = {
  groupId: Scalars['String']['input']
}

export type QueryServiceGetGroupMembersWalletsArgs = {
  groupIds: Array<Scalars['String']['input']>
  userIds: Array<Scalars['String']['input']>
}

export type QueryServiceGetNotUsedPurchasesArgs = {
  studentId: Scalars['String']['input']
}

export type QueryServiceGetPurchasedPackagesArgs = {
  studentId: Scalars['String']['input']
}

export type QueryServiceGetPurchaseUsagesArgs = {
  purchaseId: Scalars['String']['input']
}

export type QueryServiceGetScheduledCallsArgs = {
  from?: InputMaybe<Scalars['DateTimeISO']['input']>
  showBooked?: InputMaybe<Scalars['Boolean']['input']>
  to?: InputMaybe<Scalars['DateTime']['input']>
  userId: Scalars['String']['input']
}

export type QueryServiceGetScheduledCallsByDateArgs = {
  date: Scalars['DateTime']['input']
  profileId: Scalars['String']['input']
}

export type QueryServiceGetShiftsByDateArgs = {
  date: Scalars['DateTime']['input']
  tutorId: Scalars['String']['input']
}

export type QueryServiceGetWalletBalanceArgs = {
  groupId?: InputMaybe<Scalars['String']['input']>
  type?: InputMaybe<WalletType>
  userId: Scalars['String']['input']
}

export type QueryServiceGetWalletsArgs = {
  type?: InputMaybe<WalletType>
  userId: Scalars['String']['input']
}

export type QueryServiceHasPaidArgs = {
  userId: Scalars['String']['input']
}

export type QueryServiceIsStudentBusyArgs = {
  profileId: Scalars['String']['input']
}

export type QueryServiceIsUserVerifiedArgs = {
  identifier: Scalars['String']['input']
}

export type QueryStatsCountCallsArgs = {
  dateRangeInput: DateRangeInput
}

export type QueryStatsCountSuccessfulInvitesArgs = {
  dateRangeInput: DateRangeInput
}

export type QueryStatsCountUnsuccessfulCallsArgs = {
  dateRangeInput: DateRangeInput
}

export type QueryStatsCountUnsuccessfulInvitesArgs = {
  dateRangeInput: DateRangeInput
}

export type QueryStatsCountUnverifiedUsersArgs = {
  dateRangeInput: DateRangeInput
}

export type QueryStatsCountUsersArgs = {
  dateRangeInput: DateRangeInput
}

export type QueryTutorsBusyArgs = {
  tutorIds: Array<Scalars['String']['input']>
}

export type QueryValidatePromoArgs = {
  promoCode: Scalars['String']['input']
}

export type Payment = {
  amount: Scalars['Float']['output']
  amountExcludedVat: Scalars['Float']['output']
  blabuState: BlabuPaymentStateType
  createdAt: Scalars['DateTime']['output']
  exchangeRate: Maybe<Scalars['Float']['output']>
  externalId: Maybe<Scalars['String']['output']>
  groupId: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  invoiceId: Maybe<Scalars['String']['output']>
  originalAmount: Scalars['Float']['output']
  originalCurrency: Currency
  package: Maybe<Package>
  profileId: Scalars['String']['output']
  promoCode: Maybe<Scalars['String']['output']>
  targetId: Maybe<Scalars['String']['output']>
  targetType: Maybe<TargetType>
  tutorId: Maybe<Scalars['String']['output']>
  txId: Scalars['String']['output']
  type: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  /** User wiling to use current credit to partially cover package/voucher purchase */
  useCredit: Maybe<Scalars['Boolean']['output']>
  userId: Scalars['String']['output']
  vat: Maybe<Scalars['Float']['output']>
  vatCountry: Maybe<CountryType>
  vatPercentage: Maybe<Scalars['Float']['output']>
}

/** Type of payment state - general blabu. */
export enum BlabuPaymentStateType {
  Canceled = 'CANCELED',
  Created = 'CREATED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Refunded = 'REFUNDED',
  Success = 'SUCCESS',
}

/** Exchange rate currency */
export enum Currency {
  Czk = 'CZK',
  Eur = 'EUR',
}

export type Package = {
  __typename: 'Package'
  commission: Scalars['Float']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  lessons: Maybe<Array<Lesson>>
  name: Scalars['String']['output']
  preSaleBonus: Scalars['Float']['output']
  price: Scalars['Float']['output']
  purchases: Maybe<Array<Purchase>>
  state: PackageStatus
  tutors: Maybe<Array<PackageTutor>>
  units: Scalars['Int']['output']
}

export type Lesson = {
  __typename: 'Lesson'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  lessonUsage: Scalars['Boolean']['output']
  no: Maybe<Scalars['Int']['output']>
  package: Package
  units: Scalars['Int']['output']
}

export type Purchase = {
  __typename: 'Purchase'
  createdAt: Scalars['DateTime']['output']
  expiresAt: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  package: Package
  paymentId: Maybe<Scalars['String']['output']>
  status: PurchaseStatus
  studentId: Scalars['String']['output']
  tutorId: Maybe<Scalars['String']['output']>
  userId: Scalars['String']['output']
}

/** Status of Purchase. */
export enum PurchaseStatus {
  Done = 'Done',
  InProgress = 'InProgress',
  New = 'New',
}

/** Status of Package. */
export enum PackageStatus {
  ForSale = 'ForSale',
  Hidden = 'Hidden',
  PreSale = 'PreSale',
}

export type PackageTutor = {
  __typename: 'PackageTutor'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  package: Package
  tutor: Maybe<Tutor>
  tutorId: Scalars['String']['output']
}

export type Tutor = {
  __typename: 'Tutor'
  accountingId: Scalars['Int']['output']
  availability: Maybe<Availability>
  balance: Maybe<Scalars['Float']['output']>
  billing: Maybe<Billing>
  busy: Maybe<Busy>
  /** pricelist per call segment eg 15 minutes */
  callRate: CallRate
  createdAt: Scalars['DateTime']['output']
  hourlyRate: Maybe<Scalars['Float']['output']>
  id: Scalars['ID']['output']
  /** @deprecated legacy field, use `intros` field */
  introduction: Scalars['String']['output']
  intros: Array<TutorIntro>
  isActive: Scalars['Boolean']['output']
  /** @deprecated legacy field, use `intros` field */
  language: Scalars['String']['output']
  lastOnline: Maybe<Scalars['DateTime']['output']>
  /** @deprecated legacy field, use `intros` field */
  motto: Maybe<Scalars['String']['output']>
  packages: Maybe<Array<Maybe<Package>>>
  prices: Array<TutorPrice>
  rating: Scalars['Float']['output']
  reviews: Maybe<Array<Maybe<Rating>>>
  role: ProfileType
  rooms: Maybe<Array<Maybe<Room>>>
  shifts: Array<Shift>
  slug: Scalars['String']['output']
  stats: Maybe<TutorStats>
  topics: Array<Topic>
  updatedAt: Scalars['DateTime']['output']
  user: User
}

export type Availability = {
  __typename: 'Availability'
  end: Scalars['DateTime']['output']
  start: Scalars['DateTime']['output']
}

export type Billing = Contact & {
  __typename: 'Billing'
  city: Scalars['String']['output']
  companyId: Maybe<Scalars['String']['output']>
  countryCode: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  primary: Scalars['Boolean']['output']
  state: Maybe<Scalars['String']['output']>
  street: Scalars['String']['output']
  taxId: Maybe<Scalars['String']['output']>
  user: User
  verifiedAt: Maybe<Scalars['DateTime']['output']>
  zip: Scalars['String']['output']
}

export type Contact = {
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  primary: Scalars['Boolean']['output']
  user: User
  verifiedAt: Maybe<Scalars['DateTime']['output']>
}

export type User = {
  __typename: 'User'
  ban: Maybe<Ban>
  birthday: Maybe<Scalars['DateTime']['output']>
  category: UserCategory
  contacts: Maybe<Array<Contact>>
  countryCode: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  /** @deprecated Use specific credentials only */
  credentials: Maybe<Array<Credential>>
  currency: Maybe<Scalars['String']['output']>
  currentStatus: CurrentStatus
  displayName: Maybe<Scalars['String']['output']>
  firstName: Maybe<Scalars['String']['output']>
  gender: Maybe<Gender>
  getFullName: Scalars['String']['output']
  groups: Maybe<Array<GroupMember>>
  id: Scalars['ID']['output']
  image: Maybe<Scalars['String']['output']>
  ipAddress: Maybe<IpAddress>
  lastName: Maybe<Scalars['String']['output']>
  middleName: Maybe<Scalars['String']['output']>
  /** Referrals where this user is referee */
  referee: Maybe<Referral>
  /** In USD */
  referralReward: Scalars['Float']['output']
  /** Referrals where this user is referrer */
  referrals: Maybe<Array<Referral>>
  referralUrl: Scalars['String']['output']
  role: UserRole
  /** @deprecated Use currentStatus instead */
  status: Status
  statuses: Maybe<Array<UserStatus>>
  statusHistory: Maybe<Array<UserStatusHistory>>
  students: Maybe<Array<Student>>
  timezone: Maybe<Scalars['String']['output']>
  tutors: Maybe<Array<Tutor>>
  updatedAt: Scalars['DateTime']['output']
  verified: Scalars['Boolean']['output']
  walletBalance: Maybe<WalletBalance>
  webLanguage: WebLanguage
}

export type Ban = {
  __typename: 'Ban'
  bannedAt: Scalars['DateTime']['output']
  bannedUser: User
  comment: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
}

export enum UserCategory {
  Activated = 'ACTIVATED',
  Deleted = 'DELETED',
  New = 'NEW',
  Paying = 'PAYING',
  Trial = 'TRIAL',
}

export type Credential = {
  __typename: 'Credential'
  createdAt: Scalars['DateTime']['output']
  hash: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  identifier: Scalars['String']['output']
  type: CredentialType
  updatedAt: Scalars['DateTime']['output']
  user: Maybe<User>
}

export enum CredentialType {
  Google = 'Google',
  Password = 'Password',
  RestorePassword = 'RestorePassword',
  Verify = 'Verify',
}

export type CurrentStatus = {
  __typename: 'CurrentStatus'
  status: Status
  text: Maybe<Scalars['String']['output']>
}

/** status */
export enum Status {
  Offline = 'Offline',
  Online = 'Online',
}

/** gender */
export enum Gender {
  Female = 'Female',
  Male = 'Male',
}

export type GroupMember = {
  __typename: 'GroupMember'
  department: Maybe<Scalars['String']['output']>
  disabledAt: Maybe<Scalars['DateTime']['output']>
  group: Group
  groupId: Scalars['String']['output']
  groupRole: GroupRole
  state: Maybe<GroupUserState>
  user: User
  userId: Scalars['String']['output']
}

export type Group = {
  __typename: 'Group'
  billing: Maybe<GroupBillingContact>
  contact: Maybe<GroupContact>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  logo: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  type: GroupType
  updatedAt: Scalars['DateTime']['output']
  users: Array<GroupMember>
}

export type GroupBillingContact = {
  __typename: 'GroupBillingContact'
  city: Scalars['String']['output']
  /** ICO */
  companyId: Maybe<Scalars['String']['output']>
  countryCode: Scalars['String']['output']
  email: Scalars['String']['output']
  street: Scalars['String']['output']
  /** DIC */
  taxId: Maybe<Scalars['String']['output']>
  zip: Scalars['String']['output']
}

export type GroupContact = {
  __typename: 'GroupContact'
  email: Scalars['String']['output']
  firstName: Scalars['String']['output']
  lastName: Scalars['String']['output']
  phone: Scalars['String']['output']
}

export enum GroupType {
  Company = 'Company',
  Family = 'Family',
}

export enum GroupRole {
  GroupAdmin = 'groupAdmin',
  GroupManager = 'groupManager',
  GroupUser = 'groupUser',
}

export enum GroupUserState {
  Active = 'Active',
  NotRenew = 'NotRenew',
  Stopped = 'Stopped',
}

export type IpAddress = {
  __typename: 'IpAddress'
  country: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  ip: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  user: User
}

export type Referral = {
  __typename: 'Referral'
  createdAt: Scalars['DateTime']['output']
  hadCall: Scalars['Boolean']['output']
  hasCharged: Scalars['Boolean']['output']
  id: Scalars['ID']['output']
  referee: User
  referrer: User
  rewardedAt: Maybe<Scalars['DateTime']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export enum UserRole {
  Admin = 'admin',
  User = 'user',
}

export type UserStatus = {
  __typename: 'UserStatus'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  status: Status
  text: Maybe<Scalars['String']['output']>
  until: Maybe<Scalars['DateTime']['output']>
  user: User
}

export type UserStatusHistory = {
  __typename: 'UserStatusHistory'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  notify: Scalars['Boolean']['output']
  shiftStartedAt: Scalars['DateTime']['output']
  status: Status
  text: Maybe<Scalars['String']['output']>
  until: Maybe<Scalars['DateTime']['output']>
  user: User
}

export type Student = {
  __typename: 'Student'
  assignedTutor: Maybe<Array<TutorStudent>>
  createdAt: Scalars['DateTime']['output']
  hasMoneyForAnotherCall: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  introduction: Maybe<Scalars['String']['output']>
  language: Scalars['String']['output']
  quizzes: Maybe<Array<Quiz>>
  rating: Scalars['Float']['output']
  role: ProfileType
  rooms: Maybe<Array<Maybe<Room>>>
  updatedAt: Scalars['DateTime']['output']
  user: User
  walletBalance: Maybe<WalletBalance>
}

export type TutorStudent = {
  __typename: 'TutorStudent'
  conversationId: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  tutorId: Scalars['String']['output']
}

export type Quiz = {
  __typename: 'Quiz'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  quiz: Scalars['JSONObject']['output']
  /** @deprecated overschemed */
  student: Maybe<Student>
}

export enum ProfileType {
  Student = 'student',
  Tutor = 'tutor',
}

export type Room = {
  __typename: 'Room'
  /** formatted duration in h:mm:ss */
  actualDuration: Maybe<Scalars['String']['output']>
  callInviteStatus: CallInviteStatus
  cancelled: Maybe<Scalars['DateTime']['output']>
  commission: Maybe<Scalars['Float']['output']>
  createdAt: Scalars['DateTime']['output']
  /** duration in seconds, accepted values 900, 1800, 2700 */
  duration: Scalars['Int']['output']
  /** when is the planned end */
  end: Maybe<Scalars['DateTime']['output']>
  /** when is the actual end (problems, prolongations etc) */
  ended: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  isScheduled: Scalars['Boolean']['output']
  lesson: Maybe<Lesson>
  lessonId: Maybe<Scalars['String']['output']>
  meta: Maybe<Scalars['JSONObject']['output']>
  participants: Maybe<Array<Participant>>
  /** available for student and internal calls only */
  priceCharged: Maybe<Scalars['Float']['output']>
  priceListId: Scalars['String']['output']
  rating: Maybe<Rating>
  reason: Maybe<Scalars['String']['output']>
  recording: Scalars['Boolean']['output']
  resolution: ResolutionRoom
  slug: Scalars['String']['output']
  /** when is the planned start */
  start: Maybe<Scalars['DateTime']['output']>
  /** when is the actual start (countdowns, problems etc) */
  started: Maybe<Scalars['DateTime']['output']>
  state: Maybe<Scalars['String']['output']>
  status: Scalars['String']['output']
  summary: Maybe<Summary>
  technology: Maybe<CallTechType>
  trigger: Maybe<EndCallTrigger>
  /** available for tutor and internal calls only */
  tutorRevenue: Maybe<Scalars['Float']['output']>
  twilioSid: Maybe<Scalars['String']['output']>
  type: Maybe<RoomType>
  unitsCharged: Maybe<Scalars['Float']['output']>
  updatedAt: Scalars['DateTime']['output']
}

export enum CallInviteStatus {
  CallConnect = 'CallConnect',
  CallEnded = 'CallEnded',
  CallEndedEarly = 'CallEndedEarly',
  CallFailed = 'CallFailed',
  CallProlonged = 'CallProlonged',
  CallStarted = 'CallStarted',
  InviteAccepted = 'InviteAccepted',
  InviteBusy = 'InviteBusy',
  InviteCanceled = 'InviteCanceled',
  InviteExpired = 'InviteExpired',
  InviteRejected = 'InviteRejected',
  InviteSent = 'InviteSent',
  PermissionCheck = 'PermissionCheck',
}

export type Participant = {
  __typename: 'Participant'
  acceptedAt: Maybe<Scalars['DateTime']['output']>
  cancelledAt: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  expiredAt: Maybe<Scalars['DateTime']['output']>
  groupId: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  meta: Maybe<Scalars['JSONObject']['output']>
  numberOfCalls: Maybe<NumberOfCalls>
  profile: Maybe<Profile>
  profileFixed: Maybe<ProfileResult>
  profileId: Scalars['String']['output']
  refusedAt: Maybe<Scalars['DateTime']['output']>
  role: Role
  room: Maybe<Room>
  started: Maybe<Scalars['DateTime']['output']>
  token: Maybe<Scalars['String']['output']>
  userId: Scalars['String']['output']
}

export type NumberOfCalls = {
  __typename: 'NumberOfCalls'
  count: Maybe<Scalars['Float']['output']>
  countTogether: Maybe<Scalars['Float']['output']>
  lastCallTogether: Maybe<Scalars['DateTime']['output']>
}

export type Profile = {
  __typename: 'Profile'
  hasMoneyForAnotherCall: Maybe<Scalars['Boolean']['output']>
  id: Scalars['ID']['output']
  language: Scalars['String']['output']
  rating: Scalars['Float']['output']
  role: Scalars['String']['output']
  user: Maybe<User>
}

export type ProfileResult = Student | Tutor

export enum Role {
  Student = 'Student',
  Tutor = 'Tutor',
}

export type Rating = {
  __typename: 'Rating'
  additionalData: Maybe<Scalars['JSON']['output']>
  comment: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  givingProfileId: Scalars['String']['output']
  id: Scalars['ID']['output']
  ignored: Scalars['Boolean']['output']
  ratedProfileId: Scalars['String']['output']
  ratingMood: Scalars['Float']['output']
  ratingSound: Scalars['Float']['output']
  ratingTopics: Scalars['Float']['output']
  ratingTotal: Scalars['Float']['output']
  roomId: Scalars['String']['output']
  student: Maybe<Student>
  visible: Scalars['Boolean']['output']
}

export type ResolutionRoom = {
  __typename: 'ResolutionRoom'
  height: Scalars['Int']['output']
  width: Scalars['Int']['output']
}

export type Summary = {
  __typename: 'Summary'
  price: Maybe<Scalars['Float']['output']>
  timeSpentSeconds: Maybe<Scalars['Int']['output']>
}

export enum CallTechType {
  TwilioP2P = 'TwilioP2P',
  TwilioSmallGroup = 'TwilioSmallGroup',
  TwilioSmallGroupRecorded = 'TwilioSmallGroupRecorded',
}

export enum EndCallTrigger {
  Aborted = 'Aborted',
  Button = 'Button',
  ButtonEarly = 'ButtonEarly',
  ButtonFail = 'ButtonFail',
  Interrupted = 'Interrupted',
  InviteCanceled = 'InviteCanceled',
  InviteExpired = 'InviteExpired',
  InviteRejected = 'InviteRejected',
  PermissionCheck = 'PermissionCheck',
  Timeout = 'Timeout',
  Unload = 'Unload',
}

export enum RoomType {
  Instant = 'Instant',
  Interview = 'Interview',
  Package = 'Package',
  Scheduled = 'Scheduled',
}

export type WalletBalance = {
  __typename: 'WalletBalance'
  balance: Scalars['Float']['output']
  detail: Maybe<Array<MiniWallet>>
  id: Scalars['ID']['output']
  oneclick: Scalars['Boolean']['output']
  userId: Scalars['String']['output']
}

export type MiniWallet = {
  __typename: 'MiniWallet'
  balance: Scalars['Float']['output']
  options: Maybe<WalletOptions>
  type: WalletType
}

export type WalletOptions = {
  __typename: 'WalletOptions'
  /** How many per cent group cover for member in range 0-100%. 100 = all covered by group */
  coverage: Maybe<Scalars['Float']['output']>
  /** Maximal debt allowed for group wallet */
  maxDebt: Maybe<Scalars['Float']['output']>
  /** Monthly limit for group member spending */
  monthlyLimit: Maybe<Scalars['Float']['output']>
}

/** Type of wallet. */
export enum WalletType {
  Blabu = 'Blabu',
  Group = 'Group',
  User = 'User',
  UserGroup = 'UserGroup',
  UserOnHold = 'UserOnHold',
  UserPromo = 'UserPromo',
  UserVoucher = 'UserVoucher',
  Vat = 'Vat',
}

export enum WebLanguage {
  Cs = 'cs',
  En = 'en',
  Es = 'es',
  Pl = 'pl',
}

export type Busy = {
  __typename: 'Busy'
  flag: Maybe<BusyFlag>
  isBusy: Scalars['Boolean']['output']
  until: Maybe<Scalars['DateTime']['output']>
}

export enum BusyFlag {
  CallEnding = 'CallEnding',
  CallInProgress = 'CallInProgress',
  CallStarting = 'CallStarting',
}

export type CallRate = {
  __typename: 'CallRate'
  callLength: Scalars['Float']['output']
  priceInstant: Scalars['Float']['output']
  priceInstantCZK: Scalars['Float']['output']
  priceInstantSecond: Scalars['Float']['output']
}

export type TutorIntro = {
  __typename: 'TutorIntro'
  introduction: Scalars['String']['output']
  language: Scalars['String']['output']
  motto: Maybe<Scalars['String']['output']>
}

export type TutorPrice = {
  __typename: 'TutorPrice'
  id: Scalars['ID']['output']
  price: Scalars['Float']['output']
  type: TutorPriceType
}

export enum TutorPriceType {
  InstantTutorPrice = 'InstantTutorPrice',
  ScheduledTutorPrice = 'ScheduledTutorPrice',
}

export type Shift = {
  __typename: 'Shift'
  cancelledAt: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  end: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  scheduledCalls: Array<Maybe<ScheduledCall>>
  start: Scalars['DateTime']['output']
  /** Only for admin */
  tutor: Tutor
  type: Maybe<ShiftType>
  updatedAt: Scalars['DateTime']['output']
}

export type ScheduledCall = {
  __typename: 'ScheduledCall'
  bookedAt: Maybe<Scalars['DateTime']['output']>
  cancelledAt: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  end: Scalars['DateTime']['output']
  expectedPrice: Maybe<Scalars['Float']['output']>
  expectedRevenue: Maybe<Scalars['Float']['output']>
  id: Scalars['ID']['output']
  lesson: Maybe<Lesson>
  lessonId: Maybe<Scalars['String']['output']>
  participants: Array<ScheduledCallParticipant>
  purchaseId: Maybe<Scalars['String']['output']>
  /** In case of failure one scheduled call can trigger multiple rooms. */
  rooms: Maybe<Array<Room>>
  shiftId: Scalars['String']['output']
  start: Scalars['DateTime']['output']
  status: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type ScheduledCallParticipant = {
  __typename: 'ScheduledCallParticipant'
  cancellationReason: Maybe<Scalars['String']['output']>
  cancelledAt: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  profile: Maybe<ProfileResult>
  profileId: Scalars['String']['output']
  scheduledCall: Maybe<ScheduledCall>
  updatedAt: Scalars['DateTime']['output']
  userId: Scalars['String']['output']
}

/** Type of shift (online, booking) */
export enum ShiftType {
  Booking = 'Booking',
  Online = 'Online',
}

export type TutorStats = {
  __typename: 'TutorStats'
  earningsDetails: Maybe<EarningsDetails>
  hours30Days: Maybe<Scalars['Float']['output']>
  hours30DaysChange: Maybe<Scalars['Float']['output']>
}

export type EarningsDetails = {
  __typename: 'EarningsDetails'
  last30Days: Maybe<Scalars['Float']['output']>
  last30DaysChange: Maybe<Scalars['Float']['output']>
  today: Maybe<Scalars['Float']['output']>
}

export type Topic = {
  __typename: 'Topic'
  createdAt: Scalars['DateTime']['output']
  description: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
}

/** Type of target type. */
export enum TargetType {
  GiftVoucher = 'GIFT_VOUCHER',
  GroupTopUp = 'GROUP_TOP_UP',
  Package = 'PACKAGE',
}

/** Type of countries for VAT. */
export enum CountryType {
  Austria = 'Austria',
  Belgium = 'Belgium',
  Croatia = 'Croatia',
  Cyprus = 'Cyprus',
  Czechia = 'Czechia',
  Denmark = 'Denmark',
  Estonia = 'Estonia',
  Finland = 'Finland',
  France = 'France',
  Germany = 'Germany',
  Greece = 'Greece',
  Hungary = 'Hungary',
  Ireland = 'Ireland',
  Italy = 'Italy',
  Latvia = 'Latvia',
  Lithuania = 'Lithuania',
  Luxembourg = 'Luxembourg',
  Malta = 'Malta',
  Netherlands = 'Netherlands',
  Poland = 'Poland',
  Portugal = 'Portugal',
  Romania = 'Romania',
  Slovakia = 'Slovakia',
  Slovenia = 'Slovenia',
  Spain = 'Spain',
  Sweden = 'Sweden',
  UnitedKingdom = 'UnitedKingdom',
  UnitedStates = 'UnitedStates',
}

export type PageInfo = {
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}

export enum UserNotificationType {
  ExpiringCredit = 'ExpiringCredit',
  FirstPaymentReminder = 'FirstPaymentReminder',
  LeadMessage = 'LeadMessage',
  PackageActivation = 'PackageActivation',
  PackageFinished = 'PackageFinished',
  PackageReminder = 'PackageReminder',
  QuizSaved = 'QuizSaved',
  ScheduledCallBooked = 'ScheduledCallBooked',
  ScheduledCallStarting = 'ScheduledCallStarting',
  SlackMissedCall = 'SlackMissedCall',
  TutorBonus = 'TutorBonus',
  TutorPayout = 'TutorPayout',
  TutorWentOnline = 'TutorWentOnline',
  UnreadMessage = 'UnreadMessage',
}

export type Subscriber = {
  __typename: 'Subscriber'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  profile: Maybe<Profile>
  profileFixed: Maybe<ProfileResult>
  subscribedToProfileId: Maybe<Scalars['String']['output']>
  type: UserNotificationType
  userId: Scalars['ID']['output']
}

export type Conversation = {
  __typename: 'Conversation'
  blockedAt: Maybe<Scalars['DateTime']['output']>
  createdAt: Scalars['DateTime']['output']
  expiresAt: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  lastCallAt: Maybe<Scalars['DateTime']['output']>
  /** @deprecated Use lastMessage on members */
  lastMessage: Maybe<Message>
  members: Array<ConversationMember>
  messages: Maybe<Array<Message>>
  startedAt: Maybe<Scalars['DateTime']['output']>
  updatedAt: Scalars['DateTime']['output']
  user: User
}

export type Message = {
  __typename: 'Message'
  attachment: Maybe<Attachments>
  content: Scalars['String']['output']
  conversation: Conversation
  fromUserId: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  notifiedAt: Scalars['DateTime']['output']
  priority: Maybe<Priority>
  read: Maybe<Scalars['DateTime']['output']>
  roomId: Maybe<Scalars['String']['output']>
  sent: Scalars['DateTime']['output']
  toUserId: Maybe<Scalars['String']['output']>
}

export type Attachments = AttachmentCallEnded | AttachmentScheduledCall

export type AttachmentCallEnded = {
  __typename: 'AttachmentCallEnded'
  content: Scalars['String']['output']
  duration: Scalars['Float']['output']
  roomId: Scalars['ID']['output']
}

export type AttachmentScheduledCall = {
  __typename: 'AttachmentScheduledCall'
  availableAt: Scalars['DateTime']['output']
  content: Scalars['String']['output']
  duration: Scalars['Float']['output']
  expiresAt: Scalars['DateTime']['output']
  hasPassed: Scalars['Boolean']['output']
  scheduledCallId: Scalars['ID']['output']
  start: Scalars['DateTime']['output']
}

/** Message priority */
export enum Priority {
  High = 'High',
  Low = 'Low',
}

export type ConversationMember = {
  __typename: 'ConversationMember'
  conversation: Conversation
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  lastMessage: Maybe<Message>
  updatedAt: Scalars['DateTime']['output']
  /** userId */
  userId: Scalars['String']['output']
}

export type Invoice = {
  __typename: 'Invoice'
  billedBy: BillingInfo
  billedTo: BillingInfo
  createdAt: Scalars['DateTime']['output']
  czkExchangeRate: Scalars['Float']['output']
  eurExchangeRate: Scalars['Float']['output']
  from: Scalars['String']['output']
  id: Scalars['ID']['output']
  items: Array<InvoiceItem>
  subtotal: Scalars['Float']['output']
  taxableAt: Scalars['DateTime']['output']
  total: Scalars['Float']['output']
  /** Invoiced on behalf of: */
  tutorId: Maybe<Scalars['String']['output']>
  until: Scalars['String']['output']
  userId: Scalars['String']['output']
  vat: Scalars['Float']['output']
  vatPercentage: Scalars['Float']['output']
  vs: Scalars['Int']['output']
}

export type BillingInfo = {
  __typename: 'BillingInfo'
  accountingId: Scalars['Int']['output']
  alpha2: Scalars['String']['output']
  city: Scalars['String']['output']
  companyId: Scalars['String']['output']
  country: Scalars['String']['output']
  countryCode: Scalars['String']['output']
  czech: Scalars['Boolean']['output']
  email: Scalars['String']['output']
  moss: Scalars['Boolean']['output']
  name: Scalars['String']['output']
  paymentId: Scalars['String']['output']
  state: Scalars['String']['output']
  street: Scalars['String']['output']
  taxId: Scalars['String']['output']
  zip: Scalars['String']['output']
}

export type InvoiceItem = {
  __typename: 'InvoiceItem'
  amount: Scalars['Float']['output']
  groupId: Maybe<Scalars['String']['output']>
  room: Maybe<InvoiceItemRoom>
  vat: Maybe<Scalars['Float']['output']>
  vatPercentage: Maybe<Scalars['Float']['output']>
}

export type InvoiceItemRoom = {
  __typename: 'InvoiceItemRoom'
  ended: Scalars['DateTime']['output']
  id: Scalars['String']['output']
  started: Scalars['DateTime']['output']
}

export type Transaction = {
  __typename: 'Transaction'
  amount: Scalars['Float']['output']
  comment: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  expiresAt: Maybe<Scalars['DateTime']['output']>
  groupId: Scalars['String']['output']
  id: Scalars['ID']['output']
  profileId: Maybe<Scalars['String']['output']>
  refundTxId: Maybe<Scalars['String']['output']>
  /**
   * Origin of the transaction, depends on type
   * **redeem_voucher**: redemptionId
   */
  sourceId: Maybe<Scalars['String']['output']>
  type: TransactionType
  userId: Maybe<Scalars['String']['output']>
  walletId: Scalars['String']['output']
}

/** Type of payment transaction. */
export enum TransactionType {
  BlabuCommission = 'BlabuCommission',
  BlabuCommissionCancel = 'BlabuCommissionCancel',
  BlabuPromoCredit = 'BlabuPromoCredit',
  BlabuPromoCreditCancel = 'BlabuPromoCreditCancel',
  BlabuPromoCreditOnHold = 'BlabuPromoCreditOnHold',
  GroupPay = 'GroupPay',
  GroupPayOnHold = 'GroupPayOnHold',
  GroupTopUpOnHold = 'GroupTopUpOnHold',
  RedeemVoucher = 'RedeemVoucher',
  RefereeReward = 'RefereeReward',
  ReferrerReward = 'ReferrerReward',
  SodexoBonus = 'SodexoBonus',
  StudentGift = 'StudentGift',
  StudentGiftCancel = 'StudentGiftCancel',
  StudentGiftExpired = 'StudentGiftExpired',
  StudentPay = 'StudentPay',
  StudentPayOnHold = 'StudentPayOnHold',
  StudentPayPromo = 'StudentPayPromo',
  StudentPurchase = 'StudentPurchase',
  StudentPurchasePromo = 'StudentPurchasePromo',
  StudentRefund = 'StudentRefund',
  StudentTransactionPromoRefund = 'StudentTransactionPromoRefund',
  StudentTransactionRefund = 'StudentTransactionRefund',
  TopUp = 'TopUp',
  TopUpCancel = 'TopUpCancel',
  TopUpGiftVoucher = 'TopUpGiftVoucher',
  TopUpGiftVoucherBonus = 'TopUpGiftVoucherBonus',
  TopUpGiftVoucherTransfer = 'TopUpGiftVoucherTransfer',
  TopUpGiftVoucherTransferTo = 'TopUpGiftVoucherTransferTo',
  TopUpOnHold = 'TopUpOnHold',
  TutorGift = 'TutorGift',
  TutorGiftPayout = 'TutorGiftPayout',
  TutorPayout = 'TutorPayout',
  TutorReferrerReward = 'TutorReferrerReward',
  TutorRevenue = 'TutorRevenue',
  TutorTransactionCancel = 'TutorTransactionCancel',
  Vat = 'Vat',
  VatCancel = 'VatCancel',
}

export type AdminWalletBalance = {
  __typename: 'AdminWalletBalance'
  balance: Scalars['Float']['output']
  userId: Scalars['String']['output']
}

export type DateRangeInput = {
  from: Scalars['Date']['input']
  to: Scalars['Date']['input']
}

export type PaymentStats = {
  __typename: 'PaymentStats'
  amount: Scalars['Float']['output']
  blabuState: Scalars['String']['output']
  date: Scalars['DateTime']['output']
  paymentType: Scalars['String']['output']
}

export enum TimePeriod {
  SevenDays = 'SevenDays',
  ThirtyDays = 'ThirtyDays',
  Total = 'Total',
}

export enum CantProlongReason {
  BalanceLow = 'BalanceLow',
  CallNotStarted = 'CallNotStarted',
  PackageCall = 'PackageCall',
  PayingOnly = 'PayingOnly',
  ScheduledCallIncoming = 'ScheduledCallIncoming',
  Success = 'Success',
  TooLong = 'TooLong',
}

export type CheckCreditUsageInput = {
  amount?: InputMaybe<Scalars['Float']['input']>
  currency?: InputMaybe<Currency>
  originalAmount?: InputMaybe<Scalars['Float']['input']>
  targetId?: InputMaybe<Scalars['String']['input']>
  targetType?: InputMaybe<TargetType>
}

export type CheckCreditUsageResponse = {
  __typename: 'CheckCreditUsageResponse'
  creditDiscount: Scalars['Float']['output']
  originalCreditDiscount: Scalars['Float']['output']
}

export type CsobPayment = Payment & {
  __typename: 'CsobPayment'
  amount: Scalars['Float']['output']
  amountExcludedVat: Scalars['Float']['output']
  blabuState: BlabuPaymentStateType
  createdAt: Scalars['DateTime']['output']
  exchangeRate: Maybe<Scalars['Float']['output']>
  externalId: Maybe<Scalars['String']['output']>
  gatewayData: CsobGatewayData
  groupId: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  invoiceId: Maybe<Scalars['String']['output']>
  originalAmount: Scalars['Float']['output']
  originalCurrency: Currency
  package: Maybe<Package>
  profileId: Scalars['String']['output']
  promoCode: Maybe<Scalars['String']['output']>
  state: PaymentStateType
  targetId: Maybe<Scalars['String']['output']>
  targetType: Maybe<TargetType>
  tutorId: Maybe<Scalars['String']['output']>
  txId: Scalars['String']['output']
  type: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  /** User wiling to use current credit to partially cover package/voucher purchase */
  useCredit: Maybe<Scalars['Boolean']['output']>
  userId: Scalars['String']['output']
  vat: Maybe<Scalars['Float']['output']>
  vatCountry: Maybe<CountryType>
  vatPercentage: Maybe<Scalars['Float']['output']>
}

export type CsobGatewayData = {
  __typename: 'CsobGatewayData'
  orderNumber: Scalars['String']['output']
  paymentId: Scalars['String']['output']
  state: PaymentStateType
  type: PaymentType
}

/** Type of payment states. */
export enum PaymentStateType {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Declined = 'DECLINED',
  InProgress = 'IN_PROGRESS',
  Invalid = 'INVALID',
  Reversed = 'REVERSED',
  WaitingForSettle = 'WAITING_FOR_SETTLE',
}

/** Type of payment. */
export enum PaymentType {
  OneClickPayment = 'ONE_CLICK_PAYMENT',
  OneClickPaymentTemplate = 'ONE_CLICK_PAYMENT_TEMPLATE',
  Payment = 'PAYMENT',
}

export type EmailExist = {
  __typename: 'EmailExist'
  exists: Scalars['Boolean']['output']
}

export type Invite = {
  __typename: 'Invite'
  createdAt: Maybe<Scalars['DateTime']['output']>
  expired: Scalars['Boolean']['output']
  id: Maybe<Scalars['String']['output']>
  participant: Maybe<TargetProfile>
  room: Maybe<Room>
  roomId: Scalars['String']['output']
  sender: Scalars['String']['output']
  target: Scalars['String']['output']
}

export type TargetProfile = {
  __typename: 'TargetProfile'
  language: Scalars['String']['output']
  user: TargetProfileUser
}

export type TargetProfileUser = {
  __typename: 'TargetProfileUser'
  displayName: Maybe<Scalars['String']['output']>
  firstName: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  image: Maybe<Scalars['String']['output']>
  lastName: Maybe<Scalars['String']['output']>
}

export type CouponFiltersInput = {
  state?: InputMaybe<CouponFilterState>
  text?: InputMaybe<Scalars['String']['input']>
}

/** State of coupons to filter by. */
export enum CouponFilterState {
  All = 'all',
  Available = 'available',
  UsedUp = 'usedUp',
}

export type CouponSummary = {
  __typename: 'CouponSummary'
  canceledAt: Maybe<Scalars['DateTime']['output']>
  code: Maybe<Scalars['String']['output']>
  createdAt: Maybe<Scalars['DateTime']['output']>
  createdBy: Maybe<Scalars['String']['output']>
  expiryDate: Maybe<Scalars['DateTime']['output']>
  id: Maybe<Scalars['String']['output']>
  multiAccount: Maybe<Scalars['Boolean']['output']>
  note: Maybe<Scalars['String']['output']>
  packageId: Maybe<Scalars['String']['output']>
  redemptionCount: Maybe<Scalars['Float']['output']>
  usesAvailable: Maybe<Scalars['Float']['output']>
  usesPerAccount: Maybe<Scalars['Float']['output']>
}

export type Slots = {
  __typename: 'Slots'
  slots: Maybe<Array<Maybe<Slot>>>
  start: Scalars['Date']['output']
}

export type Slot = {
  __typename: 'Slot'
  end: Scalars['Date']['output']
  shiftId: Scalars['String']['output']
  start: Scalars['Date']['output']
}

export type Block = {
  __typename: 'Block'
  blockedAt: Scalars['DateTime']['output']
  blockedUser: User
  id: Scalars['ID']['output']
  reason: Reason
  reportId: Maybe<Scalars['String']['output']>
  user: User
}

export enum Reason {
  Inappropriate = 'Inappropriate',
}

export type CallSummary = {
  __typename: 'CallSummary'
  amountOwed: Maybe<Scalars['Float']['output']>
  room: Maybe<Room>
  walletBalance: Maybe<WalletBalance>
}

export type Coupon = {
  __typename: 'Coupon'
  canceledAt: Maybe<Scalars['DateTime']['output']>
  code: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  createdBy: Maybe<Scalars['String']['output']>
  expiryDate: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  multiAccount: Scalars['Boolean']['output']
  note: Maybe<Scalars['String']['output']>
  package: CouponPackage
  redemptionsCount: Scalars['Float']['output']
  usesAvailable: Scalars['Float']['output']
  usesPerAccount: Scalars['Float']['output']
}

export type CouponPackage = {
  __typename: 'CouponPackage'
  coupon: Coupon
  id: Scalars['ID']['output']
  packageId: Scalars['String']['output']
}

export type Pricelist = {
  __typename: 'Pricelist'
  /** pricelist per call segment eg 15 minutes */
  callRate: CallRate
  id: Scalars['ID']['output']
  isDefault: Scalars['Boolean']['output']
  priceBooking: Scalars['Float']['output']
  priceInstant: Scalars['Float']['output']
  visible: Scalars['Boolean']['output']
}

export type ExchangeRate = {
  __typename: 'ExchangeRate'
  /** @deprecated Use uppercase alternative */
  czk: Scalars['Float']['output']
  CZK: Scalars['Float']['output']
  /** @deprecated Use uppercase alternative */
  eur: Scalars['Float']['output']
  EUR: Scalars['Float']['output']
}

export type Currency2 = {
  __typename: 'Currency2'
  exchangeRateToCZK: Scalars['Float']['output']
  name: Currency
  value: Scalars['Float']['output']
}

export type ExpiringTransaction = {
  __typename: 'ExpiringTransaction'
  amount: Scalars['Float']['output']
  expiration: Maybe<Scalars['DateTime']['output']>
  profileId: Scalars['String']['output']
  userId: Scalars['String']['output']
  walletId: Scalars['String']['output']
}

export type Favorite = {
  __typename: 'Favorite'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  studentId: Scalars['String']['output']
  tutorId: Scalars['String']['output']
}

export type Voucher = {
  __typename: 'Voucher'
  bonusAmount: Maybe<Scalars['Float']['output']>
  bonusAmountPercent: Maybe<Scalars['Float']['output']>
  code: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  creditAmount: Scalars['Float']['output']
  creditExpirationAbsolute: Maybe<Scalars['DateTime']['output']>
  /** Relative expiration in days */
  creditExpirationRelative: Maybe<Scalars['Float']['output']>
  expiration: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  maxRedemptions: Maybe<Scalars['Float']['output']>
  /** Gift from profileId. */
  profileId: Scalars['String']['output']
  redemptions: Scalars['Float']['output']
  /** Gift payment transaction. */
  txId: Scalars['String']['output']
  type: Maybe<VoucherType>
  /** Gift from userId. */
  userId: Scalars['String']['output']
}

/** Type of voucher. */
export enum VoucherType {
  FirstCall = 'FirstCall',
  FirstCallAccel = 'FirstCallAccel',
  Flexible = 'Flexible',
  Gift = 'Gift',
  Promo = 'Promo',
}

export type GiftVoucherType = {
  __typename: 'GiftVoucherType'
  bonusAmount: Scalars['Float']['output']
  createdAt: Scalars['DateTime']['output']
  creditAmount: Scalars['Float']['output']
  id: Scalars['ID']['output']
  state: Maybe<GiftVoucherStatus>
}

/** Status of GiftVoucherType. */
export enum GiftVoucherStatus {
  Hidden = 'Hidden',
  Visible = 'Visible',
}

export type Goal = {
  __typename: 'Goal'
  id: Scalars['ID']['output']
  name: Scalars['String']['output']
  packages: Array<Package>
}

export type GroupStudent = {
  __typename: 'GroupStudent'
  department: Maybe<Scalars['String']['output']>
  firstName: Maybe<Scalars['String']['output']>
  groupId: Scalars['String']['output']
  groupRole: GroupRole
  /** UserId */
  id: Scalars['String']['output']
  identifier: Scalars['String']['output']
  lastName: Maybe<Scalars['String']['output']>
  state: Maybe<GroupUserState>
  userId: Scalars['String']['output']
  wallet: Maybe<Wallet>
}

export type Wallet = {
  __typename: 'Wallet'
  balance: Scalars['Float']['output']
  groupId: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  options: WalletOptions
  type: WalletType
  updatedAt: Scalars['DateTime']['output']
  userId: Scalars['String']['output']
}

export type CheckCreditCallsResult = {
  __typename: 'CheckCreditCallsResult'
  expectedPrice: Scalars['Float']['output']
  missingCredit: Scalars['Float']['output']
  status: CheckCreditCallsResultStatus
}

export enum CheckCreditCallsResultStatus {
  CreditInsufficient = 'CreditInsufficient',
  CreditSufficient = 'CreditSufficient',
  CreditWillExpire = 'CreditWillExpire',
}

export type UserGoal = {
  __typename: 'UserGoal'
  createdAt: Scalars['DateTime']['output']
  goal: Goal
  id: Scalars['ID']['output']
  progress: Scalars['Int']['output']
  studentId: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
}

export type Note = {
  __typename: 'Note'
  createdAt: Scalars['DateTime']['output']
  deletedAt: Maybe<Scalars['DateTime']['output']>
  id: Scalars['ID']['output']
  note: Scalars['String']['output']
  roomId: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  userId: Scalars['String']['output']
}

export type PromoInfo = {
  __typename: 'PromoInfo'
  numberOfCalls: Scalars['Float']['output']
  numberOfReferrals: Scalars['Float']['output']
  promoCredit: Scalars['Float']['output']
}

export type Recommendation = {
  __typename: 'Recommendation'
  comment: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  deleted: Scalars['Boolean']['output']
  deletedDate: Scalars['DateTime']['output']
  givingUserId: Scalars['String']['output']
  id: Scalars['ID']['output']
  language: Scalars['String']['output']
  recommendedUserId: Scalars['String']['output']
}

export type ReferralStatistics = {
  __typename: 'ReferralStatistics'
  reward: Scalars['Float']['output']
  rewardTotalGained: Scalars['Float']['output']
  rewardTotalPending: Scalars['Float']['output']
}

export type Report = {
  __typename: 'Report'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  messageId: Scalars['String']['output']
  reason: Reason
  reportedUserId: Scalars['String']['output']
  reportingUserId: Scalars['String']['output']
  roomId: Scalars['String']['output']
  supportUserId: Scalars['String']['output']
}

export enum ScheduledCallResult {
  Both = 'Both',
  NotPassed = 'NotPassed',
  Passed = 'Passed',
}

export type Source = {
  __typename: 'Source'
  createdAt: Scalars['DateTime']['output']
  fraudCookie: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  landingPage: Scalars['String']['output']
  referer: Scalars['String']['output']
  referredByUser: Maybe<User>
  signUpCampaign: Maybe<Scalars['String']['output']>
  user: User
  utm: Scalars['JSONObject']['output']
}

export type StudentInvoice = {
  __typename: 'StudentInvoice'
  items: Array<StudentInvoiceItem>
  userId: Scalars['String']['output']
}

export type StudentInvoiceItem = {
  __typename: 'StudentInvoiceItem'
  amount: Scalars['Int']['output']
  createdAt: Scalars['DateTime']['output']
  groupId: Scalars['String']['output']
}

export type QuizInput = {
  /** whatever object you send */
  quiz: Scalars['JSONObject']['input']
}

export type TutorOutput = {
  __typename: 'TutorOutput'
  displayName: Maybe<Scalars['String']['output']>
  firstName: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  image: Maybe<Scalars['String']['output']>
  introduction: Maybe<Scalars['String']['output']>
  lastName: Maybe<Scalars['String']['output']>
  motto: Maybe<Scalars['String']['output']>
  slug: Maybe<Scalars['String']['output']>
  topic: Maybe<Scalars['String']['output']>
  userId: Scalars['String']['output']
}

export type TutorRevenueByMonthResponse = {
  __typename: 'TutorRevenueByMonthResponse'
  month: Scalars['Int']['output']
  revenue: Scalars['Float']['output']
  year: Scalars['Int']['output']
}

export type TutorInvoice = {
  __typename: 'TutorInvoice'
  items: Array<InvoiceItem>
  tutorId: Scalars['String']['output']
}

export type AuthResponse = {
  __typename: 'AuthResponse'
  expiresAt: Scalars['Date']['output']
  token: Scalars['String']['output']
}

export type CheckCreditResult = {
  __typename: 'CheckCreditResult'
  duration: Maybe<Scalars['Int']['output']>
  expectedPrice: Maybe<Scalars['Float']['output']>
  missingCredit: Scalars['Float']['output']
  status: CheckCreditResultStatus
}

export enum CheckCreditResultStatus {
  CreditInsufficient = 'CreditInsufficient',
  CreditSufficient = 'CreditSufficient',
  CreditWillExpire = 'CreditWillExpire',
}

export type UnreadInfo = {
  __typename: 'UnreadInfo'
  count: Maybe<Scalars['Float']['output']>
  hasUnread: Scalars['Boolean']['output']
  lastMessageFromUserId: Maybe<Scalars['String']['output']>
}

export type ResolvedId = {
  __typename: 'ResolvedId'
  errorMessage: Maybe<Scalars['String']['output']>
  id: Maybe<Scalars['ID']['output']>
  type: Maybe<Scalars['String']['output']>
}

export type EstimatedCallPrices = {
  __typename: 'EstimatedCallPrices'
  blabuCommission: Scalars['Float']['output']
  tutorRevenue: Scalars['Float']['output']
  vat: Scalars['Float']['output']
}

export type PurchaseUsage = {
  __typename: 'PurchaseUsage'
  lessonUsages: Array<LessonUsage>
  purchase: Purchase
}

export type LessonUsage = {
  __typename: 'LessonUsage'
  createdAt: Scalars['DateTime']['output']
  lessonId: Scalars['String']['output']
  purchase: Purchase
  roomId: Maybe<Scalars['String']['output']>
  studentId: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  usageStatus: LessonUsageStatus
}

export enum LessonUsageStatus {
  Booked = 'Booked',
  Done = 'Done',
  Expired = 'Expired',
  New = 'New',
}

export type DailyCalls = {
  __typename: 'DailyCalls'
  count: Scalars['Float']['output']
  date: Scalars['Date']['output']
}

export type DailyUsers = {
  __typename: 'DailyUsers'
  count: Scalars['Float']['output']
  date: Scalars['Date']['output']
}

export type Mutation = {
  __typename: 'Mutation'
  abortCall: Scalars['Boolean']['output']
  addGoalsToStudents: Scalars['Boolean']['output']
  addMyNewGoal: UserGoal
  addPromoCredit: Scalars['Boolean']['output']
  adminCancelShift: Shift
  adminCreateContact: Scalars['Boolean']['output']
  adminCreateConversation: Conversation
  adminCreateGroup: Group
  adminCreateTopic: Topic
  adminCreateTutor: Maybe<Tutor>
  adminDeleteSubscription: Scalars['Boolean']['output']
  adminEditRatingComment: Scalars['Boolean']['output']
  adminExpirePurchase: Scalars['Boolean']['output']
  adminSetNewPassword: Credential
  adminUpdateTutor: Maybe<Tutor>
  assignTherapist: Scalars['Boolean']['output']
  assignTutor: Scalars['Boolean']['output']
  assignTutorToPackage: Scalars['Boolean']['output']
  banUser: Ban
  blockConversation: Conversation
  /** @deprecated use callEndedv2 returning Room */
  callEnded: Scalars['Boolean']['output']
  callEndedv2: Maybe<Room>
  /** Admin or service can cancel promo credit. */
  cancelGiftUser: Scalars['String']['output']
  /** returns RoomID */
  cancelInvite: Maybe<Scalars['String']['output']>
  cancelInviteFixed: Invite
  cancelScheduledCall: Scalars['Boolean']['output']
  cancelShift: Shift
  /** Captures payment and finalize payment. */
  capturePaypalOrder: PaypalPayment
  changePassword: Maybe<Token>
  changeTherapist: Maybe<Scalars['Boolean']['output']>
  checkVerifyHash: Scalars['Boolean']['output']
  confirmEmail: Maybe<Token>
  /** returns RoomID */
  confirmInvite: Maybe<Scalars['String']['output']>
  confirmInviteFixed: Invite
  /** @deprecated Legacy endpoint, will be removed */
  confirmScheduledCall: Scalars['Boolean']['output']
  confirmScheduledCallByPaymentId: Scalars['Boolean']['output']
  confirmScheduledCallByUserId: Scalars['Boolean']['output']
  /** Finish sodexo payment. */
  confirmSodexoPayment: Scalars['Boolean']['output']
  connectToRoom: ConnectedRoom
  createBlock: Block
  createContact: Scalars['Boolean']['output']
  createConversation: Conversation
  createCoupon: Coupon
  createCouponBatch: Array<Coupon>
  createCouponPayment: Scalars['Boolean']['output']
  createFavorite: Favorite
  createGiftVoucher: Voucher
  createGoal: Goal
  createGroup: Group
  createGroupMember: GroupStudent
  createInvoice: Invoice
  /** @deprecated use createPackageSimplified */
  createLesson: Lesson
  createMessage: Message
  createNewCredentials: User
  createNote: Note
  /** @deprecated use createPackageSimplified */
  createPackage: Package
  createPackageSimplified: Package
  createPasswordAndVerify: Maybe<Token>
  /** Initialize paypal payment process with amount in CZK. */
  createPaypalOrder2: CreatePaypalOrderResponse
  createQuiz: Quiz
  createRating: Rating
  createRecommendation: Recommendation
  createReferral: Referral
  createReport: Report
  createShift: Shift
  createStudent: Student
  createStudentInvoice: Invoice
  createSystemMessage: Message
  /** @deprecated use createTutor2 with access to `intros` field */
  createTutor: Tutor
  createUser: Maybe<CreateUserResponse>
  createVerifyCredential: Credential
  createVoucher: Voucher
  deleteNote: Note
  deleteRecommendation: Scalars['Boolean']['output']
  deleteUser: Scalars['Boolean']['output']
  editRatingComment: Scalars['Boolean']['output']
  event: Maybe<Scalars['String']['output']>
  forgotPassword: Maybe<Scalars['Boolean']['output']>
  galleryBetaPayment: GalleryBetaPaymentState
  generateInvoicesMonthlyForInterval: Scalars['Boolean']['output']
  generateTutorInvoicesDailyForInterval: Scalars['Boolean']['output']
  getNewHash: Maybe<Scalars['String']['output']>
  /** if the credential is outdated, create new */
  getOrRenewVerifyCredential: Credential
  /**
   * Admin or service can gift user.
   * @deprecated Use addPromoCredit
   */
  giftUser: Scalars['String']['output']
  googleAuth: Maybe<GoogleAuthResponse>
  googleLogin: Maybe<GoogleAuthResponse>
  googleSignUp: Maybe<GoogleAuthResponse>
  import: Scalars['Boolean']['output']
  initBenefitPlusPayment: BenefitPlusPayload
  initMolliePayment: InitPayment
  /** Initialize payment process with amount in CZK. Returns redirect URL or payment state in case of one click payment. */
  initPayment2: InitPayment
  initPayUPayment: InitPayment
  /** Initialize payment process with amount in CZK. Returns redirect URL. */
  initSodexoPayment2: Scalars['String']['output']
  insertTransaction: Scalars['Boolean']['output']
  invalidate: Coupon
  invalidateUser: Scalars['Boolean']['output']
  inviteGroupMember: Maybe<GroupStudent>
  /** returns RoomID */
  inviteToCall: Maybe<Scalars['String']['output']>
  login: Maybe<Login>
  logout: Maybe<Scalars['Boolean']['output']>
  notify: Scalars['Boolean']['output']
  notifyScheduledCall: Scalars['Boolean']['output']
  /** Student pays for a call. */
  payCall: Array<Transaction>
  prolongCall: ProlongCallResult
  purchasePackage: Package
  /** Mutation to purchase package without payment. SourceId should contain eg voucher triggering transaction. */
  purchasePackageWithTransaction: Scalars['Boolean']['output']
  rateUser: Rating
  redeem: CouponRedemption
  redeemPromo: Scalars['Boolean']['output']
  redeemVoucher: VoucherResult
  refreshCouponSummary: Scalars['Boolean']['output']
  refundPayUPayment: Scalars['Boolean']['output']
  /** Admin can refund user. */
  refundUser: Scalars['String']['output']
  /** returns RoomID */
  rejectInvite: Maybe<Scalars['String']['output']>
  rejectInviteFixed: Invite
  removeTutorFromPackage: Scalars['Boolean']['output']
  resendSignUpMail: Maybe<Scalars['Boolean']['output']>
  restorePasswordInit: Credential
  saveIp: Scalars['String']['output']
  saveNote: Maybe<Note>
  /** @deprecated use new flow with createQuiz and updateQuiz */
  saveQuiz: Scalars['Boolean']['output']
  scheduleCall: ScheduledCall
  sendInvite: Invite
  sendInviteToScheduledCall: Invite
  sendMail: Scalars['Boolean']['output']
  sendMessage: Maybe<Message>
  sendNewPassword: Maybe<Scalars['Boolean']['output']>
  sendScheduledCallCancelled: Scalars['Boolean']['output']
  sendSystemMessage: Scalars['Boolean']['output']
  sendVerification: Maybe<Scalars['Boolean']['output']>
  serviceCancelUserVerification: Scalars['Boolean']['output']
  serviceCreateMessage: Message
  serviceCreateWallet: Wallet
  /** Update wallet options for group and groupUser wallets */
  serviceUpdateWalletOptions: Wallet
  setCurrency: Scalars['Boolean']['output']
  setHash: Scalars['Boolean']['output']
  /** @deprecated use createPackageSimplified */
  setLessonUsageStatus: Lesson
  setMessageRead: Scalars['Boolean']['output']
  setRatingIgnored: Rating
  setSource: Scalars['Boolean']['output']
  setStatus: Scalars['Boolean']['output']
  setTutorActiveState: Scalars['Boolean']['output']
  setTutorPrice: Tutor
  setVisibility: Scalars['Boolean']['output']
  setWebLanguage: Scalars['Boolean']['output']
  signUp: Maybe<Token>
  signUpClient: SignUpOutput
  signupStudent: User
  signUpWithoutPassword: Maybe<Scalars['Boolean']['output']>
  startCall: StartCallType
  storePermissionCheck: PermissionCheck
  subscribeForNotification: Subscriber
  /** Admin can refund previous user's transaction. */
  transactionRefundUser: Scalars['String']['output']
  transferVoucherGift: Scalars['Boolean']['output']
  unreadMessageNotification: Scalars['Boolean']['output']
  unsubscribeNotification: Scalars['Boolean']['output']
  updateBilling: Billing
  updateGroup: Maybe<Group>
  updateGroupMember: Maybe<GroupStudent>
  updateHash: SignUpOutput
  updateInvoiceId: Scalars['Boolean']['output']
  updateMolliePayment: Scalars['Boolean']['output']
  updatePayUPayment: Scalars['Boolean']['output']
  updateQuiz: Scalars['Boolean']['output']
  updateRating: Scalars['Boolean']['output']
  updateScheduledCallPaymentId: Scalars['Boolean']['output']
  updateShift: Shift
  updateUser: Maybe<User>
  /** Update wallet options for groupUser wallets */
  updateWalletOptions: Wallet
  useLesson: Scalars['Boolean']['output']
  userAction: Scalars['Boolean']['output']
  verifyEmail: Maybe<Scalars['Boolean']['output']>
  verifyEmailContact: Scalars['Boolean']['output']
  verifyRestoreHash: Maybe<Token>
}

export type MutationAbortCallArgs = {
  roomId: Scalars['String']['input']
}

export type MutationAddMyNewGoalArgs = {
  goalId: Scalars['String']['input']
}

export type MutationAddPromoCreditArgs = {
  data: PromoCreditInput
}

export type MutationAdminCancelShiftArgs = {
  shiftId: Scalars['String']['input']
}

export type MutationAdminCreateContactArgs = {
  contactInput: ContactInput
}

export type MutationAdminCreateConversationArgs = {
  conversationInput: AdminConversationInput
}

export type MutationAdminCreateGroupArgs = {
  groupInput: GroupInput
  name: Scalars['String']['input']
  type: GroupType
}

export type MutationAdminCreateTopicArgs = {
  topicInput: TopicInput
}

export type MutationAdminCreateTutorArgs = {
  image?: InputMaybe<Scalars['Upload']['input']>
  tutor: AdminTutorInput
}

export type MutationAdminDeleteSubscriptionArgs = {
  id: Scalars['String']['input']
}

export type MutationAdminEditRatingCommentArgs = {
  comment: Scalars['String']['input']
  id: Scalars['String']['input']
}

export type MutationAdminExpirePurchaseArgs = {
  purchaseId: Scalars['String']['input']
}

export type MutationAdminSetNewPasswordArgs = {
  identifier: Scalars['String']['input']
  newPassword: Scalars['String']['input']
}

export type MutationAdminUpdateTutorArgs = {
  image?: InputMaybe<Scalars['Upload']['input']>
  tutor: AdminTutorInput
}

export type MutationAssignTherapistArgs = {
  studentId: Scalars['String']['input']
  tutorId: Scalars['String']['input']
}

export type MutationAssignTutorArgs = {
  tutorId: Scalars['String']['input']
}

export type MutationAssignTutorToPackageArgs = {
  packageId: Scalars['String']['input']
  tutorId: Scalars['String']['input']
}

export type MutationBanUserArgs = {
  banInput: BanInput
}

export type MutationBlockConversationArgs = {
  id: Scalars['String']['input']
}

export type MutationCallEndedArgs = {
  meta?: InputMaybe<CallEndedInfo>
  reason?: InputMaybe<Scalars['String']['input']>
  roomId: Scalars['String']['input']
  trigger: EndCallTrigger
}

export type MutationCallEndedv2Args = {
  meta?: InputMaybe<CallEndedInfo>
  reason?: InputMaybe<Scalars['String']['input']>
  roomId: Scalars['String']['input']
  trigger: EndCallTrigger
}

export type MutationCancelGiftUserArgs = {
  data: CancelGiftInput
}

export type MutationCancelInviteArgs = {
  invite: InviteInput
}

export type MutationCancelInviteFixedArgs = {
  invite: InviteInput
}

export type MutationCancelScheduledCallArgs = {
  cancellationReason?: InputMaybe<Scalars['String']['input']>
  id: Scalars['String']['input']
}

export type MutationCancelShiftArgs = {
  shiftId: Scalars['String']['input']
}

export type MutationCapturePaypalOrderArgs = {
  orderId: Scalars['String']['input']
}

export type MutationChangePasswordArgs = {
  newPassword: Scalars['String']['input']
}

export type MutationChangeTherapistArgs = {
  tutorId: Scalars['String']['input']
}

export type MutationCheckVerifyHashArgs = {
  hash: Scalars['String']['input']
}

export type MutationConfirmEmailArgs = {
  token: Scalars['String']['input']
}

export type MutationConfirmInviteArgs = {
  invite: InviteInput
}

export type MutationConfirmInviteFixedArgs = {
  invite: InviteInput
}

export type MutationConfirmScheduledCallArgs = {
  id: Scalars['String']['input']
  purchaseId: Scalars['String']['input']
}

export type MutationConfirmScheduledCallByPaymentIdArgs = {
  clientUserId: Scalars['String']['input']
  paymentId: Scalars['String']['input']
  purchaseId: Scalars['String']['input']
}

export type MutationConfirmScheduledCallByUserIdArgs = {
  clientUserId: Scalars['String']['input']
  purchaseId: Scalars['String']['input']
  scheduledCallId: Scalars['String']['input']
}

export type MutationConfirmSodexoPaymentArgs = {
  orderNumber: Scalars['String']['input']
}

export type MutationConnectToRoomArgs = {
  id: Scalars['String']['input']
}

export type MutationCreateBlockArgs = {
  block: BlockInput
}

export type MutationCreateContactArgs = {
  contactInput: ContactInput
}

export type MutationCreateConversationArgs = {
  conversationInput: ConversationInput
}

export type MutationCreateCouponArgs = {
  data: CouponInput
}

export type MutationCreateCouponBatchArgs = {
  data: CouponBatchInput
}

export type MutationCreateCouponPaymentArgs = {
  couponId: Scalars['String']['input']
  packageId: Scalars['String']['input']
  profileId: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type MutationCreateFavoriteArgs = {
  favorite: FavoriteInput
}

export type MutationCreateGiftVoucherArgs = {
  data: GiftVoucherInput
}

export type MutationCreateGoalArgs = {
  name: Scalars['String']['input']
  packageIds: Array<Scalars['String']['input']>
}

export type MutationCreateGroupArgs = {
  name: Scalars['String']['input']
  type: GroupType
}

export type MutationCreateGroupMemberArgs = {
  department?: InputMaybe<Scalars['String']['input']>
  groupId: Scalars['String']['input']
  groupRole: GroupRole
  state: GroupUserState
  userId: Scalars['String']['input']
}

export type MutationCreateInvoiceArgs = {
  from: Scalars['DateTime']['input']
  tutorUserId: Scalars['String']['input']
  until: Scalars['DateTime']['input']
}

export type MutationCreateLessonArgs = {
  LessonInput: LessonInput
}

export type MutationCreateMessageArgs = {
  message: MessageInput
}

export type MutationCreateNewCredentialsArgs = {
  credential: CredentialInput
  userId: Scalars['String']['input']
}

export type MutationCreateNoteArgs = {
  noteInput: NoteInput
}

export type MutationCreatePackageArgs = {
  PackageInput: PackageInput
}

export type MutationCreatePackageSimplifiedArgs = {
  PackageLessonInput: PackageLessonInput
}

export type MutationCreatePasswordAndVerifyArgs = {
  hash: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type MutationCreatePaypalOrder2Args = {
  paymentInput: PaypalInput
}

export type MutationCreateQuizArgs = {
  quizInput: QuizInput
}

export type MutationCreateRatingArgs = {
  rating: RatingInput
}

export type MutationCreateRecommendationArgs = {
  recommendation: RecommendationInput
}

export type MutationCreateReferralArgs = {
  referrerUserId: Scalars['String']['input']
}

export type MutationCreateReportArgs = {
  report: ReportInput
}

export type MutationCreateShiftArgs = {
  shiftInput: ShiftInput
}

export type MutationCreateStudentInvoiceArgs = {
  billingId: Scalars['String']['input']
  paymentId: Scalars['String']['input']
}

export type MutationCreateSystemMessageArgs = {
  message: MessageInput
  shouldPublish?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationCreateTutorArgs = {
  tutor: TutorInput
}

export type MutationCreateUserArgs = {
  credential: CredentialInput
  image?: InputMaybe<Scalars['Upload']['input']>
  user: UserInput
}

export type MutationCreateVerifyCredentialArgs = {
  identifier: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type MutationCreateVoucherArgs = {
  data: VoucherInput
}

export type MutationDeleteNoteArgs = {
  noteId: Scalars['String']['input']
}

export type MutationDeleteRecommendationArgs = {
  recommendationId: Scalars['String']['input']
}

export type MutationDeleteUserArgs = {
  id: Scalars['String']['input']
}

export type MutationEditRatingCommentArgs = {
  comment: Scalars['String']['input']
  id: Scalars['String']['input']
}

export type MutationEventArgs = {
  event: EventInput
}

export type MutationForgotPasswordArgs = {
  identifier: Scalars['String']['input']
}

export type MutationGalleryBetaPaymentArgs = {
  galleryBetaInput: GalleryBetaInput
}

export type MutationGenerateInvoicesMonthlyForIntervalArgs = {
  from: Scalars['DateTime']['input']
  until: Scalars['DateTime']['input']
}

export type MutationGenerateTutorInvoicesDailyForIntervalArgs = {
  from: Scalars['DateTime']['input']
  until: Scalars['DateTime']['input']
}

export type MutationGetNewHashArgs = {
  identifier: Scalars['String']['input']
}

export type MutationGetOrRenewVerifyCredentialArgs = {
  identifier: Scalars['String']['input']
}

export type MutationGiftUserArgs = {
  data: GiftInput
}

export type MutationGoogleAuthArgs = {
  code: Scalars['String']['input']
  currency?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<WebLanguage>
  sourceInput?: InputMaybe<SourceInput>
  timezone?: InputMaybe<Scalars['String']['input']>
}

export type MutationGoogleLoginArgs = {
  code: Scalars['String']['input']
}

export type MutationGoogleSignUpArgs = {
  code: Scalars['String']['input']
  currency?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<WebLanguage>
  sourceInput?: InputMaybe<SourceInput>
  timezone?: InputMaybe<Scalars['String']['input']>
}

export type MutationImportArgs = {
  login: Scalars['String']['input']
  verified: Scalars['Boolean']['input']
}

export type MutationInitBenefitPlusPaymentArgs = {
  benefitPlusInput: BenefitPlusInput
}

export type MutationInitMolliePaymentArgs = {
  molliePaymentInput: MolliePaymentInput
}

export type MutationInitPayment2Args = {
  paymentInput: CsobInput
}

export type MutationInitPayUPaymentArgs = {
  payUPaymentInput: PayUPaymentInput
}

export type MutationInitSodexoPayment2Args = {
  paymentInput: SodexoInput
}

export type MutationInsertTransactionArgs = {
  data: PromoCreditInput
}

export type MutationInvalidateArgs = {
  couponId: Scalars['String']['input']
}

export type MutationInvalidateUserArgs = {
  userId: Scalars['String']['input']
}

export type MutationInviteGroupMemberArgs = {
  department?: InputMaybe<Scalars['String']['input']>
  firstName: Scalars['String']['input']
  groupId: Scalars['String']['input']
  groupRole: GroupRole
  identifier: Scalars['String']['input']
  lastName: Scalars['String']['input']
  state: GroupUserState
  walletOptions: WalletOptionsInput
}

export type MutationInviteToCallArgs = {
  invite: InviteToCallInput
}

export type MutationLoginArgs = {
  identifier: Scalars['String']['input']
  password: Scalars['String']['input']
}

export type MutationNotifyArgs = {
  notificationInput: NotificationInput
}

export type MutationNotifyScheduledCallArgs = {
  input: NotifyScheduledCallInput
}

export type MutationPayCallArgs = {
  data: PayCallInput
}

export type MutationProlongCallArgs = {
  roomId: Scalars['String']['input']
}

export type MutationPurchasePackageArgs = {
  packageId: Scalars['String']['input']
  paymentId?: InputMaybe<Scalars['String']['input']>
  studentId: Scalars['String']['input']
  tutorId?: InputMaybe<Scalars['String']['input']>
  userId: Scalars['String']['input']
}

export type MutationPurchasePackageWithTransactionArgs = {
  packageId: Scalars['String']['input']
  profileId: Scalars['String']['input']
  sourceId?: InputMaybe<Scalars['String']['input']>
  tutorId: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type MutationRateUserArgs = {
  rating: RatingInput
}

export type MutationRedeemArgs = {
  code: Scalars['String']['input']
}

export type MutationRedeemPromoArgs = {
  paymentId: Scalars['String']['input']
  profileId: Scalars['String']['input']
  promoCode: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type MutationRedeemVoucherArgs = {
  voucherCode: Scalars['String']['input']
}

export type MutationRefundPayUPaymentArgs = {
  orderId: Scalars['String']['input']
  refundId: Scalars['String']['input']
}

export type MutationRefundUserArgs = {
  data: RefundInput
}

export type MutationRejectInviteArgs = {
  invite: InviteInput
}

export type MutationRejectInviteFixedArgs = {
  invite: InviteInput
}

export type MutationRemoveTutorFromPackageArgs = {
  packageId: Scalars['String']['input']
  tutorId: Scalars['String']['input']
}

export type MutationResendSignUpMailArgs = {
  coupon?: InputMaybe<Scalars['String']['input']>
  identifier: Scalars['String']['input']
}

export type MutationRestorePasswordInitArgs = {
  identifier: Scalars['String']['input']
}

export type MutationSaveIpArgs = {
  ip: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type MutationSaveNoteArgs = {
  callFinished?: InputMaybe<Scalars['Boolean']['input']>
  noteInput: NoteInput
  tutorName: Scalars['String']['input']
}

export type MutationSaveQuizArgs = {
  notify?: InputMaybe<Scalars['Boolean']['input']>
  quizInput: QuizInput
}

export type MutationScheduleCallArgs = {
  scheduleCallInput: NewScheduleCallInput
}

export type MutationSendInviteArgs = {
  duration?: InputMaybe<Scalars['Int']['input']>
  lessonId?: InputMaybe<Scalars['String']['input']>
  tutorId: Scalars['String']['input']
}

export type MutationSendInviteToScheduledCallArgs = {
  scheduledCallId: Scalars['String']['input']
}

export type MutationSendMailArgs = {
  message: MailInput
}

export type MutationSendMessageArgs = {
  message: MessageInput
}

export type MutationSendNewPasswordArgs = {
  identifier: Scalars['String']['input']
}

export type MutationSendScheduledCallCancelledArgs = {
  input: ScheduledCallCancelledInput
}

export type MutationSendSystemMessageArgs = {
  messageInput: SystemMessageInput
}

export type MutationSendVerificationArgs = {
  identifier: Scalars['String']['input']
}

export type MutationServiceCancelUserVerificationArgs = {
  identifier: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type MutationServiceCreateMessageArgs = {
  message: ServiceMessageInput
}

export type MutationServiceCreateWalletArgs = {
  groupId?: InputMaybe<Scalars['String']['input']>
  opts?: InputMaybe<WalletOptionsInput>
  type: WalletType
  userId: Scalars['String']['input']
}

export type MutationServiceUpdateWalletOptionsArgs = {
  opts: WalletOptionsInput
  walletId: Scalars['String']['input']
}

export type MutationSetCurrencyArgs = {
  currency: Scalars['String']['input']
}

export type MutationSetHashArgs = {
  hash: Scalars['String']['input']
  identifier: Scalars['String']['input']
}

export type MutationSetLessonUsageStatusArgs = {
  lessonId: Scalars['String']['input']
  usageStatus: LessonUsageStatus
}

export type MutationSetMessageReadArgs = {
  messageId: Scalars['String']['input']
}

export type MutationSetRatingIgnoredArgs = {
  id: Scalars['String']['input']
  ignored?: InputMaybe<Scalars['Boolean']['input']>
}

export type MutationSetSourceArgs = {
  sourceInput: SourceInput
}

export type MutationSetStatusArgs = {
  status: StatusInput
}

export type MutationSetTutorActiveStateArgs = {
  isActive: Scalars['Boolean']['input']
  tutorId: Scalars['String']['input']
}

export type MutationSetTutorPriceArgs = {
  price: Scalars['Float']['input']
  type: TutorPriceType
}

export type MutationSetVisibilityArgs = {
  id: Scalars['String']['input']
  visible: Scalars['Boolean']['input']
}

export type MutationSetWebLanguageArgs = {
  webLanguage: WebLanguage
}

export type MutationSignUpArgs = {
  currency?: InputMaybe<Scalars['String']['input']>
  identifier: Scalars['String']['input']
  language?: InputMaybe<WebLanguage>
  password: Scalars['String']['input']
  sourceInput?: InputMaybe<SourceInput>
  timezone?: InputMaybe<Scalars['String']['input']>
}

export type MutationSignUpClientArgs = {
  credential: CredentialInput
  user: UserInput
}

export type MutationSignupStudentArgs = {
  credential: CredentialInput
  user: UserInput
}

export type MutationSignUpWithoutPasswordArgs = {
  coupon?: InputMaybe<Scalars['String']['input']>
  currency?: InputMaybe<Scalars['String']['input']>
  identifier: Scalars['String']['input']
  language?: InputMaybe<WebLanguage>
  sourceInput?: InputMaybe<SourceInput>
  therapistId?: InputMaybe<Scalars['String']['input']>
  timezone?: InputMaybe<Scalars['String']['input']>
}

export type MutationStartCallArgs = {
  startCall: StartCallInput
}

export type MutationStorePermissionCheckArgs = {
  data: Scalars['JSON']['input']
  testId: Scalars['String']['input']
}

export type MutationSubscribeForNotificationArgs = {
  notificationSubscriptionInput: NotificationSubscriptionInput
}

export type MutationTransactionRefundUserArgs = {
  data: TransactionRefundInput
}

export type MutationTransferVoucherGiftArgs = {
  bonus: Scalars['Float']['input']
  profileId: Scalars['String']['input']
  txId: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type MutationUnreadMessageNotificationArgs = {
  conversationId: Scalars['String']['input']
  fromUserId: Scalars['String']['input']
  toUserId: Scalars['String']['input']
}

export type MutationUnsubscribeNotificationArgs = {
  notificationSubscriptionInput: NotificationSubscriptionInput
}

export type MutationUpdateBillingArgs = {
  billing: BillingInput
}

export type MutationUpdateGroupArgs = {
  billing?: InputMaybe<GroupBillingInput>
  contact?: InputMaybe<GroupContactInput>
  groupId: Scalars['String']['input']
  image?: InputMaybe<Scalars['Upload']['input']>
}

export type MutationUpdateGroupMemberArgs = {
  department?: InputMaybe<Scalars['String']['input']>
  groupId: Scalars['String']['input']
  groupRole: GroupRole
  state: GroupUserState
  userId: Scalars['String']['input']
  walletOptions: WalletOptionsInput
}

export type MutationUpdateHashArgs = {
  hash: Scalars['String']['input']
  newHash: Scalars['String']['input']
}

export type MutationUpdateInvoiceIdArgs = {
  invoiceId: Scalars['String']['input']
  paymentId: Scalars['String']['input']
}

export type MutationUpdateMolliePaymentArgs = {
  mollieId: Scalars['String']['input']
}

export type MutationUpdatePayUPaymentArgs = {
  orderId: Scalars['String']['input']
  payMethod?: InputMaybe<Scalars['String']['input']>
  state: PayUPaymentState
}

export type MutationUpdateQuizArgs = {
  finished: Scalars['Boolean']['input']
  id: Scalars['String']['input']
  quizInput: QuizInput
}

export type MutationUpdateRatingArgs = {
  profileId: Scalars['String']['input']
  rating: Scalars['Float']['input']
}

export type MutationUpdateScheduledCallPaymentIdArgs = {
  paymentId: Scalars['String']['input']
  scheduledCallId: Scalars['String']['input']
}

export type MutationUpdateShiftArgs = {
  shiftInput: ShiftInput
}

export type MutationUpdateUserArgs = {
  image?: InputMaybe<Scalars['Upload']['input']>
  tutor?: InputMaybe<TutorUpdateInput>
  user: UserInput
}

export type MutationUpdateWalletOptionsArgs = {
  groupId: Scalars['String']['input']
  opts: WalletOptionsInput
  userId: Scalars['String']['input']
}

export type MutationUseLessonArgs = {
  lessonId: Scalars['String']['input']
  purchaseId?: InputMaybe<Scalars['String']['input']>
  roomId?: InputMaybe<Scalars['String']['input']>
  studentId: Scalars['String']['input']
  tutorId?: InputMaybe<Scalars['String']['input']>
  usageStatus?: InputMaybe<LessonUsageStatus>
}

export type MutationUserActionArgs = {
  userActionInput: UserActionInput
}

export type MutationVerifyEmailContactArgs = {
  email: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type MutationVerifyRestoreHashArgs = {
  hash: Scalars['String']['input']
}

export type PromoCreditInput = {
  amount: Scalars['Float']['input']
  comment?: InputMaybe<Scalars['String']['input']>
  createdAt?: InputMaybe<Scalars['DateTime']['input']>
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>
  profileId: Scalars['String']['input']
  sourceId?: InputMaybe<Scalars['String']['input']>
  transactionType: TransactionType
  userId: Scalars['String']['input']
}

export type ContactInput = {
  contact: Scalars['String']['input']
  /** id of contact you want to update */
  contactId?: InputMaybe<Scalars['String']['input']>
  primary?: InputMaybe<Scalars['Boolean']['input']>
  type: ContactType
  userId?: InputMaybe<Scalars['String']['input']>
}

export enum ContactType {
  Billing = 'Billing',
  Email = 'Email',
  Phone = 'Phone',
}

export type AdminConversationInput = {
  studentUserId: Scalars['String']['input']
  tutorUserId: Scalars['String']['input']
  type?: InputMaybe<ConversationType>
  visible?: InputMaybe<ConversationVisibility>
}

/** Type of conversation */
export enum ConversationType {
  Basic = 'Basic',
  RejectedCall = 'RejectedCall',
  StartWithChat = 'StartWithChat',
}

/** Visibility */
export enum ConversationVisibility {
  Both = 'Both',
  StudentOnly = 'StudentOnly',
  TutorOnly = 'TutorOnly',
}

export type GroupInput = {
  billing?: InputMaybe<GroupBillingInput>
  contact?: InputMaybe<GroupContactInput>
  logo?: InputMaybe<Scalars['String']['input']>
}

export type GroupBillingInput = {
  city: Scalars['String']['input']
  /** ICO */
  companyId?: InputMaybe<Scalars['String']['input']>
  countryCode?: InputMaybe<Scalars['String']['input']>
  email: Scalars['String']['input']
  street: Scalars['String']['input']
  /** DIC */
  taxId?: InputMaybe<Scalars['String']['input']>
  zip: Scalars['String']['input']
}

export type GroupContactInput = {
  email: Scalars['String']['input']
  firstName: Scalars['String']['input']
  lastName: Scalars['String']['input']
  phone: Scalars['String']['input']
}

export type TopicInput = {
  description?: InputMaybe<Scalars['String']['input']>
  name: Scalars['String']['input']
}

export type AdminTutorInput = {
  id?: InputMaybe<Scalars['String']['input']>
  introduction?: InputMaybe<Scalars['String']['input']>
  language?: InputMaybe<Scalars['String']['input']>
  motto?: InputMaybe<Scalars['String']['input']>
  rating?: InputMaybe<Scalars['Float']['input']>
  topicIds?: InputMaybe<Array<Scalars['String']['input']>>
  user?: InputMaybe<UserUpdateInput>
}

export type UserUpdateInput = {
  birthday?: InputMaybe<Scalars['DateTime']['input']>
  countryCode: Scalars['String']['input']
  credential: CredentialUpdateInput
  displayName?: InputMaybe<Scalars['String']['input']>
  firstName: Scalars['String']['input']
  gender?: InputMaybe<Gender>
  id?: InputMaybe<Scalars['String']['input']>
  image?: InputMaybe<Scalars['String']['input']>
  lastName: Scalars['String']['input']
  middleName?: InputMaybe<Scalars['String']['input']>
  timezone: Scalars['String']['input']
}

export type CredentialUpdateInput = {
  hash?: InputMaybe<Scalars['String']['input']>
  id?: InputMaybe<Scalars['String']['input']>
  identifier: Scalars['String']['input']
}

export type BanInput = {
  comment?: InputMaybe<Scalars['String']['input']>
  userId: Scalars['String']['input']
}

export type CallEndedInfo = {
  /** whatever object you send */
  values: Scalars['JSONObject']['input']
}

export type CancelGiftInput = {
  amount: Scalars['Float']['input']
  comment?: InputMaybe<Scalars['String']['input']>
  profileId: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type InviteInput = {
  expired?: InputMaybe<Scalars['Boolean']['input']>
  profileId: Scalars['String']['input']
  reason?: InputMaybe<RejectReason>
  roomId: Scalars['String']['input']
}

export enum RejectReason {
  FinishingShift = 'FinishingShift',
  NeedBreak = 'NeedBreak',
  Other = 'Other',
}

export type PaypalPayment = Payment & {
  __typename: 'PaypalPayment'
  amount: Scalars['Float']['output']
  amountExcludedVat: Scalars['Float']['output']
  blabuState: BlabuPaymentStateType
  createdAt: Scalars['DateTime']['output']
  exchangeRate: Maybe<Scalars['Float']['output']>
  externalId: Maybe<Scalars['String']['output']>
  gatewayData: PaypalGatewayData
  groupId: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  invoiceId: Maybe<Scalars['String']['output']>
  originalAmount: Scalars['Float']['output']
  originalCurrency: Currency
  package: Maybe<Package>
  profileId: Scalars['String']['output']
  promoCode: Maybe<Scalars['String']['output']>
  state: Scalars['String']['output']
  targetId: Maybe<Scalars['String']['output']>
  targetType: Maybe<TargetType>
  tutorId: Maybe<Scalars['String']['output']>
  txId: Scalars['String']['output']
  type: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  /** User wiling to use current credit to partially cover package/voucher purchase */
  useCredit: Maybe<Scalars['Boolean']['output']>
  userId: Scalars['String']['output']
  vat: Maybe<Scalars['Float']['output']>
  vatCountry: Maybe<CountryType>
  vatPercentage: Maybe<Scalars['Float']['output']>
}

export type PaypalGatewayData = {
  __typename: 'PaypalGatewayData'
  captureId: Maybe<Scalars['String']['output']>
  issue: Maybe<Scalars['String']['output']>
  issueDescription: Maybe<Scalars['String']['output']>
  orderId: Scalars['String']['output']
  payerId: Maybe<Scalars['String']['output']>
  state: PaypalPaymentStateType
}

/** Type of Paypal payment states. */
export enum PaypalPaymentStateType {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Failed = 'FAILED',
}

export type Token = {
  __typename: 'Token'
  token: Scalars['String']['output']
}

export type ConnectedRoom = {
  __typename: 'ConnectedRoom'
  participant: Participant
  reconnect: Maybe<Scalars['Boolean']['output']>
  room: Room
  token: Scalars['String']['output']
}

export type BlockInput = {
  blockedUserId: Scalars['String']['input']
  reason: Reason
  reportId?: InputMaybe<Scalars['String']['input']>
  userId: Scalars['String']['input']
}

export type ConversationInput = {
  isCall: Scalars['Boolean']['input']
  partnerUserId: Scalars['String']['input']
}

export type CouponInput = {
  code: Scalars['String']['input']
  createdBy?: InputMaybe<Scalars['String']['input']>
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>
  multiAccount?: InputMaybe<Scalars['Boolean']['input']>
  note?: InputMaybe<Scalars['String']['input']>
  packageId: Scalars['String']['input']
  usesAvailable?: InputMaybe<Scalars['Float']['input']>
  usesPerAccount?: InputMaybe<Scalars['Float']['input']>
}

export type CouponBatchInput = {
  createdBy?: InputMaybe<Scalars['String']['input']>
  expiryDate?: InputMaybe<Scalars['DateTime']['input']>
  lengthOfCode: Scalars['Float']['input']
  multiAccount?: InputMaybe<Scalars['Boolean']['input']>
  note?: InputMaybe<Scalars['String']['input']>
  numberOfCoupons: Scalars['Float']['input']
  packageId: Scalars['String']['input']
  usesAvailable?: InputMaybe<Scalars['Float']['input']>
  usesPerAccount?: InputMaybe<Scalars['Float']['input']>
}

export type FavoriteInput = {
  studentId: Scalars['String']['input']
  tutorId: Scalars['String']['input']
}

export type GiftVoucherInput = {
  giftVoucherTypeId: Scalars['String']['input']
  profileId: Scalars['String']['input']
  txId: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type LessonInput = {
  no: Scalars['Float']['input']
  packageId: Scalars['String']['input']
  units: Scalars['Float']['input']
}

export type MessageInput = {
  content: Scalars['String']['input']
  conversationId?: InputMaybe<Scalars['String']['input']>
  fromUserId?: InputMaybe<Scalars['String']['input']>
  roomId?: InputMaybe<Scalars['String']['input']>
  toUserId?: InputMaybe<Scalars['String']['input']>
}

export type CredentialInput = {
  hash?: InputMaybe<Scalars['String']['input']>
  identifier: Scalars['String']['input']
  type: CredentialType
}

export type NoteInput = {
  note: Scalars['String']['input']
  roomId: Scalars['String']['input']
}

export type PackageInput = {
  commission?: InputMaybe<Scalars['Float']['input']>
  name: Scalars['String']['input']
  preSaleBonus?: InputMaybe<Scalars['Float']['input']>
  price?: InputMaybe<Scalars['Float']['input']>
  state?: InputMaybe<PackageStatus>
}

export type PackageLessonInput = {
  commission?: InputMaybe<Scalars['Float']['input']>
  /** array of lesson units in specific order */
  lessons?: InputMaybe<Array<Scalars['Int']['input']>>
  name: Scalars['String']['input']
  preSaleBonus?: InputMaybe<Scalars['Float']['input']>
  price?: InputMaybe<Scalars['Float']['input']>
  state?: InputMaybe<PackageStatus>
}

export type PaypalInput = {
  amount?: InputMaybe<Scalars['Float']['input']>
  currency?: InputMaybe<Currency>
  /** ID for identification of related records, like scheduledCall for package payment or coupon for coupon redeem */
  externalId?: InputMaybe<Scalars['String']['input']>
  /** Group id for package purchase using group credit */
  groupId?: InputMaybe<Scalars['String']['input']>
  originalAmount?: InputMaybe<Scalars['Float']['input']>
  promoCode?: InputMaybe<Scalars['String']['input']>
  targetId?: InputMaybe<Scalars['String']['input']>
  targetType?: InputMaybe<TargetType>
  tutorId?: InputMaybe<Scalars['String']['input']>
  /** Use credit from wallets */
  useCredit?: InputMaybe<Scalars['Boolean']['input']>
  vat?: InputMaybe<Scalars['Float']['input']>
  vatCountry?: InputMaybe<CountryType>
  vatPercentage?: InputMaybe<Scalars['Float']['input']>
}

export type CreatePaypalOrderResponse = {
  __typename: 'CreatePaypalOrderResponse'
  orderId: Scalars['String']['output']
}

export type RatingInput = {
  additionalData?: InputMaybe<Scalars['JSON']['input']>
  comment?: InputMaybe<Scalars['String']['input']>
  givingProfileId: Scalars['String']['input']
  ratedProfileId: Scalars['String']['input']
  ratingMood?: InputMaybe<Scalars['Float']['input']>
  ratingSound?: InputMaybe<Scalars['Float']['input']>
  ratingTopics?: InputMaybe<Scalars['Float']['input']>
  ratingTotal: Scalars['Float']['input']
  roomId: Scalars['String']['input']
}

export type RecommendationInput = {
  comment: Scalars['String']['input']
  givingUserId: Scalars['String']['input']
  language: Scalars['String']['input']
  recommendedUserId: Scalars['String']['input']
}

export type ReportInput = {
  messageId?: InputMaybe<Scalars['String']['input']>
  reason: Reason
  reportedUserId: Scalars['String']['input']
  reportingUserId: Scalars['String']['input']
  roomId?: InputMaybe<Scalars['String']['input']>
  supportUserId?: InputMaybe<Scalars['String']['input']>
}

export type ShiftInput = {
  end: Scalars['DateTime']['input']
  id?: InputMaybe<Scalars['String']['input']>
  start: Scalars['DateTime']['input']
  type?: InputMaybe<ShiftType>
}

export type TutorInput = {
  introduction: Scalars['String']['input']
  language: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type UserInput = {
  birthday?: InputMaybe<Scalars['DateTime']['input']>
  countryCode?: InputMaybe<Scalars['String']['input']>
  currency?: InputMaybe<Scalars['String']['input']>
  displayName?: InputMaybe<Scalars['String']['input']>
  firstName?: InputMaybe<Scalars['String']['input']>
  gender?: InputMaybe<Gender>
  image?: InputMaybe<Scalars['String']['input']>
  lastName?: InputMaybe<Scalars['String']['input']>
  middleName?: InputMaybe<Scalars['String']['input']>
  timezone?: InputMaybe<Scalars['String']['input']>
  webLanguage?: InputMaybe<Scalars['String']['input']>
}

export type CreateUserResponse = {
  __typename: 'CreateUserResponse'
  image: Maybe<Scalars['String']['output']>
  token: Scalars['String']['output']
}

export type VoucherInput = {
  bonusAmount?: InputMaybe<Scalars['Float']['input']>
  bonusAmountPercent?: InputMaybe<Scalars['Float']['input']>
  code?: InputMaybe<Scalars['String']['input']>
  creditAmount: Scalars['Float']['input']
  creditExpirationAbsolute?: InputMaybe<Scalars['DateTime']['input']>
  /** Relative expiration in days */
  creditExpirationRelative?: InputMaybe<Scalars['Float']['input']>
  expiration?: InputMaybe<Scalars['DateTime']['input']>
  maxRedemptions?: InputMaybe<Scalars['Float']['input']>
  type?: InputMaybe<VoucherType>
}

export type EventInput = {
  event: AllowedEvents
  payload: Scalars['JSONObject']['input']
  target: Scalars['String']['input']
}

export enum AllowedEvents {
  CallSync = 'call_sync',
  Connecting = 'connecting',
  CountdownSync = 'countdown_sync',
}

export type GalleryBetaInput = {
  amount?: InputMaybe<Scalars['Float']['input']>
  currency?: InputMaybe<Currency>
  customerNumber: Scalars['String']['input']
  customerPIN: Scalars['String']['input']
  /** ID for identification of related records, like scheduledCall for package payment or coupon for coupon redeem */
  externalId?: InputMaybe<Scalars['String']['input']>
  /** Group id for package purchase using group credit */
  groupId?: InputMaybe<Scalars['String']['input']>
  originalAmount?: InputMaybe<Scalars['Float']['input']>
  promoCode?: InputMaybe<Scalars['String']['input']>
  targetId?: InputMaybe<Scalars['String']['input']>
  targetType?: InputMaybe<TargetType>
  tutorId?: InputMaybe<Scalars['String']['input']>
  /** Use credit from wallets */
  useCredit?: InputMaybe<Scalars['Boolean']['input']>
  vat?: InputMaybe<Scalars['Float']['input']>
  vatCountry?: InputMaybe<CountryType>
  vatPercentage?: InputMaybe<Scalars['Float']['input']>
}

/** Type of payment state - Gallery Beta */
export enum GalleryBetaPaymentState {
  Failed = 'FAILED',
  Success = 'SUCCESS',
}

export type GiftInput = {
  amount: Scalars['Float']['input']
  comment?: InputMaybe<Scalars['String']['input']>
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>
  profileId: Scalars['String']['input']
  /**
   * Origin of the transaction, depends on type
   * **redeem_voucher**: redemptionId
   */
  sourceId?: InputMaybe<Scalars['String']['input']>
  userId: Scalars['String']['input']
}

export type SourceInput = {
  fraudCookie?: InputMaybe<Scalars['String']['input']>
  landingPage: Scalars['String']['input']
  referer?: InputMaybe<Scalars['String']['input']>
  /** Unique referralUrl identifying user who referred */
  referralUrl?: InputMaybe<Scalars['String']['input']>
  signUpCampaign?: InputMaybe<Scalars['String']['input']>
  utm?: InputMaybe<Scalars['JSONObject']['input']>
}

export type GoogleAuthResponse = {
  __typename: 'GoogleAuthResponse'
  isLogin: Maybe<Scalars['Boolean']['output']>
  token: Scalars['String']['output']
}

export type BenefitPlusInput = {
  amount?: InputMaybe<Scalars['Float']['input']>
  currency?: InputMaybe<Currency>
  /** ID for identification of related records, like scheduledCall for package payment or coupon for coupon redeem */
  externalId?: InputMaybe<Scalars['String']['input']>
  /** Group id for package purchase using group credit */
  groupId?: InputMaybe<Scalars['String']['input']>
  originalAmount?: InputMaybe<Scalars['Float']['input']>
  promoCode?: InputMaybe<Scalars['String']['input']>
  targetId?: InputMaybe<Scalars['String']['input']>
  targetType?: InputMaybe<TargetType>
  tutorId?: InputMaybe<Scalars['String']['input']>
  /** Use credit from wallets */
  useCredit?: InputMaybe<Scalars['Boolean']['input']>
  vat?: InputMaybe<Scalars['Float']['input']>
  vatCountry?: InputMaybe<CountryType>
  vatPercentage?: InputMaybe<Scalars['Float']['input']>
}

export type BenefitPlusPayload = {
  __typename: 'BenefitPlusPayload'
  amountCZK: Scalars['Float']['output']
  description: Scalars['String']['output']
  eshopCulture: Scalars['String']['output']
  eshopId: Scalars['String']['output']
  orderNumber: Scalars['String']['output']
  partialPayment: Scalars['Float']['output']
  url: Scalars['String']['output']
}

export type MolliePaymentInput = {
  amount?: InputMaybe<Scalars['Float']['input']>
  currency?: InputMaybe<Currency>
  /** ID for identification of related records, like scheduledCall for package payment or coupon for coupon redeem */
  externalId?: InputMaybe<Scalars['String']['input']>
  /** Group id for package purchase using group credit */
  groupId?: InputMaybe<Scalars['String']['input']>
  method: MollieMethod
  originalAmount?: InputMaybe<Scalars['Float']['input']>
  promoCode?: InputMaybe<Scalars['String']['input']>
  redirect?: InputMaybe<Scalars['String']['input']>
  targetId?: InputMaybe<Scalars['String']['input']>
  targetType?: InputMaybe<TargetType>
  tutorId?: InputMaybe<Scalars['String']['input']>
  /** Use credit from wallets */
  useCredit?: InputMaybe<Scalars['Boolean']['input']>
  vat?: InputMaybe<Scalars['Float']['input']>
  vatCountry?: InputMaybe<CountryType>
  vatPercentage?: InputMaybe<Scalars['Float']['input']>
}

/** Mollie payment method */
export enum MollieMethod {
  Applepay = 'applepay',
  Banktransfer = 'banktransfer',
  Creditcard = 'creditcard',
  Przelewy24 = 'przelewy24',
  Sofort = 'sofort',
}

export type InitPayment = {
  __typename: 'InitPayment'
  payment: Maybe<Payment>
  paymentGatewayUrl: Maybe<Scalars['String']['output']>
  paymentId: Maybe<Scalars['String']['output']>
  paymentStatus: Maybe<Scalars['String']['output']>
}

export type CsobInput = {
  amount?: InputMaybe<Scalars['Float']['input']>
  currency?: InputMaybe<Currency>
  /** ID for identification of related records, like scheduledCall for package payment or coupon for coupon redeem */
  externalId?: InputMaybe<Scalars['String']['input']>
  /** Group id for package purchase using group credit */
  groupId?: InputMaybe<Scalars['String']['input']>
  oneClick?: InputMaybe<Scalars['Boolean']['input']>
  originalAmount?: InputMaybe<Scalars['Float']['input']>
  promoCode?: InputMaybe<Scalars['String']['input']>
  redirect?: InputMaybe<Scalars['String']['input']>
  targetId?: InputMaybe<Scalars['String']['input']>
  targetType?: InputMaybe<TargetType>
  tutorId?: InputMaybe<Scalars['String']['input']>
  /** Use credit from wallets */
  useCredit?: InputMaybe<Scalars['Boolean']['input']>
  vat?: InputMaybe<Scalars['Float']['input']>
  vatCountry?: InputMaybe<CountryType>
  vatPercentage?: InputMaybe<Scalars['Float']['input']>
}

export type PayUPaymentInput = {
  amount?: InputMaybe<Scalars['Float']['input']>
  currency?: InputMaybe<Currency>
  /** ID for identification of related records, like scheduledCall for package payment or coupon for coupon redeem */
  externalId?: InputMaybe<Scalars['String']['input']>
  /** Group id for package purchase using group credit */
  groupId?: InputMaybe<Scalars['String']['input']>
  originalAmount?: InputMaybe<Scalars['Float']['input']>
  promoCode?: InputMaybe<Scalars['String']['input']>
  redirect?: InputMaybe<Scalars['String']['input']>
  targetId?: InputMaybe<Scalars['String']['input']>
  targetType?: InputMaybe<TargetType>
  tutorId?: InputMaybe<Scalars['String']['input']>
  /** Use credit from wallets */
  useCredit?: InputMaybe<Scalars['Boolean']['input']>
  vat?: InputMaybe<Scalars['Float']['input']>
  vatCountry?: InputMaybe<CountryType>
  vatPercentage?: InputMaybe<Scalars['Float']['input']>
}

export type SodexoInput = {
  amount?: InputMaybe<Scalars['Float']['input']>
  currency?: InputMaybe<Currency>
  /** ID for identification of related records, like scheduledCall for package payment or coupon for coupon redeem */
  externalId?: InputMaybe<Scalars['String']['input']>
  /** Group id for package purchase using group credit */
  groupId?: InputMaybe<Scalars['String']['input']>
  originalAmount?: InputMaybe<Scalars['Float']['input']>
  promoCode?: InputMaybe<Scalars['String']['input']>
  targetId?: InputMaybe<Scalars['String']['input']>
  targetType?: InputMaybe<TargetType>
  tutorId?: InputMaybe<Scalars['String']['input']>
  /** Use credit from wallets */
  useCredit?: InputMaybe<Scalars['Boolean']['input']>
  vat?: InputMaybe<Scalars['Float']['input']>
  vatCountry?: InputMaybe<CountryType>
  vatPercentage?: InputMaybe<Scalars['Float']['input']>
}

export type WalletOptionsInput = {
  coverage?: InputMaybe<Scalars['Float']['input']>
  maxDebt?: InputMaybe<Scalars['Float']['input']>
  monthlyLimit?: InputMaybe<Scalars['Float']['input']>
}

export type InviteToCallInput = {
  duration?: InputMaybe<Scalars['Int']['input']>
  profileId: Scalars['String']['input']
}

export type Login = {
  __typename: 'Login'
  token: Scalars['String']['output']
  walletBalance: Maybe<Scalars['Float']['output']>
}

export type NotificationInput = {
  type: NotificationType
  variables: Scalars['JSON']['input']
}

export enum NotificationType {
  Call = 'Call',
  CallCancelled = 'CallCancelled',
  CallEnded = 'CallEnded',
  CallForceEnded = 'CallForceEnded',
  CallInit = 'CallInit',
  CallProlonged = 'CallProlonged',
  CallRejected = 'CallRejected',
  CampaignFinished = 'CampaignFinished',
  CampaignStarted = 'CampaignStarted',
  CouponRedemption = 'CouponRedemption',
  InviteBusy = 'InviteBusy',
  InviteExpired = 'InviteExpired',
  LastAssignedTherapist = 'LastAssignedTherapist',
  NoTutorsOnline = 'NoTutorsOnline',
  Payment = 'Payment',
  PaymentFailed = 'PaymentFailed',
  PaymentInit = 'PaymentInit',
  PromoRedemption = 'PromoRedemption',
  Quiz = 'Quiz',
  Rating = 'Rating',
  ReferrerReward = 'ReferrerReward',
  ScheduledCall = 'ScheduledCall',
  ScheduledCallCancelled = 'ScheduledCallCancelled',
  ScheduledCallMissed = 'ScheduledCallMissed',
  ShiftCancelled = 'ShiftCancelled',
  SignUp = 'SignUp',
  SignupPassword = 'SignupPassword',
  SodexoPayment = 'SodexoPayment',
  StartedWithChat = 'StartedWithChat',
  StudentCanceledInvite = 'StudentCanceledInvite',
  TutorMissing = 'TutorMissing',
  TutorOffline = 'TutorOffline',
  TutorOnline = 'TutorOnline',
  TutorProfileEdited = 'TutorProfileEdited',
  VoucherRedemption = 'VoucherRedemption',
}

export type NotifyScheduledCallInput = {
  callEnd: Scalars['DateTime']['input']
  callStart: Scalars['DateTime']['input']
  lessonId?: InputMaybe<Scalars['String']['input']>
  scheduleId: Scalars['String']['input']
  student: ScheduledCallParticipantInput
  tutor: ScheduledCallParticipantInput
}

export type ScheduledCallParticipantInput = {
  profileId: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type PayCallInput = {
  amount: PayCallAmountInput
  commission?: InputMaybe<Scalars['Float']['input']>
  groupId?: InputMaybe<Scalars['String']['input']>
  roomId: Scalars['String']['input']
  studentProfileId: Scalars['String']['input']
  studentUserId: Scalars['String']['input']
  tutorProfileId: Scalars['String']['input']
  tutorUserId: Scalars['String']['input']
}

export type PayCallAmountInput = {
  default: Scalars['Float']['input']
  onHold?: InputMaybe<Scalars['Float']['input']>
}

export type ProlongCallResult = {
  __typename: 'ProlongCallResult'
  room: Maybe<Room>
  status: CantProlongReason
}

export type CouponRedemption = {
  __typename: 'CouponRedemption'
  coupon: Coupon
  id: Scalars['ID']['output']
  redeemedAt: Scalars['DateTime']['output']
  userId: Scalars['String']['output']
}

export type VoucherResult = {
  __typename: 'VoucherResult'
  balance: Maybe<WalletBalance>
  creditValue: Scalars['Float']['output']
}

export type RefundInput = {
  amount: Scalars['Float']['input']
  profileId: Scalars['String']['input']
  userId: Scalars['String']['input']
}

export type NewScheduleCallInput = {
  duration: Scalars['Int']['input']
  lessonId?: InputMaybe<Scalars['String']['input']>
  purchaseId?: InputMaybe<Scalars['String']['input']>
  shiftId: Scalars['String']['input']
  start: Scalars['DateTime']['input']
  tutorId: Scalars['String']['input']
  tutorUserId: Scalars['String']['input']
}

export type MailInput = {
  language?: InputMaybe<Language>
  timezone?: InputMaybe<Scalars['String']['input']>
  to: Scalars['String']['input']
  type: MessageType
  /**
   * **tutorOnlineNotification**: recipient: string, tutorName: string,
   * tutorImage: string, tutorSlug: string, dailyStatus: string
   *   **confirm**: empty
   *   **newTutor**: login: string, password: string
   *   **notes**: recipient: string, partner: string, notes: string
   *   **verify**: token: string
   *   **welcome**: empty
   *   **notifyUnreadMessage**: recipient: string, count: number, senderImage: string, senderName: string
   *   **voucherRedeemed**: recipient: string, amount: number, expiresAt: Date
   *   **creditExpiresSoon**: recipient: string, amount: number, expiresAt: Date
   *   **adminGift**: recipient: string, amount: number, expiresAt: Date
   *   **quizSaved**: quiz: any, tutors: [ Tutor ]
   *   **forgottenPassword**: token: string
   *   **packageActivation**: recipient: string, type: string, packageName: string, lessonId: string, tutors: [ Tutor ]
   */
  variables: Scalars['JSON']['input']
}

export enum Language {
  Cs = 'cs',
  En = 'en',
}

export enum MessageType {
  Confirm = 'Confirm',
  ForgottenPassword = 'ForgottenPassword',
  MeetingBooked = 'MeetingBooked',
  NewTutor = 'NewTutor',
  Notes = 'Notes',
  PackagePurchase = 'PackagePurchase',
  ScheduledCall = 'ScheduledCall',
  ScheduledCallCancelled = 'ScheduledCallCancelled',
  ScheduledCallReminderStudent = 'ScheduledCallReminderStudent',
  ScheduledCallReminderTutor = 'ScheduledCallReminderTutor',
  SignupPassword = 'SignupPassword',
  SlotBooked = 'SlotBooked',
  UnreadMessage = 'UnreadMessage',
  Verify = 'Verify',
}

export type ScheduledCallCancelledInput = {
  cancelledAt: Scalars['DateTime']['input']
  cancelledByUserId: Scalars['String']['input']
  conversationId: Scalars['String']['input']
  reason?: InputMaybe<Scalars['String']['input']>
  toUserId: Scalars['String']['input']
}

export type SystemMessageInput = {
  conversationId: Scalars['String']['input']
  roomId?: InputMaybe<Scalars['String']['input']>
  toUserId: Scalars['String']['input']
  type: SystemMessageType
}

export enum SystemMessageType {
  AbortedCallStudent = 'AbortedCallStudent',
  AbortedCallTutor = 'AbortedCallTutor',
  CallEnded = 'CallEnded',
  StartWithChat = 'StartWithChat',
}

export type ServiceMessageInput = {
  content: Scalars['String']['input']
  fromUserId: Scalars['String']['input']
  roomId?: InputMaybe<Scalars['String']['input']>
  toUserId: Scalars['String']['input']
}

export type StatusInput = {
  notify?: InputMaybe<Scalars['Boolean']['input']>
  status: Status
  text?: InputMaybe<Scalars['String']['input']>
  until?: InputMaybe<Scalars['DateTime']['input']>
  userId?: InputMaybe<Scalars['String']['input']>
}

export type SignUpOutput = {
  __typename: 'SignUpOutput'
  hash: Maybe<Scalars['String']['output']>
  identifier: Maybe<Scalars['String']['output']>
  user: User
}

export type StartCallInput = {
  roomId: Scalars['String']['input']
  twilioSID: Scalars['String']['input']
}

export type StartCallType = {
  __typename: 'StartCallType'
  busy: Busy
  room: Room
  status: CallStatus
}

export enum CallStatus {
  Failed = 'Failed',
  Started = 'Started',
  Succeeded = 'Succeeded',
}

export type PermissionCheck = {
  __typename: 'PermissionCheck'
  createdAt: Scalars['DateTime']['output']
  data: Scalars['JSON']['output']
  id: Scalars['ID']['output']
  profileId: Scalars['String']['output']
  testId: Scalars['String']['output']
  userId: Scalars['String']['output']
}

export type NotificationSubscriptionInput = {
  subscribedToProfileId?: InputMaybe<Scalars['String']['input']>
  type: UserNotificationType
}

export type TransactionRefundInput = {
  groupTxId: Scalars['String']['input']
  /**
   * Origin of the transaction, depends on type
   * **redeem_voucher**: redemptionId
   */
  sourceId?: InputMaybe<Scalars['String']['input']>
  studentProfileId: Scalars['String']['input']
  studentUserId: Scalars['String']['input']
  tutorProfileId: Scalars['String']['input']
  tutorUserId: Scalars['String']['input']
}

export type BillingInput = {
  city: Scalars['String']['input']
  companyId?: InputMaybe<Scalars['String']['input']>
  countryCode: Scalars['String']['input']
  name: Scalars['String']['input']
  state?: InputMaybe<Scalars['String']['input']>
  street: Scalars['String']['input']
  taxId?: InputMaybe<Scalars['String']['input']>
  zip: Scalars['String']['input']
}

/** Type of payment state - PayU */
export enum PayUPaymentState {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Pending = 'PENDING',
}

export type TutorUpdateInput = {
  introduction?: InputMaybe<Scalars['String']['input']>
  topicIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>
}

export type UserActionInput = {
  interactionType: UserInteraction
  userId: Scalars['String']['input']
  variables?: InputMaybe<Scalars['JSONObject']['input']>
}

export enum UserInteraction {
  Call = 'Call',
  Charge = 'Charge',
  OutOfCredit = 'OutOfCredit',
  Promo = 'Promo',
}

export type Subscription = {
  __typename: 'Subscription'
  onCallProlonged: Maybe<Room>
  onConversation: Maybe<Conversation>
  onConversationMessageSent: Maybe<Message>
  onEvent: Maybe<Event>
  onInviteCancelled: Maybe<Invite>
  onInviteConfirmed: Maybe<Invite>
  onInviteRejected: Maybe<Invite>
  onInviteSent: Maybe<Invite>
  onMessageSent: Maybe<Message>
  onRoomEnded: Room
}

export type SubscriptionOnCallProlongedArgs = {
  roomId: Scalars['String']['input']
}

export type SubscriptionOnConversationMessageSentArgs = {
  conversationId: Scalars['String']['input']
}

export type Event = {
  __typename: 'Event'
  event: AllowedEvents
  payload: Scalars['JSONObject']['output']
  sender: Scalars['String']['output']
}

export type BaseInput = {
  amount?: InputMaybe<Scalars['Float']['input']>
  currency?: InputMaybe<Currency>
  /** ID for identification of related records, like scheduledCall for package payment or coupon for coupon redeem */
  externalId?: InputMaybe<Scalars['String']['input']>
  /** Group id for package purchase using group credit */
  groupId?: InputMaybe<Scalars['String']['input']>
  originalAmount?: InputMaybe<Scalars['Float']['input']>
  promoCode?: InputMaybe<Scalars['String']['input']>
  targetId?: InputMaybe<Scalars['String']['input']>
  targetType?: InputMaybe<TargetType>
  tutorId?: InputMaybe<Scalars['String']['input']>
  /** Use credit from wallets */
  useCredit?: InputMaybe<Scalars['Boolean']['input']>
  vat?: InputMaybe<Scalars['Float']['input']>
  vatCountry?: InputMaybe<CountryType>
  vatPercentage?: InputMaybe<Scalars['Float']['input']>
}

export type BenefitPlusPayment = Payment & {
  __typename: 'BenefitPlusPayment'
  amount: Scalars['Float']['output']
  amountExcludedVat: Scalars['Float']['output']
  blabuState: BlabuPaymentStateType
  createdAt: Scalars['DateTime']['output']
  exchangeRate: Maybe<Scalars['Float']['output']>
  externalId: Maybe<Scalars['String']['output']>
  gatewayData: BenefitPlusPaymentData
  groupId: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  invoiceId: Maybe<Scalars['String']['output']>
  originalAmount: Scalars['Float']['output']
  originalCurrency: Currency
  package: Maybe<Package>
  profileId: Scalars['String']['output']
  promoCode: Maybe<Scalars['String']['output']>
  targetId: Maybe<Scalars['String']['output']>
  targetType: Maybe<TargetType>
  tutorId: Maybe<Scalars['String']['output']>
  txId: Scalars['String']['output']
  type: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  /** User wiling to use current credit to partially cover package/voucher purchase */
  useCredit: Maybe<Scalars['Boolean']['output']>
  userId: Scalars['String']['output']
  vat: Maybe<Scalars['Float']['output']>
  vatCountry: Maybe<CountryType>
  vatPercentage: Maybe<Scalars['Float']['output']>
}

export type BenefitPlusPaymentData = {
  __typename: 'BenefitPlusPaymentData'
  resultCode: Maybe<Scalars['Float']['output']>
}

export type BillingContact = {
  __typename: 'BillingContact'
  city: Scalars['String']['output']
  companyId: Maybe<Scalars['String']['output']>
  countryCode: Scalars['String']['output']
  id: Maybe<Scalars['String']['output']>
  name: Scalars['String']['output']
  state: Maybe<Scalars['String']['output']>
  street: Scalars['String']['output']
  taxId: Maybe<Scalars['String']['output']>
  zip: Scalars['String']['output']
}

export type Call = {
  __typename: 'Call'
  start: Maybe<Scalars['Date']['output']>
}

export type CouponPayment = Payment & {
  __typename: 'CouponPayment'
  amount: Scalars['Float']['output']
  amountExcludedVat: Scalars['Float']['output']
  blabuState: BlabuPaymentStateType
  createdAt: Scalars['DateTime']['output']
  exchangeRate: Maybe<Scalars['Float']['output']>
  externalId: Maybe<Scalars['String']['output']>
  groupId: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  invoiceId: Maybe<Scalars['String']['output']>
  originalAmount: Scalars['Float']['output']
  originalCurrency: Currency
  package: Maybe<Package>
  profileId: Scalars['String']['output']
  promoCode: Maybe<Scalars['String']['output']>
  targetId: Maybe<Scalars['String']['output']>
  targetType: Maybe<TargetType>
  tutorId: Maybe<Scalars['String']['output']>
  txId: Scalars['String']['output']
  type: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  /** User wiling to use current credit to partially cover package/voucher purchase */
  useCredit: Maybe<Scalars['Boolean']['output']>
  userId: Scalars['String']['output']
  vat: Maybe<Scalars['Float']['output']>
  vatCountry: Maybe<CountryType>
  vatPercentage: Maybe<Scalars['Float']['output']>
}

export type CouponResult = {
  __typename: 'CouponResult'
  id: Scalars['String']['output']
}

export type Email = Contact & {
  __typename: 'Email'
  createdAt: Scalars['DateTime']['output']
  email: Maybe<Scalars['String']['output']>
  id: Scalars['ID']['output']
  primary: Scalars['Boolean']['output']
  user: User
  verifiedAt: Maybe<Scalars['DateTime']['output']>
}

export type EmailContact = {
  __typename: 'EmailContact'
  email: Scalars['String']['output']
}

export type GalleryBetaGatewayData = {
  __typename: 'GalleryBetaGatewayData'
  code: Scalars['String']['output']
  state: GalleryBetaPaymentState
}

export type GalleryBetaPayment = Payment & {
  __typename: 'GalleryBetaPayment'
  amount: Scalars['Float']['output']
  amountExcludedVat: Scalars['Float']['output']
  blabuState: BlabuPaymentStateType
  createdAt: Scalars['DateTime']['output']
  exchangeRate: Maybe<Scalars['Float']['output']>
  externalId: Maybe<Scalars['String']['output']>
  gatewayData: GalleryBetaGatewayData
  groupId: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  invoiceId: Maybe<Scalars['String']['output']>
  originalAmount: Scalars['Float']['output']
  originalCurrency: Currency
  package: Maybe<Package>
  profileId: Scalars['String']['output']
  promoCode: Maybe<Scalars['String']['output']>
  targetId: Maybe<Scalars['String']['output']>
  targetType: Maybe<TargetType>
  tutorId: Maybe<Scalars['String']['output']>
  txId: Scalars['String']['output']
  type: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  /** User wiling to use current credit to partially cover package/voucher purchase */
  useCredit: Maybe<Scalars['Boolean']['output']>
  userId: Scalars['String']['output']
  vat: Maybe<Scalars['Float']['output']>
  vatCountry: Maybe<CountryType>
  vatPercentage: Maybe<Scalars['Float']['output']>
}

export type MollieGatewayData = {
  __typename: 'MollieGatewayData'
  id: Scalars['String']['output']
  method: MollieMethod
  state: MolliePaymentState
}

/** Type of payment state - Mollie */
export enum MolliePaymentState {
  Canceled = 'CANCELED',
  Expired = 'EXPIRED',
  Failed = 'FAILED',
  Open = 'OPEN',
  Paid = 'PAID',
  Pending = 'PENDING',
}

export type MolliePayment = Payment & {
  __typename: 'MolliePayment'
  amount: Scalars['Float']['output']
  amountExcludedVat: Scalars['Float']['output']
  blabuState: BlabuPaymentStateType
  createdAt: Scalars['DateTime']['output']
  exchangeRate: Maybe<Scalars['Float']['output']>
  externalId: Maybe<Scalars['String']['output']>
  gatewayData: MollieGatewayData
  groupId: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  invoiceId: Maybe<Scalars['String']['output']>
  originalAmount: Scalars['Float']['output']
  originalCurrency: Currency
  package: Maybe<Package>
  profileId: Scalars['String']['output']
  promoCode: Maybe<Scalars['String']['output']>
  targetId: Maybe<Scalars['String']['output']>
  targetType: Maybe<TargetType>
  tutorId: Maybe<Scalars['String']['output']>
  txId: Scalars['String']['output']
  type: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  /** User wiling to use current credit to partially cover package/voucher purchase */
  useCredit: Maybe<Scalars['Boolean']['output']>
  userId: Scalars['String']['output']
  vat: Maybe<Scalars['Float']['output']>
  vatCountry: Maybe<CountryType>
  vatPercentage: Maybe<Scalars['Float']['output']>
}

export type Payout = {
  __typename: 'Payout'
  amount: Scalars['Float']['output']
  comment: Maybe<Scalars['String']['output']>
  createdAt: Scalars['DateTime']['output']
  from: Scalars['DateTime']['output']
  groupId: Scalars['String']['output']
  id: Scalars['ID']['output']
  profileId: Maybe<Scalars['String']['output']>
  to: Scalars['DateTime']['output']
  type: PayoutType
  userId: Maybe<Scalars['String']['output']>
  walletId: Scalars['String']['output']
}

/** Tutor payout type */
export enum PayoutType {
  Manual = 'MANUAL',
}

export type PayUGatewayData = {
  __typename: 'PayUGatewayData'
  orderId: Scalars['String']['output']
  payMethod: Maybe<Scalars['String']['output']>
  refundId: Maybe<Scalars['String']['output']>
  state: PayUPaymentState
}

export type PayUPayment = Payment & {
  __typename: 'PayUPayment'
  amount: Scalars['Float']['output']
  amountExcludedVat: Scalars['Float']['output']
  blabuState: BlabuPaymentStateType
  createdAt: Scalars['DateTime']['output']
  exchangeRate: Maybe<Scalars['Float']['output']>
  externalId: Maybe<Scalars['String']['output']>
  gatewayData: PayUGatewayData
  groupId: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  invoiceId: Maybe<Scalars['String']['output']>
  originalAmount: Scalars['Float']['output']
  originalCurrency: Currency
  package: Maybe<Package>
  profileId: Scalars['String']['output']
  promoCode: Maybe<Scalars['String']['output']>
  targetId: Maybe<Scalars['String']['output']>
  targetType: Maybe<TargetType>
  tutorId: Maybe<Scalars['String']['output']>
  txId: Scalars['String']['output']
  type: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  /** User wiling to use current credit to partially cover package/voucher purchase */
  useCredit: Maybe<Scalars['Boolean']['output']>
  userId: Scalars['String']['output']
  vat: Maybe<Scalars['Float']['output']>
  vatCountry: Maybe<CountryType>
  vatPercentage: Maybe<Scalars['Float']['output']>
}

export type Phone = Contact & {
  __typename: 'Phone'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  phone: Maybe<Scalars['String']['output']>
  primary: Scalars['Boolean']['output']
  user: User
  verifiedAt: Maybe<Scalars['DateTime']['output']>
}

export type PhoneContact = {
  __typename: 'PhoneContact'
  phone: Scalars['String']['output']
}

export type SentNotification = {
  __typename: 'SentNotification'
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  type: UserNotificationType
  userId: Scalars['ID']['output']
}

export type SlevomatVoucherRedemption = {
  __typename: 'SlevomatVoucherRedemption'
  code: Scalars['String']['output']
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  status: Scalars['String']['output']
  userId: Scalars['String']['output']
}

export type SodexoGatewayData = {
  __typename: 'SodexoGatewayData'
  approvalCode: Maybe<Scalars['String']['output']>
  companyId: Maybe<Scalars['String']['output']>
  companyOrderId: Maybe<Scalars['String']['output']>
  orderNumber: Scalars['String']['output']
  state: SodexoPaymentStateType
}

/** Type of sodexo payment state. */
export enum SodexoPaymentStateType {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Confirmed = 'CONFIRMED',
  Created = 'CREATED',
  Declined = 'DECLINED',
}

export type SodexoPayment = Payment & {
  __typename: 'SodexoPayment'
  amount: Scalars['Float']['output']
  amountExcludedVat: Scalars['Float']['output']
  blabuState: BlabuPaymentStateType
  createdAt: Scalars['DateTime']['output']
  exchangeRate: Maybe<Scalars['Float']['output']>
  externalId: Maybe<Scalars['String']['output']>
  gatewayData: SodexoGatewayData
  groupId: Maybe<Scalars['String']['output']>
  id: Scalars['String']['output']
  invoiceId: Maybe<Scalars['String']['output']>
  originalAmount: Scalars['Float']['output']
  originalCurrency: Currency
  package: Maybe<Package>
  profileId: Scalars['String']['output']
  promoCode: Maybe<Scalars['String']['output']>
  targetId: Maybe<Scalars['String']['output']>
  targetType: Maybe<TargetType>
  tutorId: Maybe<Scalars['String']['output']>
  txId: Scalars['String']['output']
  type: Scalars['String']['output']
  updatedAt: Scalars['DateTime']['output']
  /** User wiling to use current credit to partially cover package/voucher purchase */
  useCredit: Maybe<Scalars['Boolean']['output']>
  userId: Scalars['String']['output']
  vat: Maybe<Scalars['Float']['output']>
  vatCountry: Maybe<CountryType>
  vatPercentage: Maybe<Scalars['Float']['output']>
}

export type StudentStats = {
  __typename: 'StudentStats'
  hours30Days: Maybe<Scalars['Float']['output']>
  hours30DaysChange: Maybe<Scalars['Float']['output']>
}

export type TmpBillingInfo = {
  __typename: 'TmpBillingInfo'
  billingInfo: BillingInfo
  createdAt: Scalars['DateTime']['output']
  id: Scalars['ID']['output']
  userId: Scalars['String']['output']
}

export type UserResponse = {
  __typename: 'UserResponse'
  id: Scalars['String']['output']
  identifier: Scalars['String']['output']
  isLogin: Scalars['Boolean']['output']
  profileIds: Array<Scalars['String']['output']>
}

export type SaveNoteMutationVariables = Exact<{
  tutorName: Scalars['String']['input']
  roomId: Scalars['String']['input']
  note: Scalars['String']['input']
  callFinished: Scalars['Boolean']['input']
}>

export type SaveNoteMutation = { saveNote: { __typename: 'Note'; id: string; note: string } | null }

export type GetNotesQueryVariables = Exact<{
  roomId: Scalars['String']['input']
}>

export type GetNotesQuery = { note: { __typename: 'Note'; id: string; note: string } | null }

export type GetParticipantsQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type GetParticipantsQuery = {
  getRoom: {
    __typename: 'Room'
    id: string
    cancelled: any | null
    ended: any | null
    participants: Array<{
      __typename: 'Participant'
      id: string
      profileId: string
      profile:
        | {
            __typename: 'Student'
            id: string
            role: ProfileType
            user: { __typename: 'User'; id: string; firstName: string | null }
          }
        | {
            __typename: 'Tutor'
            id: string
            role: ProfileType
            user: { __typename: 'User'; id: string; firstName: string | null }
          }
        | null
    }> | null
  }
}

export type SetNoteMutationVariables = Exact<{
  tutorName: Scalars['String']['input']
  roomId: Scalars['String']['input']
  note: Scalars['String']['input']
}>

export type SetNoteMutation = { saveNote: { __typename: 'Note'; id: string; note: string } | null }

export type GetNoteQueryVariables = Exact<{
  roomId: Scalars['String']['input']
}>

export type GetNoteQuery = { getNote: { __typename: 'Note'; id: string; note: string } | null }

export type CallChatLoggedUserQueryVariables = Exact<{
  partnerUserId: Scalars['String']['input']
  first?: InputMaybe<Scalars['Int']['input']>
}>

export type CallChatLoggedUserQuery = {
  conversation: {
    __typename: 'Conversation'
    id: string
    expiresAt: any | null
    blockedAt: any | null
    startedAt: any | null
    messages: Array<{
      __typename: 'Message'
      id: string
      fromUserId: string | null
      content: string
      roomId: string | null
      sent: any
      read: any | null
      priority: Priority | null
      attachment:
        | { __typename: 'AttachmentCallEnded'; duration: number }
        | {
            __typename: 'AttachmentScheduledCall'
            expiresAt: any
            duration: number
            hasPassed: boolean
            scheduledCallId: string
            start: any
          }
        | null
      conversation: {
        __typename: 'Conversation'
        id: string
        members: Array<{ __typename: 'ConversationMember'; userId: string }>
        user: { __typename: 'User'; id: string }
      }
    }> | null
    members: Array<{ __typename: 'ConversationMember'; userId: string }>
    user: {
      __typename: 'User'
      id: string
      tutors: Array<{ __typename: 'Tutor'; id: string }> | null
      students: Array<{ __typename: 'Student'; id: string }> | null
    }
  } | null
}

export type CallChatLoggedUserRoomQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type CallChatLoggedUserRoomQuery = {
  room: {
    __typename: 'Room'
    id: string
    started: any | null
    participants: Array<{
      __typename: 'Participant'
      id: string
      profileId: string
      profile:
        | {
            __typename: 'Student'
            id: string
            role: ProfileType
            user: { __typename: 'User'; id: string; firstName: string | null }
          }
        | {
            __typename: 'Tutor'
            id: string
            role: ProfileType
            user: { __typename: 'User'; id: string; firstName: string | null }
          }
        | null
    }> | null
  }
}

export type ChatConversationQueryVariables = Exact<{
  conversationId: Scalars['String']['input']
  first?: InputMaybe<Scalars['Int']['input']>
}>

export type ChatConversationQuery = {
  currentUser: {
    __typename: 'User'
    id: string
    countryCode: string | null
    displayName: string | null
    firstName: string | null
    image: string | null
    tutors: Array<{
      __typename: 'Tutor'
      id: string
      rating: number
      slug: string
      prices: Array<{ __typename: 'TutorPrice'; id: string; price: number; type: TutorPriceType }>
    }> | null
  } | null
  conversation: {
    __typename: 'Conversation'
    id: string
    expiresAt: any | null
    blockedAt: any | null
    startedAt: any | null
    messages: Array<{
      __typename: 'Message'
      id: string
      fromUserId: string | null
      content: string
      roomId: string | null
      sent: any
      read: any | null
      priority: Priority | null
      attachment:
        | { __typename: 'AttachmentCallEnded'; duration: number }
        | {
            __typename: 'AttachmentScheduledCall'
            expiresAt: any
            duration: number
            hasPassed: boolean
            scheduledCallId: string
            start: any
          }
        | null
      conversation: {
        __typename: 'Conversation'
        id: string
        members: Array<{ __typename: 'ConversationMember'; userId: string }>
        user: { __typename: 'User'; id: string }
      }
    }> | null
    user: {
      __typename: 'User'
      id: string
      countryCode: string | null
      displayName: string | null
      firstName: string | null
      image: string | null
      tutors: Array<{
        __typename: 'Tutor'
        id: string
        rating: number
        slug: string
        prices: Array<{ __typename: 'TutorPrice'; id: string; price: number; type: TutorPriceType }>
        busy: { __typename: 'Busy'; isBusy: boolean; until: any | null; flag: BusyFlag | null } | null
      }> | null
      students: Array<{ __typename: 'Student'; id: string }> | null
      currentStatus: { __typename: 'CurrentStatus'; status: Status }
    }
    members: Array<{ __typename: 'ConversationMember'; userId: string }>
  } | null
}

export type ChatTutorsRealStateQueryVariables = Exact<{ [key: string]: never }>

export type ChatTutorsRealStateQuery = {
  getTutorsRealState: Array<{
    __typename: 'Tutor'
    id: string
    user: {
      __typename: 'User'
      id: string
      currentStatus: { __typename: 'CurrentStatus'; status: Status; text: string | null }
    }
    busy: { __typename: 'Busy'; isBusy: boolean; until: any | null; flag: BusyFlag | null } | null
  }>
}

export type ChatConversationHeaderStudentFragment = { __typename: 'Student'; id: string }

export type ChatConversationHeaderTutorFragment = {
  __typename: 'Tutor'
  id: string
  busy: { __typename: 'Busy'; isBusy: boolean; until: any | null; flag: BusyFlag | null } | null
}

export type ChatConversationHeaderUserFragment = {
  __typename: 'User'
  id: string
  firstName: string | null
  displayName: string | null
  image: string | null
  currentStatus: { __typename: 'CurrentStatus'; status: Status }
  students: Array<{ __typename: 'Student'; id: string }> | null
  tutors: Array<{
    __typename: 'Tutor'
    id: string
    busy: { __typename: 'Busy'; isBusy: boolean; until: any | null; flag: BusyFlag | null } | null
  }> | null
}

export type ConversationNumberOfCallsFragment = {
  __typename: 'NumberOfCalls'
  countTogether: number | null
  lastCallTogether: any | null
}

export type ParticipantDetailsQueryVariables = Exact<{
  profileId: Scalars['String']['input']
  participantIsTutor: Scalars['Boolean']['input']
  showBooked: Scalars['Boolean']['input']
  showMissed: Scalars['Boolean']['input']
  showFinished: Scalars['Boolean']['input']
}>

export type ParticipantDetailsQuery = {
  notes: Array<{ __typename: 'Note'; id: string; createdAt: any; note: string; roomId: string }> | null
  profile:
    | {
        __typename: 'Student'
        id: string
        user: {
          __typename: 'User'
          id: string
          firstName: string | null
          displayName: string | null
          image: string | null
        }
      }
    | {
        __typename: 'Tutor'
        id: string
        introduction: string
        slug: string
        user: {
          __typename: 'User'
          id: string
          firstName: string | null
          image: string | null
          displayName: string | null
        }
      }
  packagePurchases: Array<{
    __typename: 'Purchase'
    id: string
    status: PurchaseStatus
    package: {
      __typename: 'Package'
      id: string
      name: string
      lessons: Array<{ __typename: 'Lesson'; id: string; lessonUsage: boolean; units: number }> | null
    }
  }>
  scheduledCalls: Array<{
    __typename: 'ScheduledCall'
    id: string
    start: any
    end: any
    status: string
    lessonId: string | null
    purchaseId: string | null
    participants: Array<{
      __typename: 'ScheduledCallParticipant'
      profileId: string
      profile:
        | { __typename: 'Student'; id: string; user: { __typename: 'User'; id: string } }
        | { __typename: 'Tutor'; id: string; slug: string; user: { __typename: 'User'; id: string } }
        | null
    }>
    rooms: Array<{ __typename: 'Room'; id: string; status: string }> | null
  }>
}

export type ChatParticipantDetailsNoteFragment = {
  __typename: 'Note'
  id: string
  createdAt: any
  note: string
  roomId: string
}

export type ChatParticipantDetailsPurchaseFragment = {
  __typename: 'Purchase'
  id: string
  status: PurchaseStatus
  package: {
    __typename: 'Package'
    id: string
    name: string
    lessons: Array<{ __typename: 'Lesson'; id: string; lessonUsage: boolean; units: number }> | null
  }
}

export type ChatParticipantDetailsPackageFragment = {
  __typename: 'Package'
  id: string
  name: string
  lessons: Array<{ __typename: 'Lesson'; id: string; lessonUsage: boolean; units: number }> | null
}

export type ChatParticipantDetailsStudentFragment = {
  __typename: 'Student'
  id: string
  user: { __typename: 'User'; id: string; firstName: string | null; displayName: string | null; image: string | null }
}

export type ChatParticipantDetailsTutorFragment = {
  __typename: 'Tutor'
  id: string
  introduction: string
  slug: string
  user: { __typename: 'User'; id: string; firstName: string | null; displayName: string | null; image: string | null }
}

export type ChatParticipantDetailsUserFragment = {
  __typename: 'User'
  id: string
  firstName: string | null
  displayName: string | null
  image: string | null
}

export type ChatParticipantDetailsStudentOrTutorScheduledCallFragment = {
  __typename: 'ScheduledCall'
  id: string
  start: any
  end: any
  status: string
  lessonId: string | null
  purchaseId: string | null
  participants: Array<{
    __typename: 'ScheduledCallParticipant'
    profileId: string
    profile:
      | { __typename: 'Student'; id: string; user: { __typename: 'User'; id: string } }
      | { __typename: 'Tutor'; id: string; slug: string; user: { __typename: 'User'; id: string } }
      | null
  }>
  rooms: Array<{ __typename: 'Room'; id: string; status: string }> | null
}

export type ChatParticipantDetailsStudentOrTutorScheduledCallUserFragment = { __typename: 'User'; id: string }

export type GetNotUsedPurchasesQueryVariables = Exact<{ [key: string]: never }>

export type GetNotUsedPurchasesQuery = {
  tutorId: string | null
  getNotUsedPurchases: Array<{ __typename: 'Purchase'; id: string; status: PurchaseStatus }>
}

export type PurchasePackageDialogQueryVariables = Exact<{
  tutorId: Scalars['String']['input']
}>

export type PurchasePackageDialogQuery = {
  packages: Array<{
    __typename: 'Package'
    id: string
    name: string
    price: number
    lessons: Array<{ __typename: 'Lesson'; id: string; units: number }> | null
    tutors: Array<{
      __typename: 'PackageTutor'
      tutorId: string
      tutor: { __typename: 'Tutor'; user: { __typename: 'User'; id: string } } | null
    }> | null
  }>
}

export type PurchasePackageDialogPackageFragment = {
  __typename: 'Package'
  id: string
  name: string
  price: number
  lessons: Array<{ __typename: 'Lesson'; id: string; units: number }> | null
  tutors: Array<{
    __typename: 'PackageTutor'
    tutorId: string
    tutor: { __typename: 'Tutor'; user: { __typename: 'User'; id: string } } | null
  }> | null
}

export type CompanyCoverageQueryVariables = Exact<{
  groupId: Scalars['String']['input']
  userId: Scalars['String']['input']
}>

export type CompanyCoverageQuery = {
  group: { __typename: 'Group'; id: string; name: string; logo: string | null } | null
  groupStudent: {
    __typename: 'GroupStudent'
    id: string
    wallet: {
      __typename: 'Wallet'
      id: string
      options: { __typename: 'WalletOptions'; coverage: number | null }
    } | null
  }
}

export type InfoTitleStudentFragment = {
  __typename: 'Student'
  id: string
  rating: number
  user: {
    __typename: 'User'
    id: string
    countryCode: string | null
    displayName: string | null
    firstName: string | null
    image: string | null
  }
}

export type InfoTitleTutorFragment = {
  __typename: 'Tutor'
  id: string
  rating: number
  user: {
    __typename: 'User'
    id: string
    countryCode: string | null
    displayName: string | null
    firstName: string | null
    image: string | null
  }
}

export type IntercomUserQueryVariables = Exact<{ [key: string]: never }>

export type IntercomUserQuery = {
  user: {
    __typename: 'User'
    id: string
    firstName: string | null
    displayName: string | null
    lastName: string | null
    image: string | null
  } | null
}

export type IntercomQueryVariables = Exact<{
  isLoggedIn: Scalars['Boolean']['input']
  isReferral: Scalars['Boolean']['input']
  referralUrl: Scalars['String']['input']
}>

export type IntercomQuery = { intercomHash: string; referralUser: { __typename: 'User'; id: string } | null }

export type StorePermissionCheckMutationVariables = Exact<{
  data: Scalars['JSON']['input']
  testId: Scalars['String']['input']
}>

export type StorePermissionCheckMutation = { storePermissionCheck: { __typename: 'PermissionCheck'; id: string } }

export type SetStatusMutationVariables = Exact<{
  status: Status
  notify?: InputMaybe<Scalars['Boolean']['input']>
  until?: InputMaybe<Scalars['DateTime']['input']>
  text?: InputMaybe<Scalars['String']['input']>
}>

export type SetStatusMutation = { setStatus: boolean }

export type GetStatusQueryVariables = Exact<{ [key: string]: never }>

export type GetStatusQuery = { getStatus: Status }

export type GetStatusDetailsQueryVariables = Exact<{ [key: string]: never }>

export type GetStatusDetailsQuery = {
  followersCount: number
  user: {
    __typename: 'User'
    id: string
    currentStatus: { __typename: 'CurrentStatus'; text: string | null }
    contacts: Array<
      { __typename: 'Billing'; id: string } | { __typename: 'Email'; id: string } | { __typename: 'Phone'; id: string }
    > | null
  } | null
}

export type TutorAvatarFragment = {
  __typename: 'Tutor'
  id: string
  slug: string
  user: { __typename: 'User'; id: string; firstName: string | null; image: string | null }
}

export type GetUserNameQueryVariables = Exact<{ [key: string]: never }>

export type GetUserNameQuery = {
  user: {
    __typename: 'User'
    id: string
    firstName: string | null
    countryCode: string | null
    verified: boolean
  } | null
}

export type VerifyEmailMutationVariables = Exact<{ [key: string]: never }>

export type VerifyEmailMutation = { verifyEmail: boolean | null }

export type InviteParticipantFragment = {
  __typename: 'Participant'
  id: string
  profile:
    | {
        __typename: 'Student'
        id: string
        rating: number
        user: {
          __typename: 'User'
          id: string
          countryCode: string | null
          displayName: string | null
          firstName: string | null
          image: string | null
        }
      }
    | {
        __typename: 'Tutor'
        id: string
        rating: number
        user: {
          __typename: 'User'
          id: string
          countryCode: string | null
          displayName: string | null
          firstName: string | null
          image: string | null
        }
      }
    | null
}

export type InviteUpdateFragment = {
  __typename: 'Invite'
  id: string | null
  roomId: string
  room: { __typename: 'Room'; id: string; callInviteStatus: CallInviteStatus } | null
}

export type IncomingInvitesConfirmMutationVariables = Exact<{
  invite: InviteInput
}>

export type IncomingInvitesConfirmMutation = {
  confirmedInvite: {
    __typename: 'Invite'
    id: string | null
    roomId: string
    room: { __typename: 'Room'; id: string; callInviteStatus: CallInviteStatus } | null
  }
}

export type IncomingInviteFragment = {
  __typename: 'Invite'
  id: string | null
  roomId: string
  sender: string
  target: string
  room: {
    __typename: 'Room'
    id: string
    callInviteStatus: CallInviteStatus
    createdAt: any
    duration: number
    isScheduled: boolean
    lesson: {
      __typename: 'Lesson'
      id: string
      no: number | null
      package: {
        __typename: 'Package'
        id: string
        name: string
        lessons: Array<{ __typename: 'Lesson'; id: string }> | null
      }
    } | null
    participants: Array<{
      __typename: 'Participant'
      profileId: string
      id: string
      profile:
        | {
            __typename: 'Student'
            id: string
            rating: number
            user: {
              __typename: 'User'
              id: string
              countryCode: string | null
              displayName: string | null
              firstName: string | null
              image: string | null
            }
          }
        | {
            __typename: 'Tutor'
            id: string
            rating: number
            user: {
              __typename: 'User'
              id: string
              countryCode: string | null
              displayName: string | null
              firstName: string | null
              image: string | null
            }
          }
        | null
    }> | null
  } | null
}

export type IncomingInvitesOnCancelledSubscriptionVariables = Exact<{ [key: string]: never }>

export type IncomingInvitesOnCancelledSubscription = {
  cancelledInvite: {
    __typename: 'Invite'
    id: string | null
    roomId: string
    room: { __typename: 'Room'; id: string; callInviteStatus: CallInviteStatus } | null
  } | null
}

export type IncomingInvitesOnSentSubscriptionVariables = Exact<{ [key: string]: never }>

export type IncomingInvitesOnSentSubscription = {
  incomingInvite: {
    __typename: 'Invite'
    id: string | null
    roomId: string
    sender: string
    target: string
    room: {
      __typename: 'Room'
      id: string
      callInviteStatus: CallInviteStatus
      createdAt: any
      duration: number
      isScheduled: boolean
      lesson: {
        __typename: 'Lesson'
        id: string
        no: number | null
        package: {
          __typename: 'Package'
          id: string
          name: string
          lessons: Array<{ __typename: 'Lesson'; id: string }> | null
        }
      } | null
      participants: Array<{
        __typename: 'Participant'
        profileId: string
        id: string
        profile:
          | {
              __typename: 'Student'
              id: string
              rating: number
              user: {
                __typename: 'User'
                id: string
                countryCode: string | null
                displayName: string | null
                firstName: string | null
                image: string | null
              }
            }
          | {
              __typename: 'Tutor'
              id: string
              rating: number
              user: {
                __typename: 'User'
                id: string
                countryCode: string | null
                displayName: string | null
                firstName: string | null
                image: string | null
              }
            }
          | null
      }> | null
    } | null
  } | null
}

export type IncomingInvitesQueryVariables = Exact<{ [key: string]: never }>

export type IncomingInvitesQuery = {
  finishedInvites: Array<{
    __typename: 'Invite'
    id: string | null
    roomId: string
    sender: string
    target: string
    room: {
      __typename: 'Room'
      id: string
      callInviteStatus: CallInviteStatus
      createdAt: any
      duration: number
      isScheduled: boolean
      lesson: {
        __typename: 'Lesson'
        id: string
        no: number | null
        package: {
          __typename: 'Package'
          id: string
          name: string
          lessons: Array<{ __typename: 'Lesson'; id: string }> | null
        }
      } | null
      participants: Array<{
        __typename: 'Participant'
        profileId: string
        id: string
        profile:
          | {
              __typename: 'Student'
              id: string
              rating: number
              user: {
                __typename: 'User'
                id: string
                countryCode: string | null
                displayName: string | null
                firstName: string | null
                image: string | null
              }
            }
          | {
              __typename: 'Tutor'
              id: string
              rating: number
              user: {
                __typename: 'User'
                id: string
                countryCode: string | null
                displayName: string | null
                firstName: string | null
                image: string | null
              }
            }
          | null
      }> | null
    } | null
  }>
  pendingInvites: Array<{
    __typename: 'Invite'
    id: string | null
    roomId: string
    sender: string
    target: string
    room: {
      __typename: 'Room'
      id: string
      callInviteStatus: CallInviteStatus
      createdAt: any
      duration: number
      isScheduled: boolean
      lesson: {
        __typename: 'Lesson'
        id: string
        no: number | null
        package: {
          __typename: 'Package'
          id: string
          name: string
          lessons: Array<{ __typename: 'Lesson'; id: string }> | null
        }
      } | null
      participants: Array<{
        __typename: 'Participant'
        profileId: string
        id: string
        profile:
          | {
              __typename: 'Student'
              id: string
              rating: number
              user: {
                __typename: 'User'
                id: string
                countryCode: string | null
                displayName: string | null
                firstName: string | null
                image: string | null
              }
            }
          | {
              __typename: 'Tutor'
              id: string
              rating: number
              user: {
                __typename: 'User'
                id: string
                countryCode: string | null
                displayName: string | null
                firstName: string | null
                image: string | null
              }
            }
          | null
      }> | null
    } | null
  }>
}

export type IncomingInvitesRejectMutationVariables = Exact<{
  invite: InviteInput
}>

export type IncomingInvitesRejectMutation = {
  rejectedInvite: {
    __typename: 'Invite'
    id: string | null
    roomId: string
    room: { __typename: 'Room'; id: string; callInviteStatus: CallInviteStatus } | null
  }
}

export type ReplyMessageModalCreateMutationVariables = Exact<{
  conversationInput: ConversationInput
}>

export type ReplyMessageModalCreateMutation = { conversation: { __typename: 'Conversation'; id: string } }

export type ReplyMessageModalSendMutationVariables = Exact<{
  message: MessageInput
}>

export type ReplyMessageModalSendMutation = {
  sendMessage: {
    __typename: 'Message'
    id: string
    fromUserId: string | null
    content: string
    roomId: string | null
    sent: any
    read: any | null
    priority: Priority | null
    attachment:
      | { __typename: 'AttachmentCallEnded'; duration: number }
      | {
          __typename: 'AttachmentScheduledCall'
          expiresAt: any
          duration: number
          hasPassed: boolean
          scheduledCallId: string
          start: any
        }
      | null
    conversation: {
      __typename: 'Conversation'
      id: string
      members: Array<{ __typename: 'ConversationMember'; userId: string }>
      user: { __typename: 'User'; id: string }
    }
  } | null
}

export type InviteIdFragment = { __typename: 'Invite'; roomId: string }

export type AllInvitesQueryVariables = Exact<{ [key: string]: never }>

export type AllInvitesQuery = {
  finishedInvites: Array<{ __typename: 'Invite'; roomId: string }>
  pendingInvites: Array<{ __typename: 'Invite'; roomId: string }>
}

export type SentInvitesCancelMutationVariables = Exact<{
  profileId: Scalars['String']['input']
  roomId: Scalars['String']['input']
  expired: Scalars['Boolean']['input']
}>

export type SentInvitesCancelMutation = {
  cancelledInvite: {
    __typename: 'Invite'
    id: string | null
    roomId: string
    room: { __typename: 'Room'; id: string; callInviteStatus: CallInviteStatus } | null
  }
}

export type SentInvitesOnConfirmedSubscriptionVariables = Exact<{ [key: string]: never }>

export type SentInvitesOnConfirmedSubscription = {
  confirmedInvite: {
    __typename: 'Invite'
    id: string | null
    roomId: string
    room: { __typename: 'Room'; id: string; callInviteStatus: CallInviteStatus } | null
  } | null
}

export type SentInvitesOnRejectedSubscriptionVariables = Exact<{ [key: string]: never }>

export type SentInvitesOnRejectedSubscription = {
  rejectedInvite: {
    __typename: 'Invite'
    id: string | null
    roomId: string
    room: { __typename: 'Room'; id: string; callInviteStatus: CallInviteStatus } | null
  } | null
}

export type SentInvitesQueryVariables = Exact<{ [key: string]: never }>

export type SentInvitesQuery = {
  finishedInvites: Array<{
    __typename: 'Invite'
    id: string | null
    roomId: string
    sender: string
    target: string
    room: {
      __typename: 'Room'
      id: string
      callInviteStatus: CallInviteStatus
      createdAt: any
      duration: number
      lesson: { __typename: 'Lesson'; id: string } | null
      participants: Array<{
        __typename: 'Participant'
        profileId: string
        id: string
        profile:
          | {
              __typename: 'Student'
              id: string
              rating: number
              user: {
                __typename: 'User'
                id: string
                countryCode: string | null
                displayName: string | null
                firstName: string | null
                image: string | null
              }
            }
          | {
              __typename: 'Tutor'
              id: string
              rating: number
              user: {
                __typename: 'User'
                id: string
                countryCode: string | null
                displayName: string | null
                firstName: string | null
                image: string | null
              }
            }
          | null
      }> | null
    } | null
  }>
  pendingInvites: Array<{
    __typename: 'Invite'
    id: string | null
    roomId: string
    sender: string
    target: string
    room: {
      __typename: 'Room'
      id: string
      callInviteStatus: CallInviteStatus
      createdAt: any
      duration: number
      lesson: { __typename: 'Lesson'; id: string } | null
      participants: Array<{
        __typename: 'Participant'
        profileId: string
        id: string
        profile:
          | {
              __typename: 'Student'
              id: string
              rating: number
              user: {
                __typename: 'User'
                id: string
                countryCode: string | null
                displayName: string | null
                firstName: string | null
                image: string | null
              }
            }
          | {
              __typename: 'Tutor'
              id: string
              rating: number
              user: {
                __typename: 'User'
                id: string
                countryCode: string | null
                displayName: string | null
                firstName: string | null
                image: string | null
              }
            }
          | null
      }> | null
    } | null
  }>
}

export type SentInvitesSendMutationVariables = Exact<{
  tutorId: Scalars['String']['input']
  duration: Scalars['Int']['input']
  lessonId?: InputMaybe<Scalars['String']['input']>
}>

export type SentInvitesSendMutation = {
  invite: {
    __typename: 'Invite'
    id: string | null
    roomId: string
    sender: string
    target: string
    room: {
      __typename: 'Room'
      id: string
      callInviteStatus: CallInviteStatus
      createdAt: any
      duration: number
      lesson: { __typename: 'Lesson'; id: string } | null
      participants: Array<{
        __typename: 'Participant'
        profileId: string
        id: string
        profile:
          | {
              __typename: 'Student'
              id: string
              rating: number
              user: {
                __typename: 'User'
                id: string
                countryCode: string | null
                displayName: string | null
                firstName: string | null
                image: string | null
              }
            }
          | {
              __typename: 'Tutor'
              id: string
              rating: number
              user: {
                __typename: 'User'
                id: string
                countryCode: string | null
                displayName: string | null
                firstName: string | null
                image: string | null
              }
            }
          | null
      }> | null
    } | null
  }
}

export type SentInviteFragment = {
  __typename: 'Invite'
  id: string | null
  roomId: string
  sender: string
  target: string
  room: {
    __typename: 'Room'
    id: string
    callInviteStatus: CallInviteStatus
    createdAt: any
    duration: number
    lesson: { __typename: 'Lesson'; id: string } | null
    participants: Array<{
      __typename: 'Participant'
      profileId: string
      id: string
      profile:
        | {
            __typename: 'Student'
            id: string
            rating: number
            user: {
              __typename: 'User'
              id: string
              countryCode: string | null
              displayName: string | null
              firstName: string | null
              image: string | null
            }
          }
        | {
            __typename: 'Tutor'
            id: string
            rating: number
            user: {
              __typename: 'User'
              id: string
              countryCode: string | null
              displayName: string | null
              firstName: string | null
              image: string | null
            }
          }
        | null
    }> | null
  } | null
}

export type ChangeTherapistMutationVariables = Exact<{
  tutorId: Scalars['String']['input']
}>

export type ChangeTherapistMutation = { changeTherapist: boolean | null }

export type ShiftDatesQueryVariables = Exact<{
  tutorId: Scalars['String']['input']
}>

export type ShiftDatesQuery = { dates: Array<any> }

export type ShiftDatesAndSlotsQueryVariables = Exact<{
  tutorId: Scalars['String']['input']
  duration: Scalars['Int']['input']
}>

export type ShiftDatesAndSlotsQuery = {
  dates: Array<{
    __typename: 'Slots'
    start: any
    slots: Array<{ __typename: 'Slot'; start: any; shiftId: string } | null> | null
  } | null> | null
}

export type ScheduleCallMutationVariables = Exact<{
  scheduleCallInput: NewScheduleCallInput
}>

export type ScheduleCallMutation = { call: { __typename: 'ScheduledCall'; id: string } }

export type SchedulePackageTutorsQueryVariables = Exact<{
  lessonId: Scalars['String']['input']
}>

export type SchedulePackageTutorsQuery = {
  tutors: Array<{
    __typename: 'Tutor'
    id: string
    slug: string
    user: { __typename: 'User'; id: string; firstName: string | null; image: string | null }
  } | null> | null
}

export type MissingCreditQueryVariables = Exact<{
  tutorId: Scalars['String']['input']
  durations: Array<Scalars['Int']['input']> | Scalars['Int']['input']
  date: Scalars['DateTime']['input']
  groupId?: InputMaybe<Scalars['String']['input']>
}>

export type MissingCreditQuery = {
  creditForCalls: Array<{
    __typename: 'CheckCreditResult'
    missingCredit: number
    duration: number | null
    status: CheckCreditResultStatus
  }>
}

export type TrialCallQueryVariables = Exact<{ [key: string]: never }>

export type TrialCallQuery = { user: { __typename: 'User'; id: string; category: UserCategory } | null }

export type GetAvailableLessonsQueryVariables = Exact<{
  tutorId: Scalars['String']['input']
}>

export type GetAvailableLessonsQuery = {
  userPackages: Array<{
    __typename: 'Purchase'
    id: string
    package: {
      __typename: 'Package'
      id: string
      name: string
      state: PackageStatus
      lessons: Array<{
        __typename: 'Lesson'
        id: string
        no: number | null
        units: number
        lessonUsage: boolean
      }> | null
    }
  }>
  tutor: {
    __typename: 'Tutor'
    id: string
    packages: Array<{
      __typename: 'Package'
      id: string
      name: string
      lessons: Array<{ __typename: 'Lesson'; id: string }> | null
    } | null> | null
  } | null
}

export type UserPackageFragment = {
  __typename: 'Purchase'
  id: string
  package: {
    __typename: 'Package'
    id: string
    name: string
    state: PackageStatus
    lessons: Array<{ __typename: 'Lesson'; id: string; no: number | null; units: number; lessonUsage: boolean }> | null
  }
}

export type LessonOptionQueryVariables = Exact<{
  lessonId: Scalars['String']['input']
}>

export type LessonOptionQuery = {
  lesson: {
    __typename: 'Lesson'
    id: string
    units: number
    no: number | null
    package: {
      __typename: 'Package'
      id: string
      name: string
      lessons: Array<{ __typename: 'Lesson'; id: string }> | null
    }
  }
}

export type CallRateQueryVariables = Exact<{
  tutorId: Scalars['String']['input']
}>

export type CallRateQuery = {
  tutor: {
    __typename: 'Tutor'
    id: string
    prices: Array<{ __typename: 'TutorPrice'; id: string; type: TutorPriceType; price: number }>
  } | null
}

export type GetActiveSubscriptionsQueryVariables = Exact<{
  type: UserNotificationType
}>

export type GetActiveSubscriptionsQuery = {
  getActiveSubscriptions: Array<{ __typename: 'Subscriber'; subscribedToProfileId: string | null }>
}

export type ScheduledCallFragment = {
  __typename: 'ScheduledCall'
  id: string
  lessonId: string | null
  end: any
  start: any
  participants: Array<{ __typename: 'ScheduledCallParticipant'; id: string; profileId: string }>
}

export type TutorModalSentInvitesQueryVariables = Exact<{
  isStudent: Scalars['Boolean']['input']
}>

export type TutorModalSentInvitesQuery = {
  finishedInvites: Array<{ __typename: 'Invite'; id: string | null }>
  pendingInvites: Array<{ __typename: 'Invite'; id: string | null }>
}

export type TutorModalDetailQueryVariables = Exact<{
  slug: Scalars['String']['input']
  isScheduledCall: Scalars['Boolean']['input']
  scheduledCallId: Scalars['String']['input']
}>

export type TutorModalDetailQuery = {
  tutor: {
    __typename: 'Tutor'
    rating: number
    id: string
    slug: string
    prices: Array<{ __typename: 'TutorPrice'; id: string; type: TutorPriceType; price: number }>
    shifts: Array<{ __typename: 'Shift'; start: any; end: any }>
    user: {
      __typename: 'User'
      id: string
      firstName: string | null
      lastName: string | null
      displayName: string | null
      countryCode: string | null
      image: string | null
      currentStatus: { __typename: 'CurrentStatus'; status: Status; text: string | null }
    }
    intros: Array<{ __typename: 'TutorIntro'; language: string; introduction: string; motto: string | null }>
    topics: Array<{ __typename: 'Topic'; name: string }>
    packages: Array<{
      __typename: 'Package'
      id: string
      name: string
      lessons: Array<{ __typename: 'Lesson'; id: string }> | null
    } | null> | null
  }
  scheduledCall: {
    __typename: 'ScheduledCall'
    id: string
    lessonId: string | null
    end: any
    start: any
    participants: Array<{ __typename: 'ScheduledCallParticipant'; id: string; profileId: string }>
  }
}

export type GetPackageLessonQueryVariables = Exact<{ [key: string]: never }>

export type GetPackageLessonQuery = {
  userPackages: Array<{
    __typename: 'Purchase'
    id: string
    package: {
      __typename: 'Package'
      id: string
      name: string
      state: PackageStatus
      lessons: Array<{
        __typename: 'Lesson'
        id: string
        no: number | null
        units: number
        lessonUsage: boolean
      }> | null
    }
  }>
}

export type TutorModalSendInviteMutationVariables = Exact<{
  tutorId: Scalars['String']['input']
  duration: Scalars['Int']['input']
  lessonId?: InputMaybe<Scalars['String']['input']>
}>

export type TutorModalSendInviteMutation = {
  invite: {
    __typename: 'Invite'
    id: string | null
    roomId: string
    sender: string
    target: string
    room: {
      __typename: 'Room'
      id: string
      callInviteStatus: CallInviteStatus
      createdAt: any
      duration: number
      lesson: { __typename: 'Lesson'; id: string } | null
      participants: Array<{
        __typename: 'Participant'
        profileId: string
        id: string
        profile:
          | {
              __typename: 'Student'
              id: string
              rating: number
              user: {
                __typename: 'User'
                id: string
                countryCode: string | null
                displayName: string | null
                firstName: string | null
                image: string | null
              }
            }
          | {
              __typename: 'Tutor'
              id: string
              rating: number
              user: {
                __typename: 'User'
                id: string
                countryCode: string | null
                displayName: string | null
                firstName: string | null
                image: string | null
              }
            }
          | null
      }> | null
    } | null
  }
}

export type TutorModalSendScheduledInviteMutationVariables = Exact<{
  scheduledCallId: Scalars['String']['input']
}>

export type TutorModalSendScheduledInviteMutation = {
  invite: {
    __typename: 'Invite'
    id: string | null
    roomId: string
    sender: string
    target: string
    room: {
      __typename: 'Room'
      id: string
      callInviteStatus: CallInviteStatus
      createdAt: any
      duration: number
      lesson: { __typename: 'Lesson'; id: string } | null
      participants: Array<{
        __typename: 'Participant'
        profileId: string
        id: string
        profile:
          | {
              __typename: 'Student'
              id: string
              rating: number
              user: {
                __typename: 'User'
                id: string
                countryCode: string | null
                displayName: string | null
                firstName: string | null
                image: string | null
              }
            }
          | {
              __typename: 'Tutor'
              id: string
              rating: number
              user: {
                __typename: 'User'
                id: string
                countryCode: string | null
                displayName: string | null
                firstName: string | null
                image: string | null
              }
            }
          | null
      }> | null
    } | null
  }
}

export type TutorModalPreviewByIdQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type TutorModalPreviewByIdQuery = {
  tutor: {
    __typename: 'Tutor'
    id: string
    slug: string
    user: {
      __typename: 'User'
      id: string
      firstName: string | null
      lastName: string | null
      displayName: string | null
      countryCode: string | null
      image: string | null
      currentStatus: { __typename: 'CurrentStatus'; status: Status; text: string | null }
    }
    intros: Array<{ __typename: 'TutorIntro'; language: string; introduction: string; motto: string | null }>
    topics: Array<{ __typename: 'Topic'; name: string }>
  } | null
}

export type TutorModalPreviewBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input']
}>

export type TutorModalPreviewBySlugQuery = {
  tutor: {
    __typename: 'Tutor'
    id: string
    slug: string
    user: {
      __typename: 'User'
      id: string
      firstName: string | null
      lastName: string | null
      displayName: string | null
      countryCode: string | null
      image: string | null
      currentStatus: { __typename: 'CurrentStatus'; status: Status; text: string | null }
    }
    intros: Array<{ __typename: 'TutorIntro'; language: string; introduction: string; motto: string | null }>
    topics: Array<{ __typename: 'Topic'; name: string }>
  }
}

export type TutorFragment = {
  __typename: 'Tutor'
  id: string
  rating: number
  slug: string
  user: {
    __typename: 'User'
    id: string
    firstName: string | null
    displayName: string | null
    countryCode: string | null
    image: string | null
    role: UserRole
    currentStatus: { __typename: 'CurrentStatus'; status: Status; text: string | null }
  }
  intros: Array<{ __typename: 'TutorIntro'; language: string; introduction: string; motto: string | null }>
  topics: Array<{ __typename: 'Topic'; name: string }>
  prices: Array<{ __typename: 'TutorPrice'; id: string; type: TutorPriceType; price: number }>
  busy: { __typename: 'Busy'; isBusy: boolean; until: any | null; flag: BusyFlag | null } | null
  availability: { __typename: 'Availability'; start: any; end: any } | null
}

export type GetFutureTutorsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
}>

export type GetFutureTutorsQuery = {
  getFutureTutors: Array<{
    __typename: 'Tutor'
    id: string
    rating: number
    slug: string
    user: {
      __typename: 'User'
      id: string
      firstName: string | null
      displayName: string | null
      countryCode: string | null
      image: string | null
      role: UserRole
      currentStatus: { __typename: 'CurrentStatus'; status: Status; text: string | null }
    }
    intros: Array<{ __typename: 'TutorIntro'; language: string; introduction: string; motto: string | null }>
    topics: Array<{ __typename: 'Topic'; name: string }>
    prices: Array<{ __typename: 'TutorPrice'; id: string; type: TutorPriceType; price: number }>
    busy: { __typename: 'Busy'; isBusy: boolean; until: any | null; flag: BusyFlag | null } | null
    availability: { __typename: 'Availability'; start: any; end: any } | null
  }>
}

export type GetOnlineTutorsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
}>

export type GetOnlineTutorsQuery = {
  getOnlineTutors: Array<{
    __typename: 'Tutor'
    id: string
    rating: number
    slug: string
    user: {
      __typename: 'User'
      id: string
      firstName: string | null
      displayName: string | null
      countryCode: string | null
      image: string | null
      role: UserRole
      currentStatus: { __typename: 'CurrentStatus'; status: Status; text: string | null }
    }
    intros: Array<{ __typename: 'TutorIntro'; language: string; introduction: string; motto: string | null }>
    topics: Array<{ __typename: 'Topic'; name: string }>
    prices: Array<{ __typename: 'TutorPrice'; id: string; type: TutorPriceType; price: number }>
    busy: { __typename: 'Busy'; isBusy: boolean; until: any | null; flag: BusyFlag | null } | null
    availability: { __typename: 'Availability'; start: any; end: any } | null
  } | null> | null
}

export type TutorCallChatLoggedUserQueryVariables = Exact<{
  partnerUserId: Scalars['String']['input']
  first?: InputMaybe<Scalars['Int']['input']>
}>

export type TutorCallChatLoggedUserQuery = {
  conversation: {
    __typename: 'Conversation'
    id: string
    expiresAt: any | null
    blockedAt: any | null
    startedAt: any | null
    messages: Array<{
      __typename: 'Message'
      id: string
      fromUserId: string | null
      content: string
      roomId: string | null
      sent: any
      read: any | null
      priority: Priority | null
      attachment:
        | { __typename: 'AttachmentCallEnded'; duration: number }
        | {
            __typename: 'AttachmentScheduledCall'
            expiresAt: any
            duration: number
            hasPassed: boolean
            scheduledCallId: string
            start: any
          }
        | null
      conversation: {
        __typename: 'Conversation'
        id: string
        members: Array<{ __typename: 'ConversationMember'; userId: string }>
        user: { __typename: 'User'; id: string }
      }
    }> | null
    members: Array<{ __typename: 'ConversationMember'; userId: string }>
    user: {
      __typename: 'User'
      id: string
      tutors: Array<{ __typename: 'Tutor'; id: string }> | null
      students: Array<{ __typename: 'Student'; id: string }> | null
    }
  } | null
}

export type TutorCallChatLoggedUserRoomQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type TutorCallChatLoggedUserRoomQuery = {
  room: {
    __typename: 'Room'
    id: string
    started: any | null
    participants: Array<{
      __typename: 'Participant'
      id: string
      profileId: string
      profile:
        | {
            __typename: 'Student'
            id: string
            role: ProfileType
            user: { __typename: 'User'; id: string; firstName: string | null }
          }
        | {
            __typename: 'Tutor'
            id: string
            role: ProfileType
            user: { __typename: 'User'; id: string; firstName: string | null }
          }
        | null
    }> | null
  }
}

export type TutorChatConversationQueryVariables = Exact<{
  conversationId: Scalars['String']['input']
  first?: InputMaybe<Scalars['Int']['input']>
}>

export type TutorChatConversationQuery = {
  conversation: {
    __typename: 'Conversation'
    id: string
    expiresAt: any | null
    blockedAt: any | null
    startedAt: any | null
    messages: Array<{
      __typename: 'Message'
      id: string
      fromUserId: string | null
      content: string
      roomId: string | null
      sent: any
      read: any | null
      priority: Priority | null
      attachment:
        | { __typename: 'AttachmentCallEnded'; duration: number }
        | {
            __typename: 'AttachmentScheduledCall'
            expiresAt: any
            duration: number
            hasPassed: boolean
            scheduledCallId: string
            start: any
          }
        | null
      conversation: {
        __typename: 'Conversation'
        id: string
        members: Array<{ __typename: 'ConversationMember'; userId: string }>
        user: { __typename: 'User'; id: string }
      }
    }> | null
    user: {
      __typename: 'User'
      id: string
      countryCode: string | null
      displayName: string | null
      firstName: string | null
      image: string | null
      tutors: Array<{
        __typename: 'Tutor'
        id: string
        rating: number
        slug: string
        prices: Array<{ __typename: 'TutorPrice'; id: string; price: number; type: TutorPriceType }>
        busy: { __typename: 'Busy'; isBusy: boolean; until: any | null; flag: BusyFlag | null } | null
      }> | null
      students: Array<{ __typename: 'Student'; id: string; rating: number }> | null
      currentStatus: { __typename: 'CurrentStatus'; status: Status }
    }
    members: Array<{ __typename: 'ConversationMember'; userId: string }>
  } | null
}

export type TutorchatTutorsRealStateQueryVariables = Exact<{ [key: string]: never }>

export type TutorchatTutorsRealStateQuery = {
  getTutorsRealState: Array<{
    __typename: 'Tutor'
    id: string
    user: {
      __typename: 'User'
      id: string
      currentStatus: { __typename: 'CurrentStatus'; status: Status; text: string | null }
    }
    busy: { __typename: 'Busy'; isBusy: boolean; until: any | null; flag: BusyFlag | null } | null
  }>
}

export type TutorblockConversationMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type TutorblockConversationMutation = {
  blockConversation: {
    __typename: 'Conversation'
    id: string
    createdAt: any
    expiresAt: any | null
    blockedAt: any | null
  }
}

export type TutorConversationHeaderQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type TutorConversationHeaderQuery = {
  numberOfCalls: { __typename: 'NumberOfCalls'; countTogether: number | null; lastCallTogether: any | null }
}

export type TutorChatConversationHeaderStudentFragment = { __typename: 'Student'; id: string; rating: number }

export type TutorChatConversationHeaderTutorFragment = {
  __typename: 'Tutor'
  id: string
  rating: number
  slug: string
  busy: { __typename: 'Busy'; isBusy: boolean; until: any | null; flag: BusyFlag | null } | null
}

export type TutorChatConversationHeaderUserFragment = {
  __typename: 'User'
  id: string
  firstName: string | null
  displayName: string | null
  image: string | null
  currentStatus: { __typename: 'CurrentStatus'; status: Status }
  students: Array<{ __typename: 'Student'; id: string; rating: number }> | null
  tutors: Array<{
    __typename: 'Tutor'
    id: string
    rating: number
    slug: string
    busy: { __typename: 'Busy'; isBusy: boolean; until: any | null; flag: BusyFlag | null } | null
  }> | null
}

export type TutorParticipantDetailsQueryVariables = Exact<{
  profileId: Scalars['String']['input']
  participantIsTutor: Scalars['Boolean']['input']
}>

export type TutorParticipantDetailsQuery = {
  callHistory: Array<{
    __typename: 'Room'
    id: string
    start: any | null
    started: any | null
    priceCharged?: number | null
    tutorRevenue?: number | null
    actualDuration: string | null
    rating: { __typename: 'Rating'; id: string; ratingTotal: number } | null
  }>
  notes: Array<{ __typename: 'Note'; id: string; createdAt: any; note: string; roomId: string }> | null
  quizes: Array<{ __typename: 'Quiz'; id: string; quiz: any }>
  profile:
    | {
        __typename: 'Student'
        id: string
        user: {
          __typename: 'User'
          id: string
          countryCode: string | null
          firstName: string | null
          displayName: string | null
          image: string | null
        }
      }
    | {
        __typename: 'Tutor'
        id: string
        introduction: string
        slug: string
        user: {
          __typename: 'User'
          id: string
          countryCode: string | null
          firstName: string | null
          displayName: string | null
          image: string | null
        }
      }
  purchasedPackages: Array<{
    __typename: 'Purchase'
    id: string
    createdAt: any
    package: {
      __typename: 'Package'
      id: string
      name: string
      lessons: Array<{ __typename: 'Lesson'; id: string; units: number; lessonUsage: boolean }> | null
    }
  }>
}

export type TutorChatParticipantDetailsNoteFragment = {
  __typename: 'Note'
  id: string
  createdAt: any
  note: string
  roomId: string
}

export type TutorChatParticipantDetailsPackageFragment = {
  __typename: 'Package'
  id: string
  name: string
  lessons: Array<{ __typename: 'Lesson'; id: string; units: number; lessonUsage: boolean }> | null
}

export type TutorChatParticipantDetailsRoomFragment = {
  __typename: 'Room'
  id: string
  start: any | null
  started: any | null
  priceCharged?: number | null
  tutorRevenue?: number | null
  actualDuration: string | null
  rating: { __typename: 'Rating'; id: string; ratingTotal: number } | null
}

export type TutorChatParticipantDetailsStudentFragment = {
  __typename: 'Student'
  id: string
  user: {
    __typename: 'User'
    id: string
    countryCode: string | null
    firstName: string | null
    displayName: string | null
    image: string | null
  }
}

export type TutorChatParticipantDetailsTutorFragment = {
  __typename: 'Tutor'
  id: string
  introduction: string
  slug: string
  user: {
    __typename: 'User'
    id: string
    countryCode: string | null
    firstName: string | null
    displayName: string | null
    image: string | null
  }
}

export type TutorChatParticipantDetailsUserFragment = {
  __typename: 'User'
  id: string
  countryCode: string | null
  firstName: string | null
  displayName: string | null
  image: string | null
}

export type BillingFragment = {
  __typename: 'Billing'
  id: string
  name: string
  street: string
  city: string
  state: string | null
  companyId: string | null
  taxId: string | null
  countryCode: string
  zip: string
}

export type BillingInfoFormUpdateMutationVariables = Exact<{
  billing: BillingInput
}>

export type BillingInfoFormUpdateMutation = {
  billing: {
    __typename: 'Billing'
    id: string
    name: string
    street: string
    city: string
    state: string | null
    companyId: string | null
    taxId: string | null
    countryCode: string
    zip: string
  }
}

export type BillingInfoFormQueryVariables = Exact<{ [key: string]: never }>

export type BillingInfoFormQuery = {
  user: {
    __typename: 'User'
    id: string
    firstName: string | null
    lastName: string | null
    countryCode: string | null
    contacts: Array<
      | {
          __typename: 'Billing'
          id: string
          name: string
          street: string
          city: string
          state: string | null
          companyId: string | null
          taxId: string | null
          countryCode: string
          zip: string
        }
      | { __typename: 'Email'; id: string }
      | { __typename: 'Phone'; id: string }
    > | null
  } | null
}

export type UserProfileFormQueryVariables = Exact<{
  isTutor: Scalars['Boolean']['input']
  tutorId: Scalars['String']['input']
}>

export type UserProfileFormQuery = {
  user: {
    __typename: 'User'
    id: string
    firstName: string | null
    lastName: string | null
    countryCode: string | null
    image: string | null
  } | null
  topics: Array<{ __typename: 'Topic'; id: string; name: string }>
  tutor: {
    __typename: 'Tutor'
    id: string
    introduction: string
    topics: Array<{ __typename: 'Topic'; id: string; name: string }>
  } | null
}

export type UserProfileFormUpdateMutationVariables = Exact<{
  user: UserInput
  image?: InputMaybe<Scalars['Upload']['input']>
  tutor?: InputMaybe<TutorUpdateInput>
}>

export type UserProfileFormUpdateMutation = {
  user: {
    __typename: 'User'
    id: string
    firstName: string | null
    lastName: string | null
    countryCode: string | null
    image: string | null
  } | null
}

export type UserProfileFormUserFragment = {
  __typename: 'User'
  id: string
  firstName: string | null
  lastName: string | null
  countryCode: string | null
  image: string | null
}

export type AttachmentCallEndedFragment = { __typename: 'AttachmentCallEnded'; duration: number }

export type AttachmentScheduledCallFragment = {
  __typename: 'AttachmentScheduledCall'
  expiresAt: any
  duration: number
  hasPassed: boolean
  scheduledCallId: string
  start: any
}

export type ConversationFragment = {
  __typename: 'Conversation'
  id: string
  expiresAt: any | null
  blockedAt: any | null
  startedAt: any | null
  members: Array<{ __typename: 'ConversationMember'; userId: string }>
  user: {
    __typename: 'User'
    id: string
    tutors: Array<{ __typename: 'Tutor'; id: string }> | null
    students: Array<{ __typename: 'Student'; id: string }> | null
  }
}

export type MessageFragment = {
  __typename: 'Message'
  id: string
  fromUserId: string | null
  content: string
  roomId: string | null
  sent: any
  read: any | null
  priority: Priority | null
  attachment:
    | { __typename: 'AttachmentCallEnded'; duration: number }
    | {
        __typename: 'AttachmentScheduledCall'
        expiresAt: any
        duration: number
        hasPassed: boolean
        scheduledCallId: string
        start: any
      }
    | null
  conversation: {
    __typename: 'Conversation'
    id: string
    members: Array<{ __typename: 'ConversationMember'; userId: string }>
    user: { __typename: 'User'; id: string }
  }
}

export type MessageUserFragment = {
  __typename: 'User'
  id: string
  countryCode: string | null
  displayName: string | null
  firstName: string | null
  image: string | null
  tutors: Array<{
    __typename: 'Tutor'
    id: string
    rating: number
    slug: string
    prices: Array<{ __typename: 'TutorPrice'; id: string; price: number; type: TutorPriceType }>
  }> | null
}

export type MessageWithConversationFragment = {
  __typename: 'Message'
  conversation: {
    __typename: 'Conversation'
    id: string
    user: { __typename: 'User'; id: string; displayName: string | null; firstName: string | null; image: string | null }
  }
}

export type TutorDetailFragment = {
  __typename: 'Tutor'
  rating: number
  id: string
  slug: string
  prices: Array<{ __typename: 'TutorPrice'; id: string; type: TutorPriceType; price: number }>
  shifts: Array<{ __typename: 'Shift'; start: any; end: any }>
  user: {
    __typename: 'User'
    id: string
    firstName: string | null
    lastName: string | null
    displayName: string | null
    countryCode: string | null
    image: string | null
    currentStatus: { __typename: 'CurrentStatus'; status: Status; text: string | null }
  }
  intros: Array<{ __typename: 'TutorIntro'; language: string; introduction: string; motto: string | null }>
  topics: Array<{ __typename: 'Topic'; name: string }>
  packages: Array<{
    __typename: 'Package'
    id: string
    name: string
    lessons: Array<{ __typename: 'Lesson'; id: string }> | null
  } | null> | null
}

export type TutorItemFragment = {
  __typename: 'Tutor'
  id: string
  slug: string
  user: {
    __typename: 'User'
    id: string
    firstName: string | null
    lastName: string | null
    displayName: string | null
    countryCode: string | null
    image: string | null
    currentStatus: { __typename: 'CurrentStatus'; status: Status; text: string | null }
  }
  intros: Array<{ __typename: 'TutorIntro'; language: string; introduction: string; motto: string | null }>
  topics: Array<{ __typename: 'Topic'; name: string }>
}

export type TutorPackagesFragment = {
  __typename: 'Tutor'
  packages: Array<{
    __typename: 'Package'
    id: string
    name: string
    lessons: Array<{ __typename: 'Lesson'; id: string }> | null
  } | null> | null
}

export type TutorStateFragment = {
  __typename: 'Tutor'
  id: string
  user: {
    __typename: 'User'
    id: string
    currentStatus: { __typename: 'CurrentStatus'; status: Status; text: string | null }
  }
  busy: { __typename: 'Busy'; isBusy: boolean; until: any | null; flag: BusyFlag | null } | null
}

export type ContactListConversationFragment = {
  __typename: 'Conversation'
  id: string
  createdAt: any
  expiresAt: any | null
  lastCallAt: any | null
  blockedAt: any | null
  lastMessage: {
    __typename: 'Message'
    id: string
    fromUserId: string | null
    content: string
    roomId: string | null
    sent: any
    read: any | null
  } | null
  user: {
    __typename: 'User'
    id: string
    firstName: string | null
    displayName: string | null
    image: string | null
    countryCode: string | null
    currentStatus: { __typename: 'CurrentStatus'; status: Status }
    tutors: Array<{
      __typename: 'Tutor'
      id: string
      slug: string
      rating: number
      prices: Array<{ __typename: 'TutorPrice'; id: string; type: TutorPriceType; price: number }>
    }> | null
    students: Array<{ __typename: 'Student'; id: string }> | null
  }
}

export type ContactListUserFragment = {
  __typename: 'User'
  id: string
  firstName: string | null
  displayName: string | null
  image: string | null
  countryCode: string | null
  currentStatus: { __typename: 'CurrentStatus'; status: Status }
  tutors: Array<{
    __typename: 'Tutor'
    id: string
    slug: string
    rating: number
    prices: Array<{ __typename: 'TutorPrice'; id: string; type: TutorPriceType; price: number }>
  }> | null
  students: Array<{ __typename: 'Student'; id: string }> | null
}

export type NumberOfCallsFragment = {
  __typename: 'NumberOfCalls'
  count: number | null
  countTogether: number | null
  lastCallTogether: any | null
}

export type ConfirmSodexoPaymentMutationVariables = Exact<{
  orderNumber: Scalars['String']['input']
}>

export type ConfirmSodexoPaymentMutation = { confirmSodexoPayment: boolean }

export type CreateConversationMutationVariables = Exact<{
  conversationInput: ConversationInput
}>

export type CreateConversationMutation = { createConversation: { __typename: 'Conversation'; id: string } }

export type CreatePasswordAndVerifyMutationVariables = Exact<{
  password: Scalars['String']['input']
  hash: Scalars['String']['input']
}>

export type CreatePasswordAndVerifyMutation = { updateHashResult: { __typename: 'Token'; token: string } | null }

export type GoogleLoginMutationVariables = Exact<{
  code: Scalars['String']['input']
}>

export type GoogleLoginMutation = {
  googleLogin: { __typename: 'GoogleAuthResponse'; token: string; isLogin: boolean | null } | null
}

export type GoogleSignUpMutationVariables = Exact<{
  code: Scalars['String']['input']
  language?: InputMaybe<WebLanguage>
  timezone?: InputMaybe<Scalars['String']['input']>
  sourceInput?: InputMaybe<SourceInput>
}>

export type GoogleSignUpMutation = {
  googleSignUp: { __typename: 'GoogleAuthResponse'; token: string; isLogin: boolean | null } | null
}

export type InitPaymentMutationVariables = Exact<{
  paymentInput: CsobInput
}>

export type InitPaymentMutation = {
  initPayment: {
    __typename: 'InitPayment'
    paymentGatewayUrl: string | null
    paymentId: string | null
    paymentStatus: string | null
  }
}

export type LoginMutationVariables = Exact<{
  identifier: Scalars['String']['input']
  password: Scalars['String']['input']
}>

export type LoginMutation = { login: { __typename: 'Login'; token: string } | null }

export type LogoutMutationVariables = Exact<{ [key: string]: never }>

export type LogoutMutation = { logout: boolean | null }

export type RedeemMutationVariables = Exact<{
  code: Scalars['String']['input']
}>

export type RedeemMutation = { redeem: { __typename: 'CouponRedemption'; id: string } }

export type RedeemVoucherMutationVariables = Exact<{
  voucherCode: Scalars['String']['input']
}>

export type RedeemVoucherMutation = {
  redeemVoucher: {
    __typename: 'VoucherResult'
    creditValue: number
    balance: { __typename: 'WalletBalance'; id: string; balance: number } | null
  }
}

export type ResendSignUpMailMutationVariables = Exact<{
  identifier: Scalars['String']['input']
  coupon?: InputMaybe<Scalars['String']['input']>
}>

export type ResendSignUpMailMutation = { resendSignUpMail: boolean | null }

export type SendMessageMutationVariables = Exact<{
  message: MessageInput
}>

export type SendMessageMutation = {
  sendMessage: {
    __typename: 'Message'
    id: string
    fromUserId: string | null
    content: string
    roomId: string | null
    sent: any
    read: any | null
    priority: Priority | null
    attachment:
      | { __typename: 'AttachmentCallEnded'; duration: number }
      | {
          __typename: 'AttachmentScheduledCall'
          expiresAt: any
          duration: number
          hasPassed: boolean
          scheduledCallId: string
          start: any
        }
      | null
    conversation: {
      __typename: 'Conversation'
      id: string
      members: Array<{ __typename: 'ConversationMember'; userId: string }>
      user: { __typename: 'User'; id: string }
    }
  } | null
}

export type SetMessageReadMutationVariables = Exact<{
  messageId: Scalars['String']['input']
}>

export type SetMessageReadMutation = { setMessageRead: boolean }

export type SetWebLanguageMutationVariables = Exact<{
  webLanguage: WebLanguage
}>

export type SetWebLanguageMutation = { setWebLanguage: boolean }

export type SignUpMutationVariables = Exact<{
  identifier: Scalars['String']['input']
  password: Scalars['String']['input']
  language?: InputMaybe<WebLanguage>
  timezone?: InputMaybe<Scalars['String']['input']>
  sourceInput?: InputMaybe<SourceInput>
}>

export type SignUpMutation = { signUp: { __typename: 'Token'; token: string } | null }

export type SignUpWithoutPasswordMutationVariables = Exact<{
  identifier: Scalars['String']['input']
  language?: InputMaybe<WebLanguage>
  timezone?: InputMaybe<Scalars['String']['input']>
  coupon?: InputMaybe<Scalars['String']['input']>
  therapistId?: InputMaybe<Scalars['String']['input']>
}>

export type SignUpWithoutPasswordMutation = { signUpWithoutPassword: boolean | null }

export type SubscribeForNotificationMutationVariables = Exact<{
  type: UserNotificationType
  subscribedToProfileId?: InputMaybe<Scalars['String']['input']>
}>

export type SubscribeForNotificationMutation = { subscribeForNotification: { __typename: 'Subscriber'; id: string } }

export type UpdateScheduledCallPaymentIdMutationVariables = Exact<{
  paymentId: Scalars['String']['input']
  scheduledCallId: Scalars['String']['input']
}>

export type UpdateScheduledCallPaymentIdMutation = { updateScheduledCallPaymentId: boolean }

export type CanRedeemQueryVariables = Exact<{
  code: Scalars['String']['input']
}>

export type CanRedeemQuery = { canRedeem: string }

export type CreditPageQueryVariables = Exact<{
  isLoggedIn: Scalars['Boolean']['input']
  includeCreditUsage: Scalars['Boolean']['input']
  includePackage: Scalars['Boolean']['input']
  includeVoucher: Scalars['Boolean']['input']
  packageId: Scalars['String']['input']
  targetType?: InputMaybe<TargetType>
  targetId?: InputMaybe<Scalars['String']['input']>
}>

export type CreditPageQuery = {
  defaultTutorPrices: Array<{ __typename: 'TutorPrice'; id: string; type: TutorPriceType; price: number }>
  walletBalance: { __typename: 'WalletBalance'; id: string; oneclick: boolean }
  package: { __typename: 'Package'; id: string; name: string; price: number } | null
  giftVoucherTypes: Array<{ __typename: 'GiftVoucherType'; id: string; creditAmount: number; bonusAmount: number }>
  creditUsage: { __typename: 'CheckCreditUsageResponse'; creditDiscount: number }
}

export type CurrenciesCodeGenQueryVariables = Exact<{ [key: string]: never }>

export type CurrenciesCodeGenQuery = { getCurrencies: Currency }

export type GenerateGoogleLoginUrlQueryVariables = Exact<{
  coupon?: InputMaybe<Scalars['String']['input']>
}>

export type GenerateGoogleLoginUrlQuery = { generateGoogleLoginUrl: string | null }

export type GetCallSummaryQueryVariables = Exact<{
  roomId: Scalars['String']['input']
  groupId: Scalars['String']['input']
  userId: Scalars['String']['input']
  hasGroup: Scalars['Boolean']['input']
}>

export type GetCallSummaryQuery = {
  room: {
    __typename: 'Room'
    id: string
    cancelled: any | null
    ended: any | null
    reason: string | null
    trigger: EndCallTrigger | null
    lesson: {
      __typename: 'Lesson'
      id: string
      lessonUsage: boolean
      package: { __typename: 'Package'; id: string; name: string }
    } | null
    participants: Array<{
      __typename: 'Participant'
      id: string
      profileId: string
      profile:
        | {
            __typename: 'Student'
            id: string
            role: ProfileType
            user: { __typename: 'User'; id: string; displayName: string | null; firstName: string | null }
          }
        | {
            __typename: 'Tutor'
            id: string
            role: ProfileType
            user: { __typename: 'User'; id: string; displayName: string | null; firstName: string | null }
          }
        | null
    }> | null
    summary: { __typename: 'Summary'; price: number | null; timeSpentSeconds: number | null } | null
  }
  getCallSummary: {
    __typename: 'CallSummary'
    walletBalance: { __typename: 'WalletBalance'; id: string; balance: number; oneclick: boolean } | null
  } | null
  groupStudent: {
    __typename: 'GroupStudent'
    id: string
    wallet: { __typename: 'Wallet'; id: string; balance: number } | null
  }
}

export type GetChatConversationQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type GetChatConversationQuery = {
  getConversation: {
    __typename: 'Conversation'
    id: string
    user: { __typename: 'User'; id: string; firstName: string | null; displayName: string | null }
  } | null
}

export type GetContactListConversationQueryVariables = Exact<{
  partnerUserId: Scalars['String']['input']
}>

export type GetContactListConversationQuery = {
  conversation: {
    __typename: 'Conversation'
    id: string
    createdAt: any
    expiresAt: any | null
    lastCallAt: any | null
    blockedAt: any | null
    lastMessage: {
      __typename: 'Message'
      id: string
      fromUserId: string | null
      content: string
      roomId: string | null
      sent: any
      read: any | null
    } | null
    user: {
      __typename: 'User'
      id: string
      firstName: string | null
      displayName: string | null
      image: string | null
      countryCode: string | null
      currentStatus: { __typename: 'CurrentStatus'; status: Status }
      tutors: Array<{
        __typename: 'Tutor'
        id: string
        slug: string
        rating: number
        prices: Array<{ __typename: 'TutorPrice'; id: string; type: TutorPriceType; price: number }>
      }> | null
      students: Array<{ __typename: 'Student'; id: string }> | null
    }
  } | null
}

export type GetConversationsQueryVariables = Exact<{
  pageInfo?: InputMaybe<PageInfo>
}>

export type GetConversationsQuery = {
  currentUser: {
    __typename: 'User'
    id: string
    firstName: string | null
    displayName: string | null
    image: string | null
    countryCode: string | null
    currentStatus: { __typename: 'CurrentStatus'; status: Status }
    tutors: Array<{
      __typename: 'Tutor'
      id: string
      slug: string
      rating: number
      prices: Array<{ __typename: 'TutorPrice'; id: string; type: TutorPriceType; price: number }>
    }> | null
    students: Array<{ __typename: 'Student'; id: string }> | null
  } | null
  getConversations: Array<{
    __typename: 'Conversation'
    id: string
    createdAt: any
    expiresAt: any | null
    lastCallAt: any | null
    blockedAt: any | null
    lastMessage: {
      __typename: 'Message'
      id: string
      fromUserId: string | null
      content: string
      roomId: string | null
      sent: any
      read: any | null
    } | null
    user: {
      __typename: 'User'
      id: string
      firstName: string | null
      displayName: string | null
      image: string | null
      countryCode: string | null
      currentStatus: { __typename: 'CurrentStatus'; status: Status }
      tutors: Array<{
        __typename: 'Tutor'
        id: string
        slug: string
        rating: number
        prices: Array<{ __typename: 'TutorPrice'; id: string; type: TutorPriceType; price: number }>
      }> | null
      students: Array<{ __typename: 'Student'; id: string }> | null
    }
  }>
}

export type GetUserQueryVariables = Exact<{ [key: string]: never }>

export type GetUserQuery = {
  user: {
    __typename: 'User'
    id: string
    countryCode: string | null
    firstName: string | null
    displayName: string | null
    lastName: string | null
    createdAt: any
    image: string | null
    currency: string | null
  } | null
}

export type GetWalletBalanceQueryVariables = Exact<{ [key: string]: never }>

export type GetWalletBalanceQuery = { getWalletBalance: { __typename: 'WalletBalance'; id: string; balance: number } }

export type IncomingMessageQueryVariables = Exact<{ [key: string]: never }>

export type IncomingMessageQuery = { hasUnreadMessages: { __typename: 'UnreadInfo'; count: number | null } }

export type PricingPageQueryVariables = Exact<{ [key: string]: never }>

export type PricingPageQuery = {
  defaultTutorPrices: Array<{ __typename: 'TutorPrice'; id: string; type: TutorPriceType; price: number }>
}

export type StartChatModalQueryVariables = Exact<{
  profileId: Scalars['String']['input']
}>

export type StartChatModalQuery = {
  profile:
    | {
        __typename: 'Student'
        id: string
        user: {
          __typename: 'User'
          id: string
          firstName: string | null
          displayName: string | null
          tutors: Array<{ __typename: 'Tutor'; id: string }> | null
        }
      }
    | {
        __typename: 'Tutor'
        id: string
        user: {
          __typename: 'User'
          id: string
          firstName: string | null
          displayName: string | null
          tutors: Array<{ __typename: 'Tutor'; id: string }> | null
        }
      }
}

export type OnIncomingMessageSubscriptionVariables = Exact<{ [key: string]: never }>

export type OnIncomingMessageSubscription = {
  onMessageSent: {
    __typename: 'Message'
    id: string
    fromUserId: string | null
    content: string
    roomId: string | null
    sent: any
    read: any | null
    priority: Priority | null
    attachment:
      | { __typename: 'AttachmentCallEnded'; duration: number }
      | {
          __typename: 'AttachmentScheduledCall'
          expiresAt: any
          duration: number
          hasPassed: boolean
          scheduledCallId: string
          start: any
        }
      | null
    conversation: {
      __typename: 'Conversation'
      id: string
      members: Array<{ __typename: 'ConversationMember'; userId: string }>
      user: {
        __typename: 'User'
        id: string
        displayName: string | null
        firstName: string | null
        image: string | null
      }
    }
  } | null
}

export type OnMessageSentSubscriptionVariables = Exact<{ [key: string]: never }>

export type OnMessageSentSubscription = {
  onMessageSent: {
    __typename: 'Message'
    id: string
    fromUserId: string | null
    content: string
    roomId: string | null
    sent: any
    read: any | null
    priority: Priority | null
    attachment:
      | { __typename: 'AttachmentCallEnded'; duration: number }
      | {
          __typename: 'AttachmentScheduledCall'
          expiresAt: any
          duration: number
          hasPassed: boolean
          scheduledCallId: string
          start: any
        }
      | null
    conversation: {
      __typename: 'Conversation'
      id: string
      members: Array<{ __typename: 'ConversationMember'; userId: string }>
      user: { __typename: 'User'; id: string }
    }
  } | null
}

export type GetExchangeRatesQueryVariables = Exact<{ [key: string]: never }>

export type GetExchangeRatesQuery = { getExchangeRates: { __typename: 'ExchangeRate'; CZK: number; EUR: number } }

export type GetUserCurrencyQueryVariables = Exact<{ [key: string]: never }>

export type GetUserCurrencyQuery = { user: { __typename: 'User'; id: string; currency: string | null } | null }

export type SetCurrencyMutationVariables = Exact<{
  currency: Scalars['String']['input']
}>

export type SetCurrencyMutation = { setCurrency: boolean }

export type ReferralBannerQueryVariables = Exact<{
  referralUrl: Scalars['String']['input']
}>

export type ReferralBannerQuery = {
  referralUser: {
    __typename: 'User'
    id: string
    firstName: string | null
    displayName: string | null
    image: string | null
    tutors: Array<{ __typename: 'Tutor'; id: string }> | null
  } | null
}

export type AuthReferralUserFragment = {
  __typename: 'User'
  id: string
  referralReward: number
  tutors: Array<{ __typename: 'Tutor'; id: string }> | null
}

export type AuthPageQueryVariables = Exact<{
  referralUrl: Scalars['String']['input']
}>

export type AuthPageQuery = {
  referralUser: {
    __typename: 'User'
    id: string
    referralReward: number
    tutors: Array<{ __typename: 'Tutor'; id: string }> | null
  } | null
}

export type CallLessonFragment = {
  __typename: 'Lesson'
  id: string
  no: number | null
  package: { __typename: 'Package'; id: string; name: string }
}

export type CallComponentRoomFragment = {
  __typename: 'Room'
  id: string
  cancelled: any | null
  ended: any | null
  participants: Array<{
    __typename: 'Participant'
    id: string
    profileId: string
    profile:
      | {
          __typename: 'Student'
          id: string
          language: string
          role: ProfileType
          user: { __typename: 'User'; id: string; firstName: string | null }
        }
      | {
          __typename: 'Tutor'
          id: string
          language: string
          role: ProfileType
          user: { __typename: 'User'; id: string; firstName: string | null }
        }
      | null
  }> | null
}

export type CallComponentRootRoomFragment = {
  __typename: 'Room'
  id: string
  cancelled: any | null
  ended: any | null
  end: any | null
  duration: number
  reason: string | null
  started: any | null
  trigger: EndCallTrigger | null
  participants: Array<{
    __typename: 'Participant'
    id: string
    profileId: string
    token: string | null
    profile:
      | {
          __typename: 'Student'
          id: string
          language: string
          role: ProfileType
          rating: number
          user: {
            __typename: 'User'
            id: string
            firstName: string | null
            countryCode: string | null
            displayName: string | null
            image: string | null
          }
        }
      | {
          __typename: 'Tutor'
          id: string
          language: string
          role: ProfileType
          rating: number
          user: {
            __typename: 'User'
            id: string
            firstName: string | null
            countryCode: string | null
            displayName: string | null
            image: string | null
          }
        }
      | null
  }> | null
  lesson: {
    __typename: 'Lesson'
    id: string
    no: number | null
    package: { __typename: 'Package'; id: string; name: string }
  } | null
  rating: { __typename: 'Rating'; id: string } | null
  resolution: { __typename: 'ResolutionRoom'; width: number; height: number }
}

export type CallComponentOnRoomEndedSubscriptionVariables = Exact<{ [key: string]: never }>

export type CallComponentOnRoomEndedSubscription = {
  room: {
    __typename: 'Room'
    id: string
    cancelled: any | null
    duration: number
    end: any | null
    ended: any | null
    reason: string | null
    started: any | null
    trigger: EndCallTrigger | null
    rating: { __typename: 'Rating'; id: string } | null
    resolution: { __typename: 'ResolutionRoom'; width: number; height: number }
  }
}

export type CallComponentQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type CallComponentQuery = {
  room: {
    __typename: 'Room'
    id: string
    cancelled: any | null
    ended: any | null
    end: any | null
    duration: number
    reason: string | null
    started: any | null
    trigger: EndCallTrigger | null
    participants: Array<{
      __typename: 'Participant'
      id: string
      profileId: string
      token: string | null
      profile:
        | {
            __typename: 'Student'
            id: string
            language: string
            role: ProfileType
            rating: number
            user: {
              __typename: 'User'
              id: string
              firstName: string | null
              countryCode: string | null
              displayName: string | null
              image: string | null
            }
          }
        | {
            __typename: 'Tutor'
            id: string
            language: string
            role: ProfileType
            rating: number
            user: {
              __typename: 'User'
              id: string
              firstName: string | null
              countryCode: string | null
              displayName: string | null
              image: string | null
            }
          }
        | null
    }> | null
    lesson: {
      __typename: 'Lesson'
      id: string
      no: number | null
      package: { __typename: 'Package'; id: string; name: string }
    } | null
    rating: { __typename: 'Rating'; id: string } | null
    resolution: { __typename: 'ResolutionRoom'; width: number; height: number }
  }
}

export type PanelLeftRoomFragment = {
  __typename: 'Room'
  id: string
  cancelled: any | null
  duration: number
  end: any | null
  ended: any | null
}

export type GetQuizzesQueryVariables = Exact<{
  profileId: Scalars['String']['input']
}>

export type GetQuizzesQuery = { getQuizzes: Array<{ __typename: 'Quiz'; quiz: any }> }

export type ProlongDialogCheckQueryVariables = Exact<{
  roomId: Scalars['String']['input']
}>

export type ProlongDialogCheckQuery = {
  initialStatus: CantProlongReason
  walletBalance: { __typename: 'WalletBalance'; id: string; balance: number }
}

export type OnCallProlongedSubscriptionVariables = Exact<{
  roomId: Scalars['String']['input']
}>

export type OnCallProlongedSubscription = {
  prolongedRoom: { __typename: 'Room'; id: string; duration: number; end: any | null } | null
}

export type IsFirstCallQueryVariables = Exact<{
  profileId: Scalars['String']['input']
}>

export type IsFirstCallQuery = { calls: { __typename: 'NumberOfCalls'; count: number | null } }

export type RatedRoomQueryVariables = Exact<{
  roomId: Scalars['String']['input']
}>

export type RatedRoomQuery = {
  room: {
    __typename: 'Room'
    id: string
    callInviteStatus: CallInviteStatus
    trigger: EndCallTrigger | null
    participants: Array<{
      __typename: 'Participant'
      id: string
      profileId: string
      cancelledAt: any | null
      profile:
        | {
            __typename: 'Student'
            id: string
            role: ProfileType
            user: { __typename: 'User'; id: string; firstName: string | null; image: string | null }
          }
        | {
            __typename: 'Tutor'
            id: string
            role: ProfileType
            user: { __typename: 'User'; id: string; firstName: string | null; image: string | null }
          }
        | null
    }> | null
  }
}

export type RatingPackagesQueryVariables = Exact<{ [key: string]: never }>

export type RatingPackagesQuery = { packages: Array<{ __typename: 'Package'; id: string; name: string }> }

export type RateUserMutationVariables = Exact<{
  rating: RatingInput
}>

export type RateUserMutation = { rateUser: { __typename: 'Rating'; id: string } }

export type ScreenRoomFragment = {
  __typename: 'Room'
  cancelled: any | null
  duration: number
  end: any | null
  ended: any | null
  id: string
  started: any | null
  participants: Array<{
    __typename: 'Participant'
    id: string
    profileId: string
    token: string | null
    profile: { __typename: 'Student'; id: string } | { __typename: 'Tutor'; id: string } | null
  }> | null
  resolution: { __typename: 'ResolutionRoom'; width: number; height: number }
}

export type InstructionsRoomFragment = {
  __typename: 'Room'
  cancelled: any | null
  end: any | null
  ended: any | null
  id: string
}

export type TutorInstructionsCallsQueryVariables = Exact<{
  profileId: Scalars['String']['input']
}>

export type TutorInstructionsCallsQuery = {
  numberOfCalls: {
    __typename: 'NumberOfCalls'
    count: number | null
    countTogether: number | null
    lastCallTogether: any | null
  }
}

export type TutorInstructionsRoomQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type TutorInstructionsRoomQuery = {
  room: {
    __typename: 'Room'
    id: string
    duration: number
    end: any | null
    participants: Array<{
      __typename: 'Participant'
      id: string
      profileId: string
      profile:
        | {
            __typename: 'Student'
            id: string
            role: ProfileType
            user: { __typename: 'User'; id: string; firstName: string | null }
          }
        | {
            __typename: 'Tutor'
            id: string
            role: ProfileType
            user: { __typename: 'User'; id: string; firstName: string | null }
          }
        | null
    }> | null
  }
}

export type ChangePasswordMutationVariables = Exact<{
  newPassword: Scalars['String']['input']
}>

export type ChangePasswordMutation = { changePassword: { __typename: 'Token'; token: string } | null }

export type ConfirmEmailMutationVariables = Exact<{
  token: Scalars['String']['input']
}>

export type ConfirmEmailMutation = { verified: boolean }

export type CompanyCreditQueryVariables = Exact<{
  groupId: Scalars['String']['input']
  userId: Scalars['String']['input']
}>

export type CompanyCreditQuery = {
  group: { __typename: 'Group'; id: string; name: string; logo: string | null } | null
  student: {
    __typename: 'GroupStudent'
    id: string
    state: GroupUserState | null
    wallet: {
      __typename: 'Wallet'
      id: string
      balance: number
      options: { __typename: 'WalletOptions'; monthlyLimit: number | null; coverage: number | null }
    } | null
  }
}

export type GetCountryQueryVariables = Exact<{ [key: string]: never }>

export type GetCountryQuery = { user: { __typename: 'User'; id: string; countryCode: string | null } | null }

export type InitSodexoPaymentMutationVariables = Exact<{
  paymentInput: SodexoInput
}>

export type InitSodexoPaymentMutation = { initSodexoPayment: string }

export type PromoCodeFormQueryVariables = Exact<{
  promoCode: Scalars['String']['input']
}>

export type PromoCodeFormQuery = {
  validatePromo: { __typename: 'Voucher'; id: string; bonusAmountPercent: number | null; code: string } | null
}

export type PromoCodeVoucherFragment = {
  __typename: 'Voucher'
  id: string
  bonusAmountPercent: number | null
  code: string
}

export type StudentDashboardQueryVariables = Exact<{ [key: string]: never }>

export type StudentDashboardQuery = {
  me: {
    __typename: 'User'
    id: string
    students: Array<{
      __typename: 'Student'
      id: string
      assignedTutor: Array<{ __typename: 'TutorStudent'; id: string; tutorId: string; conversationId: string }> | null
    }> | null
  } | null
}

export type GetCallHistoryQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
  isStudent: Scalars['Boolean']['input']
}>

export type GetCallHistoryQuery = {
  rooms: Array<{
    __typename: 'Room'
    id: string
    start: any | null
    started: any | null
    priceCharged?: number | null
    tutorRevenue?: number | null
    actualDuration: string | null
    state: string | null
    lesson: {
      __typename: 'Lesson'
      id: string
      no: number | null
      package: {
        __typename: 'Package'
        id: string
        name: string
        lessons: Array<{ __typename: 'Lesson'; id: string; no: number | null }> | null
      }
    } | null
    rating: { __typename: 'Rating'; id: string; ratingTotal: number } | null
    participants: Array<{
      __typename: 'Participant'
      id: string
      role: Role
      profileId: string
      profile:
        | {
            __typename: 'Student'
            id: string
            rating: number
            user: {
              __typename: 'User'
              id: string
              firstName: string | null
              displayName: string | null
              countryCode: string | null
              image: string | null
              credentials: Array<{ __typename: 'Credential'; identifier?: string }> | null
            }
          }
        | {
            __typename: 'Tutor'
            id: string
            rating: number
            user: {
              __typename: 'User'
              id: string
              firstName: string | null
              displayName: string | null
              countryCode: string | null
              image: string | null
              credentials: Array<{ __typename: 'Credential'; identifier?: string }> | null
            }
          }
        | null
    }> | null
  }>
}

export type GetTutorQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type GetTutorQuery = { getTutor: { __typename: 'Tutor'; slug: string } | null }

export type GetRatingByRoomIdQueryVariables = Exact<{
  roomId: Scalars['String']['input']
}>

export type GetRatingByRoomIdQuery = {
  getRatingByRoomId: { __typename: 'Rating'; id: string; ratingTotal: number; comment: string } | null
}

export type CreateRatingMutationVariables = Exact<{
  givingProfileId: Scalars['String']['input']
  ratedProfileId: Scalars['String']['input']
  roomId: Scalars['String']['input']
  ratingTotal: Scalars['Float']['input']
  comment: Scalars['String']['input']
}>

export type CreateRatingMutation = {
  rateUser: { __typename: 'Rating'; id: string; ratingTotal: number; comment: string }
}

export type SaveCommentMutationVariables = Exact<{
  comment: Scalars['String']['input']
  id: Scalars['String']['input']
}>

export type SaveCommentMutation = { editRatingComment: boolean }

export type CompanyBalanceQueryVariables = Exact<{
  groupId: Scalars['String']['input']
  userId: Scalars['String']['input']
}>

export type CompanyBalanceQuery = {
  group: { __typename: 'Group'; id: string; name: string; logo: string | null } | null
  groupStudent: {
    __typename: 'GroupStudent'
    id: string
    state: GroupUserState | null
    wallet: {
      __typename: 'Wallet'
      id: string
      balance: number
      options: { __typename: 'WalletOptions'; monthlyLimit: number | null }
    } | null
  }
}

export type GetStudentDashboardQueryVariables = Exact<{ [key: string]: never }>

export type GetStudentDashboardQuery = {
  getStudentDashboard: {
    __typename: 'Student'
    rating: number
    walletBalance: { __typename: 'WalletBalance'; id: string; balance: number } | null
  } | null
}

export type GetTutorDashboardQueryVariables = Exact<{ [key: string]: never }>

export type GetTutorDashboardQuery = {
  getSubscriberCount: number
  getTutorDashboard: {
    __typename: 'Tutor'
    rating: number
    user: { __typename: 'User'; currentStatus: { __typename: 'CurrentStatus'; status: Status } }
  } | null
}

export type GetLastStudentsQueryVariables = Exact<{ [key: string]: never }>

export type GetLastStudentsQuery = {
  getLastStudents: Array<{
    __typename: 'Student'
    id: string
    rating: number
    user: {
      __typename: 'User'
      id: string
      firstName: string | null
      displayName: string | null
      countryCode: string | null
      image: string | null
    }
  } | null> | null
}

export type GetTherapistClientsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
  offset?: InputMaybe<Scalars['Int']['input']>
}>

export type GetTherapistClientsQuery = {
  students: Array<{
    __typename: 'Student'
    id: string
    user: {
      __typename: 'User'
      id: string
      displayName: string | null
      credentials: Array<{ __typename: 'Credential'; identifier: string }> | null
    }
  }>
}

export type OnboardingStepsQueryVariables = Exact<{ [key: string]: never }>

export type OnboardingStepsQuery = {
  quizes: Array<{ __typename: 'Quiz'; id: string; quiz: any }>
  callHistory: Array<{ __typename: 'Room'; id: string }>
  user: {
    __typename: 'User'
    id: string
    firstName: string | null
    countryCode: string | null
    verified: boolean
  } | null
}

export type StudentOrTutorScheduledCallFragment = {
  __typename: 'ScheduledCall'
  id: string
  cancelledAt: any | null
  expectedPrice?: number | null
  expectedRevenue?: number | null
  status: string
  start: any
  end: any
  lesson: {
    __typename: 'Lesson'
    id: string
    no: number | null
    package: {
      __typename: 'Package'
      id: string
      name: string
      lessons: Array<{ __typename: 'Lesson'; id: string }> | null
    }
  } | null
  participants: Array<{
    __typename: 'ScheduledCallParticipant'
    profileId: string
    profile:
      | {
          __typename: 'Student'
          id: string
          rating: number
          user: {
            __typename: 'User'
            id: string
            firstName: string | null
            displayName: string | null
            countryCode: string | null
            image: string | null
            credentials: Array<{ __typename: 'Credential'; identifier?: string }> | null
          }
        }
      | {
          __typename: 'Tutor'
          id: string
          rating: number
          slug: string
          user: {
            __typename: 'User'
            id: string
            firstName: string | null
            displayName: string | null
            countryCode: string | null
            image: string | null
            credentials: Array<{ __typename: 'Credential'; identifier?: string }> | null
          }
        }
      | null
  }>
  rooms: Array<{ __typename: 'Room'; id: string; status: string }> | null
}

export type StudentOrTutorScheduledCallUserFragment = {
  __typename: 'User'
  id: string
  firstName: string | null
  displayName: string | null
  countryCode: string | null
  image: string | null
  credentials: Array<{ __typename: 'Credential'; identifier?: string }> | null
}

export type CancelCallMutationVariables = Exact<{
  id: Scalars['String']['input']
  cancellationReason: Scalars['String']['input']
}>

export type CancelCallMutation = { cancelled: boolean }

export type ShiftCalendarQueryVariables = Exact<{
  from: Scalars['DateTime']['input']
  to: Scalars['DateTime']['input']
  type?: InputMaybe<ShiftType>
}>

export type ShiftCalendarQuery = {
  user: {
    __typename: 'User'
    id: string
    contacts: Array<
      { __typename: 'Billing'; id: string } | { __typename: 'Email'; id: string } | { __typename: 'Phone'; id: string }
    > | null
  } | null
  shifts: Array<{ __typename: 'Shift'; id: string; start: any; end: any; type: ShiftType | null }>
}

export type ShiftFragment = { __typename: 'Shift'; id: string; start: any; end: any; type: ShiftType | null }

export type ShiftCalendarCancelShiftMutationVariables = Exact<{
  shiftId: Scalars['String']['input']
}>

export type ShiftCalendarCancelShiftMutation = {
  shift: { __typename: 'Shift'; id: string; start: any; end: any; type: ShiftType | null }
}

export type ShiftCalendarCreateShiftMutationVariables = Exact<{
  shiftInput: ShiftInput
}>

export type ShiftCalendarCreateShiftMutation = {
  shift: { __typename: 'Shift'; id: string; start: any; end: any; type: ShiftType | null }
}

export type ShiftCalendarUpdateShiftMutationVariables = Exact<{
  shiftInput: ShiftInput
}>

export type ShiftCalendarUpdateShiftMutation = {
  shift: { __typename: 'Shift'; id: string; start: any; end: any; type: ShiftType | null }
}

export type StudentScheduledCallsQueryVariables = Exact<{
  isStudent: Scalars['Boolean']['input']
  showBooked: Scalars['Boolean']['input']
}>

export type StudentScheduledCallsQuery = {
  scheduledCalls: Array<{
    __typename: 'ScheduledCall'
    id: string
    cancelledAt: any | null
    expectedPrice?: number | null
    expectedRevenue?: number | null
    status: string
    start: any
    end: any
    lesson: {
      __typename: 'Lesson'
      id: string
      no: number | null
      package: {
        __typename: 'Package'
        id: string
        name: string
        lessons: Array<{ __typename: 'Lesson'; id: string }> | null
      }
    } | null
    participants: Array<{
      __typename: 'ScheduledCallParticipant'
      profileId: string
      profile:
        | {
            __typename: 'Student'
            id: string
            rating: number
            user: {
              __typename: 'User'
              id: string
              firstName: string | null
              displayName: string | null
              countryCode: string | null
              image: string | null
              credentials: Array<{ __typename: 'Credential'; identifier?: string }> | null
            }
          }
        | {
            __typename: 'Tutor'
            id: string
            rating: number
            slug: string
            user: {
              __typename: 'User'
              id: string
              firstName: string | null
              displayName: string | null
              countryCode: string | null
              image: string | null
              credentials: Array<{ __typename: 'Credential'; identifier?: string }> | null
            }
          }
        | null
    }>
    rooms: Array<{ __typename: 'Room'; id: string; status: string }> | null
  }>
}

export type ForgotPasswordMutationVariables = Exact<{
  identifier: Scalars['String']['input']
}>

export type ForgotPasswordMutation = { forgotPassword: boolean | null }

export type ReferralHeadQueryVariables = Exact<{
  referralUrl: Scalars['String']['input']
}>

export type ReferralHeadQuery = {
  user: { __typename: 'User'; id: string; referralReward: number; referralUrl: string } | null
}

export type GetTutorWentOnlineSubscriptionsQueryVariables = Exact<{ [key: string]: never }>

export type GetTutorWentOnlineSubscriptionsQuery = {
  getActiveSubscriptions: Array<{
    __typename: 'Subscriber'
    id: string
    subscribedToProfileId: string | null
    profile:
      | {
          __typename: 'Student'
          id: string
          user: { __typename: 'User'; id: string; firstName: string | null; displayName: string | null }
        }
      | {
          __typename: 'Tutor'
          id: string
          user: { __typename: 'User'; id: string; firstName: string | null; displayName: string | null }
        }
      | null
  }>
}

export type UnsubscribeNotificationMutationVariables = Exact<{
  notificationSubscriptionInput: NotificationSubscriptionInput
}>

export type UnsubscribeNotificationMutation = { unsubscribeNotification: boolean }

export type OnboardingQuizSaveMutationVariables = Exact<{
  quizInput: QuizInput
  notify?: InputMaybe<Scalars['Boolean']['input']>
}>

export type OnboardingQuizSaveMutation = { saveQuiz: boolean }

export type GetTherapistsByQuizQueryVariables = Exact<{
  quiz: QuizInput
  language: Scalars['String']['input']
}>

export type GetTherapistsByQuizQuery = {
  tutors: Array<{
    __typename: 'TutorOutput'
    id: string
    userId: string
    firstName: string | null
    lastName: string | null
    displayName: string | null
    image: string | null
    introduction: string | null
    motto: string | null
    slug: string | null
    topic: string | null
  }>
}

export type CheckPaymentQueryVariables = Exact<{
  paymentId: Scalars['String']['input']
  isCsob: Scalars['Boolean']['input']
}>

export type CheckPaymentQuery = {
  csob: {
    __typename: 'CsobPayment'
    id: string
    amount: number
    blabuState: BlabuPaymentStateType
    invoiceId: string | null
    targetType: TargetType | null
    targetId: string | null
    vatPercentage: number | null
    package: { __typename: 'Package'; id: string; name: string } | null
  }
  payment:
    | {
        __typename: 'CsobPayment'
        id: string
        amount: number
        blabuState: BlabuPaymentStateType
        invoiceId: string | null
        targetType: TargetType | null
        targetId: string | null
        vatPercentage: number | null
        package: { __typename: 'Package'; id: string; name: string } | null
      }
    | {
        __typename: 'PaypalPayment'
        id: string
        amount: number
        blabuState: BlabuPaymentStateType
        invoiceId: string | null
        targetType: TargetType | null
        targetId: string | null
        vatPercentage: number | null
        package: { __typename: 'Package'; id: string; name: string } | null
      }
    | {
        __typename: 'BenefitPlusPayment'
        id: string
        amount: number
        blabuState: BlabuPaymentStateType
        invoiceId: string | null
        targetType: TargetType | null
        targetId: string | null
        vatPercentage: number | null
        package: { __typename: 'Package'; id: string; name: string } | null
      }
    | {
        __typename: 'CouponPayment'
        id: string
        amount: number
        blabuState: BlabuPaymentStateType
        invoiceId: string | null
        targetType: TargetType | null
        targetId: string | null
        vatPercentage: number | null
        package: { __typename: 'Package'; id: string; name: string } | null
      }
    | {
        __typename: 'GalleryBetaPayment'
        id: string
        amount: number
        blabuState: BlabuPaymentStateType
        invoiceId: string | null
        targetType: TargetType | null
        targetId: string | null
        vatPercentage: number | null
        package: { __typename: 'Package'; id: string; name: string } | null
      }
    | {
        __typename: 'MolliePayment'
        id: string
        amount: number
        blabuState: BlabuPaymentStateType
        invoiceId: string | null
        targetType: TargetType | null
        targetId: string | null
        vatPercentage: number | null
        package: { __typename: 'Package'; id: string; name: string } | null
      }
    | {
        __typename: 'PayUPayment'
        id: string
        amount: number
        blabuState: BlabuPaymentStateType
        invoiceId: string | null
        targetType: TargetType | null
        targetId: string | null
        vatPercentage: number | null
        package: { __typename: 'Package'; id: string; name: string } | null
      }
    | {
        __typename: 'SodexoPayment'
        id: string
        amount: number
        blabuState: BlabuPaymentStateType
        invoiceId: string | null
        targetType: TargetType | null
        targetId: string | null
        vatPercentage: number | null
        package: { __typename: 'Package'; id: string; name: string } | null
      }
}

export type IssueInvoiceFormCreateMutationVariables = Exact<{
  billingId: Scalars['String']['input']
  paymentId: Scalars['String']['input']
}>

export type IssueInvoiceFormCreateMutation = { invoice: { __typename: 'Invoice'; id: string } }

export type IssuedInvoiceFragment = { __typename: 'Invoice'; id: string }

export type PayoutsQueryVariables = Exact<{ [key: string]: never }>

export type PayoutsQuery = {
  invoices: Array<{ __typename: 'Invoice'; createdAt: any; id: string; from: string; until: string }>
  user: {
    __typename: 'User'
    id: string
    firstName: string | null
    displayName: string | null
    contacts: Array<
      | {
          __typename: 'Billing'
          id: string
          name: string
          street: string
          city: string
          state: string | null
          companyId: string | null
          countryCode: string
          zip: string
        }
      | { __typename: 'Email'; id: string }
      | { __typename: 'Phone'; id: string }
    > | null
  } | null
}

export type TutorBillingFragment = {
  __typename: 'Billing'
  id: string
  name: string
  street: string
  city: string
  state: string | null
  companyId: string | null
  countryCode: string
  zip: string
}

export type TutorInvoiceFragment = { __typename: 'Invoice'; createdAt: any; id: string; from: string; until: string }

export type TutorPayoutUserFragment = { __typename: 'User'; firstName: string | null; displayName: string | null }

export type CreateContactMutationVariables = Exact<{
  contactInput: ContactInput
}>

export type CreateContactMutation = { createContact: boolean }

export type PhoneFormQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type PhoneFormQuery = {
  tutor: {
    __typename: 'Tutor'
    id: string
    user: {
      __typename: 'User'
      id: string
      contacts: Array<
        | { __typename: 'Billing'; id: string }
        | { __typename: 'Email'; id: string }
        | { __typename: 'Phone'; id: string; phone: string | null }
      > | null
    }
  } | null
}

export type GetPackagesSimpleQueryVariables = Exact<{ [key: string]: never }>

export type GetPackagesSimpleQuery = {
  getPackages: Array<{ __typename: 'Package'; id: string; name: string; price: number; state: PackageStatus }>
}

export type VerifyRestoreHashMutationVariables = Exact<{
  hash: Scalars['String']['input']
}>

export type VerifyRestoreHashMutation = { verifyRestoreHash: { __typename: 'Token'; token: string } | null }

export type GetTutorsRealStateQueryVariables = Exact<{ [key: string]: never }>

export type GetTutorsRealStateQuery = {
  getTutorsRealState: Array<{
    __typename: 'Tutor'
    id: string
    user: {
      __typename: 'User'
      id: string
      currentStatus: { __typename: 'CurrentStatus'; status: Status; text: string | null }
    }
    busy: { __typename: 'Busy'; isBusy: boolean; until: any | null; flag: BusyFlag | null } | null
  }>
}

export type ListTutorsQueryVariables = Exact<{
  first?: InputMaybe<Scalars['Int']['input']>
}>

export type ListTutorsQuery = {
  listTutors: Array<{
    __typename: 'Tutor'
    id: string
    slug: string
    user: {
      __typename: 'User'
      id: string
      firstName: string | null
      lastName: string | null
      displayName: string | null
      countryCode: string | null
      image: string | null
      currentStatus: { __typename: 'CurrentStatus'; status: Status; text: string | null }
    }
    intros: Array<{ __typename: 'TutorIntro'; language: string; introduction: string; motto: string | null }>
    topics: Array<{ __typename: 'Topic'; name: string }>
  }>
}

export type GetLessonPackageQueryVariables = Exact<{
  id: Scalars['String']['input']
}>

export type GetLessonPackageQuery = {
  getLesson: {
    __typename: 'Lesson'
    id: string
    no: number | null
    package: {
      __typename: 'Package'
      id: string
      name: string
      lessons: Array<{ __typename: 'Lesson'; id: string }> | null
    }
  }
}

export type CallRatingFormSaveMutationVariables = Exact<{
  rating: RatingInput
}>

export type CallRatingFormSaveMutation = { rating: { __typename: 'Rating'; id: string } }

export type CallRatingFormQueryVariables = Exact<{
  roomId: Scalars['String']['input']
}>

export type CallRatingFormQuery = {
  room: {
    __typename: 'Room'
    id: string
    participants: Array<{
      __typename: 'Participant'
      id: string
      profileId: string
      profile:
        | {
            __typename: 'Student'
            id: string
            role: ProfileType
            user: { __typename: 'User'; id: string; firstName: string | null }
          }
        | {
            __typename: 'Tutor'
            id: string
            role: ProfileType
            user: { __typename: 'User'; id: string; firstName: string | null }
          }
        | null
    }> | null
  }
}

export type ProlongCallMutationVariables = Exact<{
  roomId: Scalars['String']['input']
}>

export type ProlongCallMutation = {
  prolongedCall: {
    __typename: 'ProlongCallResult'
    status: CantProlongReason
    room: { __typename: 'Room'; id: string; duration: number; end: any | null } | null
  }
}

export type RoomStateFragment = {
  __typename: 'Room'
  id: string
  cancelled: any | null
  duration: number
  end: any | null
  ended: any | null
  reason: string | null
  started: any | null
  trigger: EndCallTrigger | null
  rating: { __typename: 'Rating'; id: string } | null
  resolution: { __typename: 'ResolutionRoom'; width: number; height: number }
}

export type RoomStateCheckQueryVariables = Exact<{
  roomId: Scalars['String']['input']
}>

export type RoomStateCheckQuery = {
  room: {
    __typename: 'Room'
    id: string
    cancelled: any | null
    duration: number
    end: any | null
    ended: any | null
    reason: string | null
    started: any | null
    trigger: EndCallTrigger | null
    rating: { __typename: 'Rating'; id: string } | null
    resolution: { __typename: 'ResolutionRoom'; width: number; height: number }
  }
  note: { __typename: 'Note'; id: string; note: string } | null
}

export type CallEndedMutationVariables = Exact<{
  roomId: Scalars['String']['input']
  trigger: EndCallTrigger
  reason?: InputMaybe<Scalars['String']['input']>
  meta?: InputMaybe<CallEndedInfo>
}>

export type CallEndedMutation = {
  room: {
    __typename: 'Room'
    id: string
    cancelled: any | null
    duration: number
    end: any | null
    ended: any | null
    reason: string | null
    started: any | null
    trigger: EndCallTrigger | null
    rating: { __typename: 'Rating'; id: string } | null
    resolution: { __typename: 'ResolutionRoom'; width: number; height: number }
  } | null
}

export type ConnectToRoomMutationVariables = Exact<{
  id: Scalars['String']['input']
}>

export type ConnectToRoomMutation = {
  connectedRoom: {
    __typename: 'ConnectedRoom'
    reconnect: boolean | null
    participant: {
      __typename: 'Participant'
      id: string
      token: string | null
      profile: { __typename: 'Student'; id: string } | { __typename: 'Tutor'; id: string } | null
    }
    room: {
      __typename: 'Room'
      id: string
      lessonId: string | null
      cancelled: any | null
      duration: number
      end: any | null
      ended: any | null
      reason: string | null
      started: any | null
      trigger: EndCallTrigger | null
      rating: { __typename: 'Rating'; id: string } | null
      resolution: { __typename: 'ResolutionRoom'; width: number; height: number }
    }
  }
}

export type StartCallMutationVariables = Exact<{
  startCall: StartCallInput
}>

export type StartCallMutation = {
  startCall: {
    __typename: 'StartCallType'
    status: CallStatus
    room: {
      __typename: 'Room'
      end: any | null
      id: string
      cancelled: any | null
      duration: number
      ended: any | null
      reason: string | null
      started: any | null
      trigger: EndCallTrigger | null
      rating: { __typename: 'Rating'; id: string } | null
      resolution: { __typename: 'ResolutionRoom'; width: number; height: number }
    }
  }
}
