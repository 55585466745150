import { WebLanguage } from '@gql/__generated__'
import { User } from '@src/types/User'
import { QuestionId } from '@pages/onboarding/Questions'

export enum AuthKind {
  Login = 'login',
  Signup = 'signup',
  SetPassword = 'setPassword',
  ChangePassword = 'changePassword',
}

export type Identifier = string

export enum Role {
  User = 'user',
  Tutor = 'tutor',
  Student = 'student',
  Restore = 'restore',
}

export enum GroupRole {
  GroupAdmin = 'GroupAdmin',
  GroupUser = 'GroupUser',
  GroupManager = 'GroupManager',
}

export type Token = string

export type TokenPayload = {
  id: string
  profileIds: string[]
  roles: Role[]
  identifier: Identifier
  language: WebLanguage
  iat: number
  groups: {
    id: string
    role: GroupRole
  }[]
}

export type CurrentUser = User

export type UserState = {
  // @TODO Remove
  currentUser: CurrentUser | null
  token: Token | null
  tokenPayload: TokenPayload | null
  therapistId: string
  onboardingValues: OnboardingValues
}

export type TokenStateType = 'valid' | 'invalid' | 'not-verified' | 'error'

export { WebLanguage }

export type OnboardingValues = {
  [questionId in QuestionId]: any
}
